import React from "react";
import { Link } from "gatsby";
import { filterServices } from "@resourcehub/resourcehub-services";
import Search from "../Search/Search";
import FilterDropDown from "./FilterDropDown";
import styled from "styled-components";
import PropTypes from "prop-types";

const DropDownFilterLink = styled(Link)`
    z-index: 5;
    padding: 1em;
    position: relative;
    font-family: effra_medium, sans-serif;
    background: white;
    color: #4a4a4a;
    border-radius: 8px;
    margin: 0 !important;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
    transition: box-shadow 0.1s ease-in-out;
    text-decoration: none;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px 0px;
    }

    &:focus {
      padding: calc(1em - 1px);
      outline: none;
      border: 1px solid #72c6ec;
      box-shadow: 0 0 8px #72c6ec;
    }
  }

  a.filter {
    padding: 1em;
  }

  .filter__dropdown {
    transition: width 0.3s;

    button {
      padding: 1em;
      color: #4a4a4a;
      border-radius: 8px;
      border: 0;
      text-align: left;
      width: 100%;

      font-family: effra_medium, sans-serif;
      font-size: 14px;
      font-weight: 400;

      &:focus {
        padding: calc(1em - 1px);
        width: 100%;
        height: auto;
      }
    }

    a,
    button {
      &:focus {
        outline: none;
        border: 1px solid #72c6ec;
        box-shadow: 0 0 8px #72c6ec;
      }
    }

    a {
      padding: 10px 1em;

      &:focus {
        padding: 9px calc(1em - 1px);
      }
    }
    a:last-of-type {
      padding: 10px 1em 1em;

      &:focus {
        padding: 9px calc(1em - 1px) calc(1em - 1px);
      }
    }
`;

const DesktopFilterBar = styled.div`
    width: 100%;
    display: flex;
    padding: 2em 2.4vw;
    justify-content: flex-start !important;
    background-color: initial;

    + div {
        display: flex;
        justify-content: space-evenly;
        flex-flow: row wrap;
    }

    @media (max-width: 1680px) {
        padding: 2em;
        margin: auto;
        max-width: 1680px;
    }

    @media (max-width: 1024px) {
        display: none !important;
        + div {
            a {
                &:nth-child(1) {
                    margin-top: 1em;
                }
                margin-bottom: 2em;
            }
        }
    }
`;

const DesktopFilter = ({ vertical, resourcesLink, market, placeholder }) => {
    const data = filterServices.getFilterBarData(market, vertical);

    const sections = data.sections || [];

    const dropDowns = sections.map((section) => (
        <FilterDropDown
            key={section.title}
            btnText={`Browse by ${section.title}`}
            links={section.sublevels}
            resourcesLink={resourcesLink}
        />
    ));

    const noDropDowns =
        data.noDropDowns &&
        data.noDropDowns.map((link) => (
            <DropDownFilterLink key={link.title} to={`${resourcesLink}${link.url_slug}/`}>
                {link.title}
            </DropDownFilterLink>
        ));

    return (
        <DesktopFilterBar>
            <Search vertical={vertical} market={market} resourcesLink={resourcesLink} placeholder={placeholder} />
            {dropDowns}
            {noDropDowns}
        </DesktopFilterBar>
    );
};

export const DesktopFilterProps = {
    market: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    resourcesLink: PropTypes.string.isRequired,
    vertical: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
};

DesktopFilter.propTypes = DesktopFilterProps;

export default DesktopFilter;

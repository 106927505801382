import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import AppStoreRating, { AppStoreRatingPropTypes } from "../components/AppStore/AppStoreRating";
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import PageSectionWrapper from "../component-styles/PageSectionWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

const AppStoreRatingListLayout = styled.div`
    label: AppStoreRatingListLayout;
    display: flex;
    flex-direction: column;
    width: 50%;
    > div:nth-of-type(1) {
        margin-bottom: 30px;
    }
`;
const AppStoreRatingListContainer = styled.div`
    label: AppStoreRatingListContainer;

    display: flex;
    flex-direction: column;
`;

const AppStoreChildContentWrapper = styled(ChildContentWrapper)`
    label: AppStoreChildContentWrapper;
    margin: 0;
    padding: 0;
`;

const PageSectionAppStoreRating = ({ dataQa, panels, title, children }) => (
    <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
        <AppStoreRatingListLayout>
            {title && <TitleWrapper {...title} />}
            {panels && (
                <AppStoreRatingListContainer>
                    {panels.map((panel, i) => (
                        <AppStoreRating key={`image-banner-${i}`} {...panel} />
                    ))}
                </AppStoreRatingListContainer>
            )}
        </AppStoreRatingListLayout>
        {children && <AppStoreChildContentWrapper>{children}</AppStoreChildContentWrapper>}
    </PageSectionWrapper>
);
export const PageSectionAppStoreRatingPropTypes = {
    title: PropTypes.shape(TitleWrapperPropTypes),
    panels: PropTypes.arrayOf(PropTypes.shape(AppStoreRatingPropTypes)).isRequired
};
PageSectionAppStoreRating.defaultProps = {};
PageSectionAppStoreRating.propTypes = PageSectionAppStoreRatingPropTypes;
export default PageSectionAppStoreRating;

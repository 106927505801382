import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "react-select";
import ButtonStyled from "../Buttons/ButtonStyled";
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import Image from "../Images/Image";
import { useDispatch, useSelector } from "react-redux";
import {
    setFreeTrialFormSubmission,
    setFreeTrialIsLoading,
    setFreeTrialSubmissionError
} from "@resourcehub/resourcehub-data/src/store/free-trial/free-trial-actions";
import { userDeviceServices, emailServices } from "@resourcehub/resourcehub-services";
import { setGlobalLanguageId } from "@resourcehub/resourcehub-data/src/store/global/global-actions";
import { emailErrors, freeTrialError } from "./freeTrialErrors";
import GdprEmailCheckbox from "../GdprEmailCheckbox/GdprEmailCheckbox";
import { determineGdprEmailConsent } from "../../hooks/determineGdprEmailConsent";
import { globalStore, freeTrialFormStore } from "@resourcehub/resourcehub-data";
const { globalSelectors } = globalStore;
const { freeTrialFormSelectors } = freeTrialFormStore;

const FormTrialWrapperDiv = styled.div`
    label: FormTrialWrapperDiv;

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    margin: 1em 1em 2em;
    > div {
        display: inline-flex;
        width: 100%;
        margin-bottom: 0.2em;
        margin-top: 0.2em;
    }
`;

const SelectFieldWrapper = styled.div`
    label: SelectFieldWrapper;
`;

const SelectFieldStyled = styled(Select)`
    label: SelectFieldStyled;
    width: 100%;
    color: #333333;
    font-family: effra, sans-serif;
    font-size: 12pt;
`;

const TextFieldWrapper = styled.div`
    label: TextFieldWrapper;

    input {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #cccccc;
        color: #333333;
        cursor: text;
        font-family: effra, sans-serif;
        font-size: 12pt;
        padding: 10px;
    }
`;

const OptionStyled = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div {
        margin-right: 10px;
    }
`;

const GdprCheckboxWrapper = styled.div`
    display: block;
    font-family: effra, sans-serif;
`;

const FreeTrialButtonWrapper = styled.div`
    width: 100%;
    a {
        width: 100%;
        padding: 1.3em 0;
    }
`;

const formatOptionLabel = ({ label, label_sub, icon }) => (
    <OptionStyled>
        <Image image={icon} alt={label} />
        <div>{label}</div>
        <div>{label_sub}</div>
    </OptionStyled>
);

const ErrorMessage = styled.div`
    color: red;
    font-size: 20px;
    font-family: effra, sans-serif;
    display: flex;
    justify-content: center;
`;

const FormTrial = ({
    isHomeschool,
    button_submit,
    children,
    dataQa,
    form_field_email,
    form_field_password,
    form_field_language,
    siteCode,
    siteLang,
    locale,
    guid,
    gdpr_checkbox_text,
    gdpr_more_info_text,
    gdpr_more_info_link_text
}) => {
    const dispatch = useDispatch();

    const [userDevice, setUserDevice] = useState("desktop");
    const [emailInputValue, setEmailInputValue] = useState("");
    const [passwordInputValue, setPasswordInputValue] = useState("");

    const {
        emailConsent,
        updateGdprEmailConsent,
        toggleEmailInfoText,
        displayEmailInfoText,
        displayEmailConsentCheckbox,
        country
    } = determineGdprEmailConsent();

    const isLoading = useSelector(freeTrialFormSelectors.getIsLoading);
    const freeTrialResponse = useSelector(freeTrialFormSelectors.getSubmissionResponse);
    const languageId = useSelector(globalSelectors.getLanguageId);
    const error = useSelector(freeTrialFormSelectors.getSubmissionError);

    useEffect(() => {
        // run on mount
        setUserDevice(
            process.env.STORYBOOK ? userDeviceServices.getUserDeviceStorybook() : userDeviceServices.getUserDevice()
        );
    }, []);

    useEffect(() => {
        // runs every time the response in the free trial store changes
        if (freeTrialResponse) {
            if (freeTrialResponse.returnCode !== "0" && freeTrialResponse.returnCode !== "1") {
                const error =
                    freeTrialError[locale][freeTrialResponse.returnCode] ||
                    freeTrialError[locale]["unknown"] ||
                    freeTrialError["en-US"][freeTrialResponse.returnCode] ||
                    freeTrialError["en-US"]["unknown"];

                dispatch(setFreeTrialSubmissionError(error.join("")));
                dispatch(setFreeTrialIsLoading(false));
                return;
            }

            if (freeTrialResponse.returnCode === "0" || freeTrialResponse.returnCode === "1") {
                dispatch(setFreeTrialSubmissionError(null));
                redirectUser();
            }

            dispatch(setFreeTrialSubmissionError(null));
        }
    }, [freeTrialResponse]);

    const urlParams = new URLSearchParams(document.location.search);

    const validateFormSubmissionEmail = () => {
        const normalizedLocale = normalizeLocale(locale);
        const isValid = emailServices.validateEmail(emailInputValue);
        if (!isValid) {
            dispatch(setFreeTrialSubmissionError(emailErrors[normalizedLocale].join()));
            dispatch(setFreeTrialIsLoading(false));
            return;
        }
        return isValid;
    };

    const validateFormSubmissionPassword = () => {
        const normalizedLocale = normalizeLocale(locale);
        if (userDevice !== "desktop" && !emailServices.validateFreetrialPassword(passwordInputValue)) {
            dispatch(setFreeTrialSubmissionError(freeTrialError[normalizedLocale].invalidPassword.join("")));
            dispatch(setFreeTrialIsLoading(false));
            return false;
        }
        return true;
    };

    const normalizeLocale = (locale) => {
        const localeMap = {
            "en-US": "en",
            "es-419": "es",
            pt: "pt",
            ko: "ko"
        };

        const mapped = localeMap[locale];
        if (!mapped) {
            console.error("unknown locale passed to toStandardLocale falling back to en-US: ", locale);
            return "en-US";
        }
        return mapped;
    };

    const createFormSubmissionPayload = () => {
        const emailOptedIn = country.toUpperCase() === "US" || emailConsent === "active";
        const normalizedLocale = normalizeLocale(locale);

        const firstName = process.env.STORYBOOK ? "Customer" : process.env.FREETRIAL_FIRST_NAME;
        const lastName = process.env.STORYBOOK ? "CustomerLast" : process.env.FREETRIAL_LAST_NAME;

        const homeschoolPayload = {
            licenseType: "HSUB",
            siteLang,
            trialLanguage: languageId.toUpperCase(),
            firstName,
            lastName,
            emailAddress: emailInputValue,
            password: passwordInputValue,
            consentType: emailConsent || "unknown",
            siteCode,
            countryCode: country || "unknown",
            guid,
            cid: urlParams.get("cid") || "",
            emailPermissionStatus: emailOptedIn ? "I" : "O",
            verifyEmail: true
        };

        const consumerPayload = {
            licenseType: "Totale",
            siteLang,
            trialLanguage: languageId.toUpperCase(),
            firstName,
            emailAddress: emailInputValue,
            password: passwordInputValue,
            language: languageId,
            consentType: emailConsent || "unknown",
            siteCode,
            cid: urlParams.get("cid") || "",
            countryCode: country || "unknown",
            guid,
            mkt: normalizedLocale,
            emailPermissionStatus: emailOptedIn ? "I" : "O",
            verifyEmail: true
        };

        return isHomeschool ? homeschoolPayload : consumerPayload;
    };

    const redirectUser = () => {
        let redirectUrl = null;

        let totaleUrl = process.env.STORYBOOK
            ? "https://totale.rosettastone.com/token_authentication?"
            : process.env.FREETRIAL_TOTALE_URL;

        // if Desktop - redirect to product
        // purchase=true lets product know to include DTM
        // https://jira.trstone.com/browse/TCOM-2024
        if (userDevice === "desktop") {
            const normalizedLocale = normalizeLocale(locale);

            // We can only log a user in if we have a token
            if (freeTrialResponse && freeTrialResponse.lsToken) {
                totaleUrl +=
                    "dtm_trial_type=nocc" +
                    "&purchase=true" +
                    `&target_language=${languageId.toUpperCase()}` +
                    `&username=${emailInputValue}` +
                    "&accepted_terms=true" +
                    `&authToken=${freeTrialResponse.lsToken}` +
                    `&mkt=${normalizedLocale}` +
                    `&locale=${locale}` +
                    "&namespace=";

                if (freeTrialResponse.newUser === "true") {
                    totaleUrl += "&welcome=true";
                }
            } else {
                // If we don't have a token, send them to login page with trial language
                totaleUrl = totaleUrl.replace("token_authentication", "sign_in");
                totaleUrl += `&target_language=${languageId.toUpperCase()}`;
            }

            redirectUrl = totaleUrl;
            console.log("redirecting to " + redirectUrl);
        } else {
            // if mobile, redirect to app store
            redirectUrl = "/lp/form-mobile-success";
        }

        window.setTimeout(() => {
            window.location.href = redirectUrl;
        }, 600);
    };

    const onSubmitHandler = () => {
        dispatch(setFreeTrialIsLoading(true));

        const isEmailValid = validateFormSubmissionEmail();
        if (!isEmailValid) return;

        const isPasswordValid = validateFormSubmissionPassword();
        if (!isPasswordValid) return;

        dispatch(setFreeTrialSubmissionError(null));

        const payload = createFormSubmissionPayload();
        dispatch(setFreeTrialFormSubmission(payload));
    };

    return (
        <FormTrialWrapperDiv data-qa={`trial-form-header-${dataQa}`}>
            <SelectFieldWrapper>
                <SelectFieldStyled
                    className="basic-single"
                    classNamePrefix="select"
                    formatOptionLabel={formatOptionLabel}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name={form_field_language.name}
                    defaultValue={form_field_language.options[0]}
                    options={form_field_language.options}
                    onChange={(selectedLanguage) => dispatch(setGlobalLanguageId(selectedLanguage.value))}
                />
            </SelectFieldWrapper>
            <TextFieldWrapper>
                <input
                    {...form_field_email}
                    disabled={isLoading}
                    onChange={(event) => setEmailInputValue(event.target.value)}
                />
            </TextFieldWrapper>

            {userDevice !== "desktop" && (
                <TextFieldWrapper>
                    <input
                        {...form_field_password}
                        disabled={isLoading}
                        onChange={(event) => setPasswordInputValue(event.target.value)}
                    />
                </TextFieldWrapper>
            )}

            <FreeTrialButtonWrapper>
                <ButtonStyled onClick={() => onSubmitHandler()} disabled={isLoading} {...button_submit} />
            </FreeTrialButtonWrapper>

            <ChildContentWrapper>{children}</ChildContentWrapper>

            <ErrorMessage>{error?.length && error}</ErrorMessage>

            {displayEmailConsentCheckbox && (
                <GdprCheckboxWrapper>
                    <GdprEmailCheckbox
                        displayEmailInfoText={displayEmailInfoText}
                        updateGdprEmailConsent={updateGdprEmailConsent}
                        toggleEmailInfoText={toggleEmailInfoText}
                        gdpr_checkbox_text={gdpr_checkbox_text}
                        gdpr_more_info_text={gdpr_more_info_text}
                        gdpr_more_info_link_text={gdpr_more_info_link_text}
                    />
                </GdprCheckboxWrapper>
            )}
        </FormTrialWrapperDiv>
    );
};

export const FormTrialPropTypes = {
    dataQa: PropTypes.string,
    isHomeschool: PropTypes.bool.isRequired,
    body: PropTypes.string,
    form_field_email: PropTypes.object.isRequired,
    form_field_language: PropTypes.object.isRequired,
    button_submit: PropTypes.object.isRequired,
    legalese: PropTypes.object,
    guid: PropTypes.string,
    siteCode: PropTypes.string,
    siteLang: PropTypes.string,
    locale: PropTypes.string
};

FormTrial.propTypes = FormTrialPropTypes;
FormTrial.defaultProps = {
    direction: "image-left",
    title_type: "section-title",
    // default guid to 3 day trial
    guid: "759049fd-c91b-493e-afd1-29cce286cea9",
    // Possible siteCode options the API is expecting:
    // US_WEBSITE, KR_WEBSITE, BR_WEBSITE, and EH_WEBSITE
    // EH_WEBSITE is for es-419 and es-ES
    siteCode: "US_WEBSITE",
    // Possible siteLang options the API is expecting:
    // ENG, ESP, KOR, POR
    siteLang: "ENG",
    // Possible locale options:
    //  en-US, es-419, pt, ko =>
    //  en     es      pt  ko
    // locale gets passed into normalizeLocale() to send a simplified locale
    // version that the API is expecting for the 'mkt' value in the payload
    // as well as the locale that gets sent in the totaleUrl when redirecting the user
    // possible locale options can be changed if needed, as normalizeLocale() is using a dictionary
    locale: "en-US"
};

export default FormTrial;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

/**
 * Falls back to using an anchor tag if useLink is false
 * @param {Object} params
 * @param {boolean} params.useLink switch for link vs anchor, link=true
 * @param {string} params.destination where to link to
 * @param {string|function} params.children children to display
 * @param {string} [params.classes=''] classes to use
 */
const AnchorFallback = ({ useLink, destination, children, ...restProps }) => {
    if (useLink) {
        return (
            <Link to={destination} {...restProps}>
                {children}
            </Link>
        );
    }
    return (
        <a href={destination} {...restProps}>
            {children}
        </a>
    );
};

AnchorFallback.defaultProps = {
    classes: ""
};

AnchorFallback.propTypes = {
    destination: PropTypes.string.isRequired,
    classes: PropTypes.string
};

export default AnchorFallback;

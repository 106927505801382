import React, { useState } from "react";
import DropDownLink from "./DropDownLink";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const FileDropDownWrapper = styled.div`
    z-index: 5;
    position: relative;
    font-family: effra_medium, sans-serif;
    background: white;
    color: #4a4a4a;
    border-radius: ${({ isDropDownActive }) => (isDropDownActive ? "8px 8px 0 0" : "8px")};
    margin-right: 1em;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
    transition: box-shadow 0.1s ease-in-out, width 0.3s;

    button {
        padding: 1em;
        color: ${({ isDropDownActive }) => (isDropDownActive ? "black" : "#4a4a4a")};
        border-radius: 8px;
        border: 0;
        text-align: left;
        width: 100%;

        font-family: effra_medium, sans-serif;
        font-size: 14px;
        font-weight: 400;

        &:focus {
            padding: calc(1em - 1px);
            width: 100%;
            height: auto;
        }
    }

    a,
    button {
        &:focus {
            outline: none;
            border: 1px solid #72c6ec;
            box-shadow: 0 0 8px #72c6ec;
        }
    }

    a {
        padding: 10px 1em;

        &:focus {
            padding: 9px calc(1em - 1px);
        }
    }

    a:last-of-type {
        padding: 10px 1em 1em;

        &:focus {
            padding: 9px calc(1em - 1px) calc(1em - 1px);
        }
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px 0px;
    }

    &:focus {
        padding: calc(1em - 1px);
        outline: none;
        border: 1px solid #72c6ec;
        box-shadow: 0 0 8px #72c6ec;
    }
`;

const DropdownActiveStyles = css`
    div {
        height: ${({ isOpen }) => (isOpen ? "inherit" : "0px")};
        display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    }
`;

const DropdownBody = styled.div`
    position: absolute;
    background: white;
    border-radius: 0 0 8px 8px;
    width: 100%;
    top: 41px;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 20px 0;

    ul {
        padding: 0;
        margin: 0;
        li {
            display: flex;
            width: 100%;
            border-top: 1px solid #dbdbdb;
            &:hover {
                background: #eee;
            }
        }
    }

    ${DropdownActiveStyles}
`;

const FilterDropDown = ({ btnText, links, resourcesLink }) => {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    const handleMouseEnter = () => setIsDropDownOpen(true);

    const handleMouseLeave = () => setIsDropDownOpen(false);

    const handleClick = () => setIsDropDownOpen((prevIsDropDownOpen) => !prevIsDropDownOpen);

    const dropDownLinks = links.map((link, index) => {
        const key = `${link.title} ${index + 1}`;
        return <DropDownLink key={key} resourcesLink={resourcesLink} link={link} />;
    });

    return (
        <FileDropDownWrapper
            isDropDownOpen={isDropDownOpen}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <button type="button" onClick={handleClick}>
                {btnText}
            </button>
            <DropdownBody>{isDropDownOpen && dropDownLinks}</DropdownBody>
        </FileDropDownWrapper>
    );
};

export const FilterDropDownPropTypes = {
    resourcesLink: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    btnText: PropTypes.string.isRequired
};

FilterDropDownPropTypes.propTypes = FilterDropDownPropTypes;

export default FilterDropDown;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Styles
import BodyWrapper, { BodyWrapperPropTypes } from "../component-styles/BodyWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

// Components
import SectionStone, { flareOptions, hTypes } from "../components/Stones/SectionStone";

const StoneTextSection = styled.section`
    label: StoneTextSection;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        flex-direction: column;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        flex-direction: column;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: column;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        flex-direction: column;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        flex-direction: row;
    }
`;
const MediaWrapper = styled.div`
    label: MediaWrapper;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 2em;
        ${({ direction }) => {
            if (direction === "content-left") {
                return `
                    flex-direction: row;
                    display: flex;
                    justify-content: flex-end;
                    align-content: flex-end;
                    margin-left: auto;
                    margin-right: 30px;
                `;
            }
            return `
                    flex-direction: row-reverse;
                    display: flex;
                    justify-content: flex-end;
                    align-content: flex-end;
                    margin-right: auto;
                    margin-left: 30px;
                `;
        }}
        img {
            max-height: 400px;
            vertical-align: middle;
        }
    }
`;
const ContentWrapper = styled.div`
    label: ContentWrapper;

    text-align: left;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: 5px;
        margin-right: 5px;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: 10px;
        margin-right: 10px;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: 20px;
        margin-right: 20px;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 30px;
        margin-right: 30px;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        max-width: 60%;
        padding-left: 0;
        //padding-right: 20%;
        margin-left: 30px;
        margin-right: 30px;
    }
`;

const PageSectionStoneText = ({
    dataQa,
    layoutDirection,
    title,
    titleFlare,
    titleHType,
    body,
    childPosition,
    children
}) => {
    return (
        <StoneTextSection>
            <MediaWrapper id={"media-wrapper"} direction={layoutDirection}>
                <SectionStone flare={titleFlare} hType={titleHType} text={title} />
            </MediaWrapper>
            <ContentWrapper id={"content-wrapper"}>
                {children && childPosition === "top" && <ChildContentWrapper>{children}</ChildContentWrapper>}
                <BodyWrapper data-qa={`stone-text-body-${dataQa}`} {...body} dangerous={true} />
                {children && childPosition === "bottom" && <ChildContentWrapper>{children}</ChildContentWrapper>}
            </ContentWrapper>
        </StoneTextSection>
    );
};

export const PageSectionStoneTextPropTypes = {
    dataQa: PropTypes.string.isRequired,
    body: PropTypes.shape(BodyWrapperPropTypes).isRequired,
    direction: PropTypes.oneOf(["content-left", "content-right"]),
    titleFlare: PropTypes.oneOf(flareOptions),
    titleHType: PropTypes.oneOf(hTypes),
    childPosition: PropTypes.oneOf(["top", "bottom"]),
    title: PropTypes.string.isRequired
};

PageSectionStoneText.defaultProps = {
    childPosition: "bottom",
    layoutDirection: "content-left",

    titleFlare: "blue-stone",
    titleHType: "h2"
};
PageSectionStoneText.propTypes = PageSectionStoneTextPropTypes;
export default PageSectionStoneText;

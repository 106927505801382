import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Styles
import Image, { ImagePropTypes } from "../Images/Image";
import { textEmphasis } from "@resourcehub/resourcehub-assets";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import BodyWrapper, { BodyWrapperPropTypes } from "../../component-styles/BodyWrapper";

const ContentDiv = styled.div`
    label: ContentDiv;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column-reverse;

    ${({ visible }) => (visible ? "display: flex;" : "display: none;")};
    @media (min-width: 768px) {
        ${({ direction }) => {
            switch (direction) {
                case "toggle-left":
                    return "flex-direction: row;";
                case "toggle-right":
                    return "flex-direction: row-reverse";
            }
        }};
    }
`;
const StaticContentDiv = styled.div`
    label: StaticContentDiv;

    display: flex;
    flex-direction: column;
    max-width: 350px;
    min-width: 350px;

    ${({ direction }) => {
        switch (direction) {
            case "left":
                return "align-items: flex-start;";
            case "right":
                return "align-items: flex-end;";
        }
    }};
`;

const ImageDiv = styled.div`
    label: ImageDiv;

    padding: 20px;
    min-height: 250px;
    ${({ open }) => (open ? "display: none;" : "display: inline-block;")};
    @media (min-width: 992px) {
        display: inline-block;
    }
`;
const BodyDiv = styled.div`
    label: BodyDiv;

    align-self: center;
    color: #262626;
    display: flex;
    flex-direction: column;
    font-family: effra, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    position: relative;
    padding: 20px;
    text-align: left;

    ${({ align }) => {
        switch (align) {
            case "left":
                return "text-align: left;";
            case "center":
                return "text-align: center;";
            case "right":
                return "text-align: right;";
        }
    }};

    > img {
        height: 100px;
        width: 100px;
        position: absolute;
        z-index: -100;
    }
    > img:nth-of-type(1) {
        align-self: flex-start;
        bottom: 0;
        left: 0;
    }
    > img:nth-of-type(2) {
        align-self: flex-end;
        top: 0;
        right: 0;
    }
`;

const ToggleSwitch = styled.div`
    label: ToggleSwitch;

    ${({ open }) => (open ? "transform: rotate(45deg);" : "transform: rotate(90deg);")};
    ${({ direction }) => {
        switch (direction) {
            case "toggle-left":
                return "align-self: flex-start;";
            case "toggle-right":
                return "align-self: flex-end;";
        }
    }};

    border-radius: 50%;
    width: 38px;
    height: 38px;
    background-color: #f0c433;
    position: relative;
    ::after {
        background-color: #fff;
        content: " ";
        display: block;
        height: 5px;
        left: 7px;
        margin-top: -5px;
        position: absolute;
        right: 8px;
        top: 56%;
        z-index: 9;
    }
    ::before {
        background-color: #fff;
        bottom: 7px;
        content: " ";
        display: block;
        left: 56%;
        margin-left: -5px;
        position: absolute;
        top: 7px;
        width: 5px;
        z-index: 9;
    }
`;
const ToggleContentDiv = styled.div`
    label: ToggleContentDiv;

    display: flex;
    flex-direction: column;

    margin: 20px;
    padding: 20px;
    ${({ open }) => (open ? "display: inline-block;" : "display: none;")};
`;
class ToggleContent extends React.Component {
    state = {
        open: false
    };

    toggle = () => {
        const { emit_change, index } = this.props;
        const { open } = this.state;
        const newValue = !open;
        this.setState({
            open: newValue
        });
        if (emit_change) {
            emit_change(index, newValue);
        }
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.toggle_state &&
            this.props.toggle_state !== this.state.open &&
            prevProps.toggle_state !== this.props.toggle_state
        ) {
            this.setState({
                open: this.props.toggle_state
            });
        }
    }

    render() {
        const { dataQa, direction, image, title, body, toggle, visible } = this.props;
        return (
            <ContentDiv direction={direction} data-qa={dataQa} visible={visible}>
                <StaticContentDiv onClick={this.toggle}>
                    <ToggleSwitch open={this.state.open} direction={direction} />
                    {image && (
                        <ImageDiv open={this.state.open}>
                            <Image image={image} />
                        </ImageDiv>
                    )}
                    {title && <TitleWrapper {...title} />}
                    {body && <BodyWrapper {...body} />}
                </StaticContentDiv>
                <ToggleContentDiv open={this.state.open}>
                    {toggle.body && (
                        <BodyDiv {...toggle.body}>
                            <img src={textEmphasis.brushYellowCornerLeftPng} alt="" height="100%" width="100%" />
                            {toggle.title && <TitleWrapper {...toggle.title} />}
                            {toggle.body.content && <div dangerouslySetInnerHTML={{ __html: toggle.body.content }} />}
                            <img src={textEmphasis.brushYellowCornerRightPng} alt="" height="100%" width="100%" />
                        </BodyDiv>
                    )}
                </ToggleContentDiv>
            </ContentDiv>
        );
    }
}

export const ToggleContentPropTypes = {
    dataQa: PropTypes.string,
    visible: PropTypes.bool,
    direction: PropTypes.oneOf(["toggle-left", "toggle-right"]),
    image: PropTypes.shape(ImagePropTypes).isRequired,

    // Static Content
    title: PropTypes.shape(TitleWrapperPropTypes),
    body: PropTypes.shape(BodyWrapperPropTypes),

    // Toggle Content
    toggle: {
        title: PropTypes.shape(TitleWrapperPropTypes),
        body: PropTypes.shape(BodyWrapperPropTypes)
    },
    toggle_state: PropTypes.bool,
    emit_change: PropTypes.func
};
ToggleContent.propTypes = ToggleContentPropTypes;
ToggleContent.defaultProps = {
    direction: "toggle-left",
    toggle_state: false,

    title: {
        align: "center",
        type: "sub-title",
        theme: "black"
    },

    body: {
        align: "center"
    },

    toggle: {
        title: {
            align: "left",
            theme: "black",
            type: "sub-title"
        },
        body: {
            align: "center"
        }
    }
};
export default ToggleContent;

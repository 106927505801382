import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { configServices } from "@resourcehub/resourcehub-services";
import GlobalStyles from "../component-styles/GlobalStyles.jsx";
import OverlayStyled from "../component-styles/OverlayStyled.jsx";
import HelmetWrapper from "../components/HelmetWrapper/HelmetWrapper";
import NavConnected, { NarwhalNav } from "../components/Header/NarwhalNav";
import GoogleTagManager from "../components/Analytics/GoogleTagManager";
import GutterWrapper from "../component-styles/GutterWrapper";
import PageSectionVertical from "../page-sections/PageSectionVertical";
import PageSectionImage from "../page-sections/PageSectionImage";
import PageSectionQuoteBranded from "../page-sections/PageSectionQuoteBranded";
import NarwhalFooter from "../components/Footer/NarwhalFooter";
import LeadGenerationBarConnected, { LeadGenerationBar } from "../components/LeadGeneration/LeadGenerationBar";
import EmailCollectionBarConnected, { EmailCollectionBar } from "../components/EmailCollection/EmailCollectionBar";
import Disclaimer from "../components/Disclaimers/Disclaimer";
import { datadogRum } from "@datadog/browser-rum";

const CyberweekHolidayBanner = styled.article`
    width: 100%;
    background: #00b1ff;
    color: #fff;
    display: flex;
    padding: 10px;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    min-height: 52px;
    font-weight: 500;
    align-items: center;

    div {
        display: flex;
        text-align: center;
        align-items: center;

        @media (max-width: 820px) {
            flex-direction: column;
            margin: 0 50px;
        }

        a {
            background: #ffd753;
            border-radius: 5px;
            border: none;
            padding: 7px 16px;
            color: #242424;
            margin-left: 15px;

            @media (max-width: 820px) {
                margin: 10px 0 5px;
            }
        }
    }
`;

const CyberweekBarToggle = styled.span`
    color: #fff;
    font-weight: bold;
    position: absolute;
    right: 12px;
    top: 9%;
    font-size: 40px;
    cursor: pointer;

    @media (max-width: 820px) {
        top: -1%;
    }
`;

const ContentWrapper = styled.div`
    label: ContentWrapper;

    .gatsby-image-wrapper {
        margin: auto;
    }
`;

class NarwhalPageTemplateWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            openDropdown: null,
            cartProducts: [],
            cartPrices: [],
            cartCount: 0,
            showCyberweekBanner: true
        };

        if (process.env.NODE_ENV !== "development") {
            datadogRum.init({
                applicationId: "88d124a6-1417-49b9-bbd8-025a3dcec5e9",
                clientToken: "pub0ac8eaffa2748278527260748bccc933",
                site: "datadoghq.com",
                env: "prod",
                service: "www.rosettastone.com",
                sampleRate: 10,
                trackInteractions: true,
                defaultPrivacyLevel: "mask-user-input"
            });
            datadogRum.startSessionReplayRecording();
        }
    }

    // Lifecycle Methods
    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    render() {
        const { children, config, connected } = this.props;
        const { openDropdown } = this.state;
        return (
            <div>
                <GlobalStyles />
                {config.page.helmet && <HelmetWrapper {...config.page.helmet} />}
                {configServices.hasChildObjectVisible(config.page.content, "cyberweek_banner") &&
                    this.state.showCyberweekBanner && (
                        <CyberweekHolidayBanner>
                            <div>
                                {config.page.content.cyberweek_banner.text}
                                <a href={config.page.content.cyberweek_banner.button.destination}>
                                    {config.page.content.cyberweek_banner.button.text}
                                </a>
                            </div>
                            <CyberweekBarToggle onClick={() => this.setState({ showCyberweekBanner: false })}>
                                &times;
                            </CyberweekBarToggle>
                        </CyberweekHolidayBanner>
                    )}
                {configServices.hasChildObjectVisible(config, "nav") && connected && (
                    <NavConnected
                        config={config.nav}
                        openDropdown={openDropdown}
                        setOpenDropdown={this.setOpenDropdown}
                    />
                )}
                {configServices.hasChildObjectVisible(config, "nav") && !connected && (
                    <NarwhalNav
                        config={config.nav}
                        openDropdown={openDropdown}
                        setOpenDropdown={this.setOpenDropdown}
                    />
                )}
                <OverlayStyled
                    role="menuitem"
                    showOverlay={openDropdown !== null && openDropdown !== "Mobile Nav"}
                    onClick={this.overlayClick}
                />
                <ContentWrapper>{children}</ContentWrapper>
                {configServices.hasChildObjectVisible(config.page.content, "verticals") && (
                    <GutterWrapper>
                        <PageSectionVertical {...config.page.content.verticals} />
                    </GutterWrapper>
                )}
                {configServices.hasChildObjectVisible(config.page.content, "awards") && (
                    <GutterWrapper>
                        <PageSectionImage {...config.page.content.awards} />
                    </GutterWrapper>
                )}
                {configServices.hasChildObjectVisible(config.page.content, "branded_quotes") && (
                    <GutterWrapper>
                        <PageSectionQuoteBranded {...config.page.content.branded_quotes} />
                    </GutterWrapper>
                )}
                {configServices.hasChildObjectVisible(config.page.content, "lead_generation_bar") && connected && (
                    <LeadGenerationBarConnected {...config.page.content.lead_generation_bar} />
                )}
                {configServices.hasChildObjectVisible(config.page.content, "lead_generation_bar") && !connected && (
                    <LeadGenerationBar {...config.page.content.lead_generation_bar} />
                )}
                {configServices.hasChildObjectVisible(config.page.content, "email_collection_bar") && connected && (
                    <EmailCollectionBarConnected {...config.page.content.email_collection_bar} />
                )}
                {configServices.hasChildObjectVisible(config.page.content, "email_collection_bar") && !connected && (
                    <EmailCollectionBar {...config.page.content.email_collection_bar} />
                )}
                {configServices.hasChildObjectVisible(config.page.content, "disclaimer") && (
                    <Disclaimer {...config.page.content.disclaimer} />
                )}
                {configServices.hasChildObjectVisible(config, "footer") && (
                    <NarwhalFooter config={config.footer} eloquaPage={config.page.eloquaPage} />
                )}
                <GoogleTagManager gtm_id={config.analytics.gtm_id} />
            </div>
        );
    }

    // Custom Methods
    setOpenDropdown = (openDropdown) => {
        this.setState({
            openDropdown
        });
    };

    resize = () => {
        const MOBILE_BREAKPOINT = 1079;
        const { openDropdown, isMobile } = this.state;
        this.setState({
            isMobile: document.body.getBoundingClientRect().width <= MOBILE_BREAKPOINT
        });
        if (!isMobile && openDropdown === "Mobile Nav") {
            this.setState({
                openDropdown: null
            });
        } else if (isMobile && openDropdown === "Languages & Pricing") {
            this.setState({
                openDropdown: null
            });
        }
    };

    overlayClick = () => {
        this.setState({
            openDropdown: null
        });
    };
}

NarwhalPageTemplateWrapper.propTypes = {
    config: PropTypes.object,
    children: PropTypes.node,
    connected: PropTypes.bool
};
NarwhalPageTemplateWrapper.defaultProps = {
    connected: true
};

export default NarwhalPageTemplateWrapper;

import React from "react";
import styled from "styled-components";
import { awards, images } from "@resourcehub/resourcehub-assets";
import TitleWrapper from "../../component-styles/TitleWrapper";
import BodyWrapper from "../../component-styles/BodyWrapper";

const Hero = styled.div`
    background: url(${images.usAwardsMastheadJpeg}) no-repeat;
    background-size: cover;
    min-height: 558px;

    @media (max-width: 768px) {
        min-height: 359px;
    }
`;

const HeroContentWrapper = styled.div`
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1060px;

    h2 {
        color: #fff;
        font-family: Helvetica, sans-serif;
        font-weight: 700;
        font-size: 112px;
        padding: 162px 0 17px;
        margin: 0;
        letter-spacing: normal;

        @media (max-width: 830px) {
            font-size: 3.5em;
            padding: 50px 0 17px;
        }

        @media (max-width: 500px) {
            font-size: 2.5rem;
        }
    }

    div {
        color: #fff;
        font-family: Helvetica, sans-serif;
        font-weight: 700;
        font-size: 32px;
        line-height: 1.3em;
        letter-spacing: 0.2px;

        @media (max-width: 768px) {
            line-height: 1.45em;
            letter-spacing: 1px;
            font-weight: 400;
            font-size: 18px;
        }
    }
`;

const PCMagYellowSection = styled.div`
    background: #f2c000;
    padding: 40px 0;

    > div {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        max-width: 1060px;
        padding: 0 15px;

        @media (max-width: 660px) {
            flex-direction: column;
        }
    }

    img {
        width: 240px;
        height: 100px;
        margin: 0 0 30px;
        display: inline-block;
    }
`;

const PCMagText = styled.div`
    width: 100%;
    display: inline-block;
    padding: 8px 0 0;
    margin-left: 35px;

    @media (max-width: 660px) {
        margin: 0;
    }

    h4 {
        color: #333;
        font-size: 32px;
        line-height: 24px;
        letter-spacing: 2.2px;
        margin: 0 0 8px;
        font-family: effra_medium, sans-serif;

        a {
            color: #333;
        }
    }

    p {
        color: #333;
        font-size: 24px;
        letter-spacing: -0.5px;
        line-height: 29px;
        margin: 0;
        font-family: effra, sans-serif;

        a {
            color: #333;
        }
    }
`;

const AwardsContent = styled.div`
    padding: 58px 0 0;
    max-width: 1060px;
    margin: 0 auto;
    @media (max-width: 768px) {
        padding: 8px 15px;
    }

    h2 {
        font-size: 48px;
        letter-spacing: 0.24px;
        line-height: 58px;
    }

    h3 {
        font-size: 40px;
        letter-spacing: 0.2px;
        line-height: 48px;
        padding: 74px 0 0;
        margin: 0 0 10px;
    }

    p {
        font-family: effra, sans-serif;
        font-size: 16px;
        margin: 0 0 10px;
    }
`;

const AwardsBoxes = styled.div`
    font-family: effra, sans-serif;

    > div {
        margin: 100px auto;
        max-width: 1060px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        align-items: center;
        justify-items: center;
        gap: 30px;
        text-align: center;

        @media (max-width: 860px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 540px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        p {
            color: #333;
            font-size: 16px;
            line-height: 19px;
            max-width: 330px;
            margin: 8px auto 0;
            font-family: effra, sans-serif;
            letter-spacing: -0.01em;
        }

        b {
            font-family: effra_bold, sans-serif;
        }

        img {
            display: flex;
            margin: 0 auto;
            width: 50%;
            height: 50%;
        }
    }
`;

const PageTemplateConsumerProductAwards = (config) => (
    <>
        <Hero>
            <HeroContentWrapper>
                <TitleWrapper {...config.page.content.sections[0].title} />
                <BodyWrapper {...config.page.content.sections[0].body} />
            </HeroContentWrapper>
        </Hero>

        <PCMagYellowSection>
            <div>
                <a target="_blank" href={config.page.content.sections[1].pcmag.imageLink} rel="noreferrer">
                    <img src={awards.awardPcMagPng} />
                </a>
                <PCMagText>
                    <h4>
                        <a target="_blank" href={config.page.content.sections[1].pcmag.imageLink} rel="noreferrer">
                            {config.page.content.sections[1].pcmag.yearsInARow}
                        </a>
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href={config.page.content.sections[1].pcmag.imageLink}
                            rel="noreferrer"
                            dangerouslySetInnerHTML={{ __html: config.page.content.sections[1].pcmag.text }}
                        />
                    </p>
                </PCMagText>
            </div>
        </PCMagYellowSection>

        <AwardsContent>
            <div>
                <h2>{config.page.content.sections[2].awardContent.h2}</h2>
                <h3>{config.page.content.sections[2].awardContent.h3}</h3>
                <p>{config.page.content.sections[2].awardContent.p}</p>
            </div>
        </AwardsContent>

        <AwardsBoxes>
            <div>
                {config.page.content.sections[3].awardBoxes.map(({ image, link, text }, index) => (
                    <div key={index}>
                        <a target="_blank" href={link} rel="noreferrer">
                            <img src={image} />
                            <p dangerouslySetInnerHTML={{ __html: text }} />
                        </a>
                    </div>
                ))}
            </div>
        </AwardsBoxes>
    </>
);

PageTemplateConsumerProductAwards.propTypes = {};
PageTemplateConsumerProductAwards.defaultProps = {};

export default PageTemplateConsumerProductAwards;

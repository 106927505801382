import React from "react";
import PropTypes from "prop-types";
import NarwhalMeta from "./NarwhalMeta";

const NarwhalFooter = ({ config }) => {
    return (
        <footer data-qa={"footer-section"}>
            {config.meta && config.meta.visible && <NarwhalMeta config={config.meta} />}
        </footer>
    );
};

NarwhalFooter.propTypes = {
    config: PropTypes.object.isRequired
};

export default NarwhalFooter;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const GutterStyled = styled.div`
    label: GutterStyled;
    
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        padding-left: 5%;
        padding-right: 5%;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        padding-left: 10%;
        padding-right: 10%;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        padding-left: 10%;
        padding-right: 10%;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        padding-left: 10%;
        padding-right: 10%;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        
    ${({ gutter }) => {
        if (gutter === "double") {
            return `
                padding-left: 20%;
                padding-right: 20%;
            `;
        }
        return `
            padding-left: 10%;
            padding-right: 10%;
        `;
    }}

    ${({ debug }) =>
        debug &&
        `
            > div, header {
                border: red solid 1px;
            }
            > section {
                border: blue solid 1px;
            }
        `}
`;

const GutterWrapper = ({ children, debug, gutter }) => (
    <GutterStyled debug={debug} gutter={gutter}>
        {children}
    </GutterStyled>
);

const GutterWrapperPropTypes = {
    debug: PropTypes.bool
};
GutterWrapper.propTypes = GutterWrapperPropTypes;
GutterWrapper.defaultProps = {
    debug: false,
    gutter: null
};
export default GutterWrapper;

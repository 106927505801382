import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image, { ImagePropTypes } from "../Images/Image";
import FeatureIcon, { FeatureIconPropTypes } from "./FeatureIcon";

const FeatureListHoverContent = styled.div`
    label: FeatureListHoverContent;

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    font-family: effra, sans-serif;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        flex-direction: column;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        flex-direction: column;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: column;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        flex-direction: row;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        flex-direction: row;
    }
`;

const ImageWrapper = styled.div`
    flex: 3;
`;
const FeatureListWrapper = styled.div`
    // Feature

    flex: 2;
    font-family: effra_medium, sans-serif;
    ${({ align }) => {
        switch (align) {
            case "left":
                return `
                        text-align: left;
                        padding-left: 15px;
                    `;
            case "center":
                return "justify-content: center";
            case "right":
                return "justify-content: flex-end";
        }
    }};
    > div:nth-of-type(1) {
        // Feature Title
        font-size: 18pt;
    }
    > div:nth-of-type(2) {
        // Feature Description
        font-size: 11pt;
        padding-top: 4px;
    }
`;

const HoverWrapper = styled.div`
    label: HoverWrapper;
    padding: 10px;
    ${({ active }) => {
        if (active) {
            return `
                color: #000000 !important;
                div {
                    color: #000000 !important;
                }
            `;
        }
        return `
                color: #999999 !important;
                div {
                    color: #999999 !important;
                }
            `;
    }}
`;

class FeatureListHover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        };
    }

    setActive = (index) => {
        this.setState({
            activeIndex: index
        });
    };

    componentDidMount() {
        const { features } = this.props;

        setInterval(() => {
            const { activeIndex } = this.state;
            this.setState({
                activeIndex: activeIndex === features.length - 1 ? 0 : activeIndex + 1
            });
        }, 2500);
    }

    render() {
        const { align, children, className, dataQa, features, layout } = this.props;
        const { activeIndex } = this.state;
        const activeFeature = features[activeIndex];
        if (!activeFeature) return null;
        return (
            <FeatureListHoverContent data-qa={dataQa} className={className} layout={layout} align={align}>
                <ImageWrapper>
                    {activeFeature.media && <Image image={activeFeature.media} img_type={"static"} />}
                </ImageWrapper>
                <FeatureListWrapper>
                    {features.map((featureItem, index) => (
                        <HoverWrapper
                            onMouseOver={() => {
                                this.setActive(index);
                            }}
                            active={index === activeIndex}
                            key={index}
                        >
                            <FeatureIcon {...featureItem.feature} />
                        </HoverWrapper>
                    ))}
                </FeatureListWrapper>
                {children}
            </FeatureListHoverContent>
        );
    }
}

export const FeatureListHoverPropTypes = {
    dataQa: PropTypes.string,
    features: PropTypes.arrayOf(
        PropTypes.shape({
            media: PropTypes.shape(ImagePropTypes).isRequired,
            feature: PropTypes.shape(FeatureIconPropTypes).isRequired
        })
    ).isRequired,
    layout: PropTypes.oneOf(["icon_left"]),
    align: PropTypes.oneOf(["left", "center", "right"])
};
FeatureListHover.propTypes = FeatureListHoverPropTypes;
FeatureListHover.defaultProps = {
    layout: "icon_left",
    align: "left"
};
export default FeatureListHover;

import React from "react";
import { services } from "@resourcehub/resourcehub-assets";
import AdminImage from "./AdminImage";
import FeatureButton from "./FeatureButton";
import styled from "styled-components";
import { useFeatureSelect } from "./useFeatureSelect";

const { iconSelector } = services;

const AppSectionLeft = styled.section`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 4em;
    margin: 160px 0 !important;
    position: relative;

    & > div,
    & > button {
        display: block;
    }

    @media (max-width: 1400px) {
        padding: 0;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        margin: 0 auto 70px;
        padding: 0;
    }
`;

const SectionImage = styled.div`
    position: relative;
    width: 50vw;
    order: 2;

    @media (max-width: 1024px) {
        padding: 0;
        width: 100%;
        order: 1;
    }

    @media (max-width: 425px) {
        margin: 0;
        padding: 0 5%;
    }
`;

const AppTopContent = styled.div`
    text-align: center;
    @media (max-width: 960px) {
        text-align: left;
    }
`;

const SectionTitle = styled.div`
    font-family: helvetica_bold, sans-serif;
    font-size: 36px;
    margin: ${({ isHomePage }) => (isHomePage ? "0 auto 15px" : "15px auto 40px")};
    max-width: 17em;
    padding: 1em 0;
    display: flex;
    justify-content: center;

    @media (max-width: 1024px) {
        margin: 20px 0;
        max-width: initial;
    }
`;

const SectionContentContainer = styled.div`
    z-index: 1;
    width: 50vw;
    min-width: 670px;
    display: flex;
    order: 1;
    padding: 0 4em;

    @media (max-width: 1440px) {
        min-width: 600px;
    }

    @media (max-width: 1024px) {
        padding: 0 10%;
        min-width: initial;
        width: initial;
        max-width: initial;
        order: 2;
        margin-top: 50px;
    }

    @media (max-width: 425px) {
        padding: 0 5%;
    }
`;

const AppContent = styled.div`
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    margin: auto;

    @media (max-width: 768px) {
        justify-content: space-evenly;
        width: 100%;
    }

    @media (max-width: 1024px) {
        justify-content: space-around;
        margin: 0;
    }
`;

const AdminFeatures = ({ pageData, data, img_bg, isHomePage = false }) => {
    const { selected, changeSelected } = useFeatureSelect();

    return (
        <AppSectionLeft isHomePage={isHomePage}>
            <SectionImage>
                <AppTopContent>
                    <SectionTitle isHomePage={isHomePage}>
                        <span>{pageData.admin_feature_title}</span>
                    </SectionTitle>
                </AppTopContent>
                <AdminImage data={data} selected={selected} img_bg={img_bg} isHomePage={isHomePage} />
            </SectionImage>
            <SectionContentContainer>
                <AppContent>
                    <FeatureButton
                        index="1"
                        title={pageData.admin_feature_1_title}
                        subtitle={pageData.admin_feature_1_subtitle}
                        icon={iconSelector(pageData.admin_feature_1_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="2"
                        title={pageData.admin_feature_2_title}
                        subtitle={pageData.admin_feature_2_subtitle}
                        icon={iconSelector(pageData.admin_feature_2_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="3"
                        title={pageData.admin_feature_3_title}
                        subtitle={pageData.admin_feature_3_subtitle}
                        icon={iconSelector(pageData.admin_feature_3_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="4"
                        title={pageData.admin_feature_4_title}
                        subtitle={pageData.admin_feature_4_subtitle}
                        icon={iconSelector(pageData.admin_feature_4_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="5"
                        title={pageData.admin_feature_5_title}
                        subtitle={pageData.admin_feature_5_subtitle}
                        icon={iconSelector(pageData.admin_feature_5_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                    <FeatureButton
                        index="6"
                        title={pageData.admin_feature_6_title}
                        subtitle={pageData.admin_feature_6_subtitle}
                        icon={iconSelector(pageData.admin_feature_6_icon_name)}
                        selected={selected}
                        changeSelected={(index) => changeSelected(index)}
                    />
                </AppContent>
            </SectionContentContainer>
        </AppSectionLeft>
    );
};

export default AdminFeatures;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const themes = ["black_yellow", "blue_white", "yellow_black", "white_black", "white_blue"];
const sizes = ["mini", "regular", "mobile"];

const switchTheme = (theme) => {
    switch (theme) {
        case "black_yellow":
            return `
            background-color: #262626 !important;
            border: 3px solid #262626 !important;
            border-radius: 4px !important;
            color: #F0C433 !important;
            :hover {
                background-color: #262626 !important;
                border: 3px solid #262626 !important;
                color: #F0C433 !important;
                text-decoration: none;
                cursor: pointer;
            }
        `;
        case "yellow_black":
            return `
                color: #262626 !important;
                background-color: #F0C433 !important;
                border: 3px solid #F0C433 !important;
                border-radius: 8px !important;
                :hover {
                    background-color: #D7B12C !important;
                    border: 3px solid #D7B12C !important;
                    color: #262626 !important;
                    text-decoration: none;
                    cursor: pointer;
                }
             `;
        case "white_black":
            return `
                background-color: transparent !important;
                border: 3px solid #262626 !important;
                border-radius: 8px !important;
                color: #262626 !important;
                :hover {
                    background-color: "#262626 !important;
                    border: 3px solid #262626 !important;
                    color: #FFFFFF !important;
                    text-decoration: none;
                    cursor: pointer;
                }
             `;
        case "white_blue":
            return `
                background: rgb(0,152,219);
                background: linear-gradient(90deg, rgba(0,152,219,1) 0%, rgba(0,152,219,1) 60px, rgba(255,255,255,1) 60px, rgba(255,255,255,1) 100%);
                border-radius: 8px !important;
                color: #0098DB !important;
                
                :hover {
                    text-decoration: none;
                    cursor: pointer;
                }
                div:nth-of-type(1) {
                    color: #FFFFFF;
                }
                div:nth-of-type(1) {
                }
                div:nth-of-type(3) {
                    span {
                        transform: rotate(45deg);
                        border-style: solid;
                        border-width: 3px 3px 0 0;
                        content: "";
                        display: inline-block;
                        height: 10px;
                        margin-right: 10px;
                        margin-left: 10px;
                        padding: none;
                        pointer-events: none; /* Allow clicking of select element beneath */;
                        position: relative;
                        right: 0;
                        top: 0;
                        vertical-align: top;
                        width: 10px;
                    }
                }
        `;
        case "blue_white":
            return `
                border: 3px solid #0098DB !important;
                border-radius: 8px !important;
                background-color: #0098DB !important;
                color: #ffffff !important;
                :hover {
                    background-color: #377EB3 !important;
                    border: 3px solid #377EB3 !important;
                    color: #ffffff !important;
                    text-decoration: none;
                    cursor: pointer;
                }  
        `;
    }
    return `
        border: 3px solid #0098DB !important;
        border-radius: 8px !important;
        background-color: #0098DB !important;
        color: #ffffff !important;
        :hover {
            background-color: #377EB3 !important;
            border: 3px solid #377EB3 !important;
            color: #ffffff !important;
            text-decoration: none;
            cursor: pointer;
        }    
    `;
};

const ListButtonStyled = styled.a`
    label: ListButton;

    //border-radius: 8px;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 20%);
    color: #333333;
    display: inline-flex;
    justify-content: flex-start;
    line-height: 19px;
    margin: 5px;
    text-align: left;
    text-decoration: none;
    transition: all 0.1s ease-in-out;

    // Marked as important because of style bleed from LESS.
    text-transform: capitalize !important;
    font-family: helvetica_bold, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 0.08px !important;
    //letter-spacing: 4px !important;
    ${({ theme }) => switchTheme(theme)};

    div:nth-of-type(1) {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 0;
        justify-content: center;
        max-width: 60px;
        min-width: 60px;
    }

    div:nth-of-type(2) {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        padding-left: 25px;
        padding-right: 25px;
        text-align: left;
    }

    div:nth-of-type(3) {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 0;
        justify-content: center;
        max-width: 60px;
        min-width: 60px;
    }

    :hover {
        transform: scale(1.02);
    }

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        div {
            min-height: 86px;
        }

        div:nth-of-type(1) {
            min-width: 60px;
        }

        div:nth-of-type(2) {
            min-width: 200px;
            padding: 5px 15px;
        }

        div:nth-of-type(3) {
            min-width: 50px;
        }
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        div:nth-of-type(1) {
            min-width: 60px;
        }

        div:nth-of-type(2) {
            min-width: 200px;
            padding: 5px 15px;
        }

        div:nth-of-type(3) {
            min-width: 50px;
        }
    }
`;

const ListButton = (
    { onClick, size, theme, text, destination, list_item_number, new_window, dataQa, disabled },
    extraProps
) => {
    if (onClick) {
        return (
            <ListButtonStyled
                {...extraProps}
                data-qa={dataQa}
                type="button"
                size={size}
                theme={theme}
                onClick={disabled ? () => "return false;" : onClick}
                disabled={disabled}
            >
                <div>{list_item_number}</div>
                <div>{text}</div>
                <div>
                    <span />
                </div>
            </ListButtonStyled>
        );
    }
    if (new_window) {
        return (
            <ListButtonStyled
                href={destination}
                target="_blank"
                rel="noopener"
                data-qa={dataQa}
                size={size}
                theme={theme}
                disabled={disabled}
                {...extraProps}
            >
                <div>{list_item_number}</div>
                <div>{text}</div>
                <div>
                    <span />
                </div>
            </ListButtonStyled>
        );
    }
    return (
        <ListButtonStyled
            data-qa={dataQa}
            size={size}
            href={destination}
            theme={theme}
            disabled={disabled}
            {...extraProps}
        >
            <div>{list_item_number}</div>
            <div>{text}</div>
            <div>
                <span />
            </div>
        </ListButtonStyled>
    );
};

export const ListButtonPropTypes = {
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(themes),
    size: PropTypes.oneOf(sizes),
    text: PropTypes.string,
    destination: PropTypes.string,
    new_window: PropTypes.bool,
    disabled: PropTypes.bool,
    list_item_number: PropTypes.number
};
ListButton.defaultProps = {
    theme: "blue",
    disabled: false,
    size: "regular",
    text: "",
    list_item_number: 1
};
ListButton.propTypes = ListButtonPropTypes;
export default ListButton;

import styled from "styled-components";

const ResourcesContainer = styled.div`
    label: ResourcesDiv;
    background-color: #ffd75322;
    padding-bottom: 4rem;
    border-radius: 20px;
    margin-bottom: 40px;
    > div:nth-of-type(3) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        > a {
            margin: 20px;
        }
    }
`;

export default ResourcesContainer;

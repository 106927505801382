import React from "react";
import styled from "styled-components";
import { profileCard, images } from "@resourcehub/resourcehub-assets";
import Image from "../Images/Image";

const SwiperSlide = styled.div`
    width: auto;
    max-width: 1102px;
    position: relative;
    flex-shrink: 0;
`;

const SwiperRoot = styled.div`
    display: flex;
    min-height: 362px;
    width: auto;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 0 3px 14px 0 rgb(22 22 1 / 34%);

    @media (max-width: 745px) {
        width: 80%;
        margin: 0 auto;
        padding: 18px 18px 0 18px;
        flex-direction: column;
        align-items: center;
    }
`;

const ProfileImageContainer = styled.div`
    position: relative;
    width: 100%;

    @media (max-width: 745px) {
        text-align: center;
        max-width: 309px;
        max-height: 405px;
        order: 0;
    }
`;

const ProfileImage = styled.img`
    max-width: 309px;
    max-height: 298px;
    height: auto;
    border-radius: 14.4px;
    margin: 32px;

    @media (max-width: 1030px) {
        margin: 14px 14px;
    }
    @media (max-width: 745px) {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        width: 100%;
    }
`;

const QuoteIcon = styled.img`
    width: 100%;
    max-width: 90px;
    position: absolute;
    top: 140px;
    right: -12px;
    @media (max-width: 1030px) {
        top: 120px;
        right: -26px;
    }
    @media (max-width: 745px) {
        position: relative;
        top: -45px;
        left: 0;
    }
`;

const SlideText = styled.div`
    font-family: effra, sans-serif;
    font-size: 24px;
    color: #262626;
    padding: 32px 46px 20px 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1030px) {
        padding: 14px 18px 20px 45px;
    }

    @media (max-width: 950px) {
        font-size: 16px;
        line-height: 28.52px;
    }

    @media (max-width: 745px) {
        width: 75%;
        line-height: 1.75;
        order: 2;
        padding: 25px 0 20px 0;
    }

    @media (max-width: 600px) {
        width: 100%;
    }

    p {
        line-height: 32px;
        margin: 0;
        padding: 0 0 25px 0;
        width: 100%;

        @media (max-width: 1130px) {
            padding-bottom: 10px;
        }

        @media (max-width: 405px) {
            padding: 0 5px;
            font-size: 13px;
        }
    }

    span {
        @media (max-width: 405px) {
            padding: 0 5px;
            font-size: 13px;
        }
    }
`;

const StarRating = styled.div`
    position: absolute;
    bottom: 40px;
    right: 55px;

    @media (max-width: 1155px) {
        bottom: 25px;
        right: 35px;
    }

    @media (max-width: 1100px) {
        bottom: 15px;
    }

    @media (max-width: 745px) {
        order: 1;
        position: initial;
    }

    span {
        display: inline-block;
        width: 32px;
        height: 30px;
        background-repeat: no-repeat;
        background-image: ${({ profileCardStar }) => `url(${profileCardStar})`};
    }
`;

const OccupationImageContainer = styled.div`
    min-width: 290px;
    min-height: 290px;

    > div {
        height: 100%;
    }

    @media (max-width: 740px) {
        height: initial;
        width: initial;
    }
`;

const OccupationSwiperRoot = styled(SwiperRoot)`
    min-height: 325px;
    display: flex;
    align-items: center;
`;

const OccupationSlideText = styled(SlideText)`
    padding: 20px 20px 20px 30px;

    p {
        padding-bottom: 10px;
    }
`;

const OccupationInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;

    img {
        width: 200px;
    }

    span {
        color: grey;
        font-weight: 700;
    }
`;

const OccupationQuoteIcon = styled.img`
    width: 75px;
    position: absolute;
    top: 125px;
    left: 230px;

    @media (max-width: 745px) {
        top: 240px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const ProfileCard = ({
    profileImgSrc,
    profileName,
    profileSummary,
    profileSignature,
    profileOccupation,
    occupationPanel
}) => {
    if (occupationPanel) {
        return (
            <SwiperSlide>
                <OccupationSwiperRoot>
                    <OccupationImageContainer>
                        <Image image={profileImgSrc} />
                    </OccupationImageContainer>
                    <OccupationQuoteIcon src={images.careersQuoteCircleSvg} alt="Quote Flourish Icon" />
                    <OccupationSlideText>
                        <p style={{ fontSize: "13pt" }}>{profileSummary}</p>
                        <OccupationInfo>
                            {profileSignature && (
                                <img src={profileSignature} alt={`${profileName} Profile Signature`} />
                            )}
                            {profileOccupation && <span>{profileOccupation}</span>}
                        </OccupationInfo>
                    </OccupationSlideText>
                </OccupationSwiperRoot>
            </SwiperSlide>
        );
    }

    return (
        <SwiperSlide>
            <SwiperRoot>
                <ProfileImageContainer>
                    <ProfileImage src={profileImgSrc} alt={`${profileName} Profile Image`} />
                    <QuoteIcon src={profileCard.profileCardQuoteCirlceSvg} alt="Quote Flourish Icon" />
                </ProfileImageContainer>
                <SlideText>
                    <p>{profileSummary}</p>
                    <span>-{profileName}</span>
                </SlideText>
                <StarRating profileCardStar={profileCard.profileCardStarSvg}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </StarRating>
            </SwiperRoot>
        </SwiperSlide>
    );
};

export default ProfileCard;

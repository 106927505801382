import * as actionTypes from "./free-trial-action-types";

export const setFreeTrialFormSubmission = (payload) => ({
    type: actionTypes.SET_FREE_TRIAL_FORM_SUBMISSION,
    payload
});

export const setFreeTrialIsLoading = (isLoading) => ({
    type: actionTypes.SET_FREE_TRIAL_IS_LOADING,
    payload: isLoading
});

export const setFreeTrialSubmissionResponse = (response) => ({
    type: actionTypes.SET_FREE_TRIAL_SUBMISSION_RESPONSE,
    payload: response
});

export const setFreeTrialSubmissionStatus = (status) => ({
    type: actionTypes.SET_FREE_TRIAL_SUBMISSION_STATUS,
    payload: status
});

export const setFreeTrialSubmissionError = (error) => ({
    type: actionTypes.SET_FREE_TRIAL_SUBMISSION_ERROR,
    payload: error
});

export const clearFreeTrialSubmissionError = () => ({
    type: actionTypes.SET_FREE_TRIAL_SUBMISSION_ERROR
});

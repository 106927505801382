import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import Image, { ImagePropTypes } from "./Image";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import BodyWrapper, { BodyWrapperPropTypes } from "../../component-styles/BodyWrapper";

const ImageTextWrapperDiv = styled.div`
    label: ImageTextWrapperDiv;

    display: flex;
    align-items: ${({ alignItems }) => alignItems};
    margin: 0;
    padding: 0;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        flex-direction: column;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        flex-direction: column;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: column;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        justify-content: center;
        flex-direction: column;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        // justify-content: ${({ justifyContent }) => justifyContent};
        ${({ justifyContent }) => {
            if (justifyContent === "split") {
                return `
                        > div {
                            width: 49%;
                        }
                    `;
            }
            return `
                        justify-content: ${justifyContent};
                    `;
        }}

        ${({ direction }) => (direction === "image-left" ? "flex-direction: row;" : "flex-direction: row-reverse;")}
    }
`;
const ImageFixedWrapper = styled.div`
    label: ImageFixedWrapper;

    padding: 0;
    margin: 0;
    text-align: center;
`;
const ImageFluidWrapper = styled.div`
    label: ImageFluidWrapper;

    padding: 0;
    margin: 0;
    text-align: center;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        min-width: 100%;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        min-width: 100%;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        min-width: 100%;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        min-width: 40%;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        min-width: 40%;
    }
`;
const ImageStaticWrapper = styled.div`
    label: ImageStaticWrapper;

    padding: 0;
    margin: 0;
    text-align: center;
`;
const TextWrapper = styled.div`
    label: TextWrapper;
    margin-left: auto;
    margin-right: auto;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        text-align: center;
        div {
            text-align: center;
        }
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        text-align: center;
        div {
            text-align: center;
        }
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        text-align: center;
        div {
            text-align: center;
        }
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        text-align: center;
        div {
            text-align: center;
        }
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        max-width: ${({ maxWidth }) => `${maxWidth}%`};
        text-align: left;
        padding: 20px;
        div {
            text-align: left;
        }
    }
`;

const ImageText = ({
    body,
    children,
    dataQa,
    direction,
    image,
    title,
    maxWidth,
    justifyContent,
    alignItems,
    className
}) => {
    return (
        <ImageTextWrapperDiv
            data-qa={`content-section-header-${dataQa}`}
            direction={direction}
            justifyContent={justifyContent}
            alignItems={alignItems}
            className={className}
        >
            <Image image={image} />
            <TextWrapper direction={direction} maxWidth={maxWidth}>
                {title && <TitleWrapper {...title} dataQa={`content-section-main-title-${dataQa}`} />}
                {body && <BodyWrapper {...body} dataQa={dataQa} />}
                {children && (
                    <ChildContentWrapper data-qa={`image-text-children-${dataQa}`}>{children}</ChildContentWrapper>
                )}
            </TextWrapper>
        </ImageTextWrapperDiv>
    );
};

export const ImageTextPropTypes = {
    dataQa: PropTypes.string,
    body: PropTypes.shape(BodyWrapperPropTypes),
    direction: PropTypes.oneOf(["image-left", "image-right"]),
    title: PropTypes.shape(TitleWrapperPropTypes),
    image: PropTypes.shape(ImagePropTypes).isRequired,
    renderTextWrapper: PropTypes.bool,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    maxWidth: PropTypes.number
};
ImageText.propTypes = ImageTextPropTypes;
ImageText.defaultProps = {
    body: null,
    direction: "image-left",
    justifyContent: "center",
    alignItems: "flex-start",
    renderTextWrapper: true,
    title: null,
    width: 100
};
export default ImageText;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image, { ImagePropTypes } from "../Images/Image";
import Link, { LinkPropTypes } from "../Links/Link";

const BrandLogoLink = styled(Link)`
    label: BrandLogoLink;
    display: inline-flex;
    line-height: 0;
    flex-grow: 0;
`;

const BrandLogo = ({ image, link, className }) => (
    <BrandLogoLink {...link} className={className}>
        <Image image={image} img_type={"static"} />
    </BrandLogoLink>
);

BrandLogo.propTypes = {
    image: PropTypes.shape(ImagePropTypes).isRequired,
    link: PropTypes.shape(LinkPropTypes).isRequired
};

BrandLogo.defaultProps = {};
export default BrandLogo;

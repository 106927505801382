import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image, { ImagePropTypes } from "../Images/Image";

const QuoteBlock = styled.div`
    label: QuoteBlock;
    background-image: ${({ backdrop }) => `url(${backdrop})`};
    box-shadow: 0 3px 10px 0 rgba(33, 33, 33, 0.5);
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: effra_bold, sans-serif;
    font-size: 18px;
    height: 200px;
    justify-content: space-between;
    line-height: 1.33;
    margin: 0 15px 20px;
    padding: 20px 24px;
    width: 350px;

    em {
        font-family: effra_medium, sans-serif;
        font-weight: 700;
    }

    img {
        bottom: 0;
        // override default gatsby image styling
        height: 35px !important;
    }
`;

const QuoteBranded = ({ dataQa, quote, backdrop, logo }) => (
    <QuoteBlock backdrop={backdrop} data-qa={dataQa}>
        <div dangerouslySetInnerHTML={{ __html: quote }} />
        <div>
            <Image image={logo} />
        </div>
    </QuoteBlock>
);

export const QuoteBrandedPropTypes = {
    logo: PropTypes.shape(ImagePropTypes).isRequired,
    quote: PropTypes.string.isRequired,

    backdrop: PropTypes.string,
    dataQa: PropTypes.string,
    name: PropTypes.string
};
QuoteBranded.propTypes = QuoteBrandedPropTypes;
QuoteBranded.defaultProps = {
    dataQa: "qa"
};
export default QuoteBranded;

import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";
import PropTypes from "prop-types";

const NavLinkWrapper = styled.div`
    label: NavLinkWrapper;

    align-content: center;
    align-items: center;
    background-color: ${(props) => (props.selected ? "#e5e5e5" : "#f9f9f9")};
    display: flex;
    font-family: effra, sans-serif;
    font-size: 14px;
    height: 38px;
    justify-content: center;
    letter-spacing: 0.4px;
    transition: all 0.25s;

    :hover {
        background-color: #cccccc;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        padding-left: 15px;
        padding-right: 15px;
        flex: auto;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        padding-left: 15px;
        padding-right: 15px;
        flex: auto;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        padding-left: 48px;
        padding-right: 48px;
        min-width: 100px;
        :lang(pt) {
            min-width: 115px;
        }
        flex: 0;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        padding-left: 48px;
        padding-right: 48px;
        min-width: 100px;
        flex: 0;
        :lang(pt) {
            min-width: 110px;
        }
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        padding-left: 48px;
        padding-right: 48px;
        min-width: 100px;
        flex: 0;
    }
`;

const NavLinkStyled = styled(Link)`
    label: NavLinkStyled;

    color: #262626;
    margin: 0;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    :hover {
        text-decoration: none;
    }
`;

const NavAnchorStyled = styled.a`
    label: NavAnchorStyled;

    color: #262626;
    margin: 0;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    :hover {
        text-decoration: none;
    }
`;

const NavTextStyled = styled.div`
    label: NavTextStyled;

    color: #262626;
    margin: 0;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    :hover {
        text-decoration: none;
    }
`;

const SecondaryNavLink = (props) => {
    const { dataQa, destination, children, new_window, selected, external, label } = props;
    let specialProps = {
        selected
    };
    if (new_window) {
        specialProps = {
            target: "_blank",
            rel: "noopener noreferrer"
        };
    }
    if (destination) {
        // Links outside of the immediate gatsby hub/site
        if (external) {
            return (
                <NavLinkWrapper {...props}>
                    <NavAnchorStyled
                        data-qa={dataQa}
                        href={destination}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={label}
                        {...specialProps}
                    >
                        {label} {children}
                    </NavAnchorStyled>
                </NavLinkWrapper>
            );
        }
        return (
            <NavLinkWrapper {...props}>
                <NavLinkStyled data-qa={dataQa} to={destination} {...specialProps}>
                    {label} {children}
                </NavLinkStyled>
            </NavLinkWrapper>
        );
    }
    // this is for the case where we want the look and feel of the nav link with no action. children
    return (
        <NavLinkWrapper>
            <NavTextStyled data-qa={dataQa} selected={selected} {...props}>
                {label} {children}
            </NavTextStyled>
        </NavLinkWrapper>
    );
};

SecondaryNavLink.propTypes = {
    dataQa: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    destination: PropTypes.string,
    external: PropTypes.bool,
    label: PropTypes.string,
    new_window: PropTypes.bool
};

SecondaryNavLink.defaultProps = {
    dataQa: null,
    destination: null,
    external: false,
    label: "",
    new_window: false
};

export { NavLinkStyled };
export default SecondaryNavLink;

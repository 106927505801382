import React from "react";
import styled from "styled-components";
import { NAV_TOP_HEIGHT } from "../../styles/style-constants";

const HamburgerMenuContainer = styled.div`
    label: HamburgerMenuContainer;

    background-color: #262626;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 58px;
    position: absolute;
    right: 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    top: 0;
    width: ${NAV_TOP_HEIGHT};
`;
const HamburgerMenuOpenIcon = styled.svg`
    label: HamburgerMenuContainer;

    display: ${(props) => (props.isOpen ? "none" : "block")};
    height: 100%;
    stroke-linecap: round;
    stroke-width: 0.7;
    stroke: #fff;
    transition: all 0.2s;
    width: 30px;
`;
const HamburgerMenuCloseIcon = styled.svg`
    label: HamburgerMenuCloseIcon;

    display: ${(props) => (props.isOpen ? "block" : "none")};
    height: 100%;
    stroke-linecap: round;
    stroke-width: 0.7;
    stroke: #fff;
    transition: all 0.2s;
    width: 30px;
`;

class MobileNavToggle extends React.PureComponent {
    state = {
        isOpen: false,
        dropdownName: "Mobile Nav"
    };

    componentDidUpdate(prevProps) {
        const { openDropdown } = this.props;
        const { dropdownName } = this.state;
        if (prevProps.openDropdown !== openDropdown) {
            if (openDropdown !== dropdownName) {
                this.setState({
                    isOpen: false
                });
            }
        }
    }

    togglePane = () => {
        const { isOpen } = this.state;
        if (isOpen) {
            this.closePane();
        } else {
            this.openPane();
        }
    };

    openPane = () => {
        const { setOpenDropdown } = this.props;
        const { dropdownName } = this.state;
        this.setState({
            isOpen: true
        });
        setOpenDropdown(dropdownName);
    };

    closePane = () => {
        const { setOpenDropdown } = this.props;
        this.setState({
            isOpen: false
        });
        setOpenDropdown(null);
    };

    render() {
        const { isOpen } = this.state;
        return (
            <HamburgerMenuContainer
                data-qa="mobile-nav-hamburger-menu-toggle-2020"
                onClick={this.togglePane}
                onKeyDown={this.togglePane}
            >
                <HamburgerMenuCloseIcon isOpen={isOpen} viewBox="0 0 10 7" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 2.3,1 L 7.7,6" />
                    <path d="M 2.3,6 L 7.7,1" />
                </HamburgerMenuCloseIcon>
                <HamburgerMenuOpenIcon isOpen={isOpen} viewBox="0 0 10 7" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 1,1 L 9,1" />
                    <path d="M 1,3.5 L 9,3.5" />
                    <path d="M 1,6 L 9,6" />
                </HamburgerMenuOpenIcon>
            </HamburgerMenuContainer>
        );
    }
}

export default MobileNavToggle;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Swiper from "swiper";

// Styles
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

// Components
import Image, { ImagePropTypes } from "../components/Images/Image";

// Carousel
const ImageCarouselSection = styled.section`
    width: 100%;
    > div:nth-of-type(1) {
        margin-left: 2.5em;
        margin-right: 4em;
        margin-bottom: 50px;
    }
`;
const ImageCarouselSlideDiv = styled.div`
    label: ImageCarouselSlideDiv;

    width: 100%;
    > div {
        margin-left: 2em;
        margin-right: 2em;
    }
`;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;

    .swiper-pagination-bullet {
        border: 2px solid #666666;
        height: 10px;
        width: 10px;
        margin-left: 13px !important;
        margin-right: 13px !important;
        background-color: inherit !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666 !important;
    }
`;
const CarouselNavigationNext = styled.div`
    label: CarouselNavigationNext;

    --swiper-navigation-color: #666666;
`;
const CarouselNavigationPrevious = styled.div`
    label: CarouselNavigationPrevious;

    --swiper-navigation-color: #666666;
`;
class ImageCarousel extends React.Component {
    componentDidMount() {
        new Swiper(".swiper-container", {
            cssMode: true,
            loop: true,
            centeredSlides: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            },
            mousewheel: true,
            keyboard: true
        });
    }
    render() {
        const { dataQa, panels, title } = this.props;
        if (!panels) return null;
        return (
            <ImageCarouselSection data-qa={`content-section-header-${dataQa}`}>
                {title && <TitleWrapper {...title} />}
                {panels && (
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            {panels.map((panel, i) => (
                                <ImageCarouselSlideDiv className="swiper-slide" key={`image-text-carousel-slide-${i}`}>
                                    <Image {...panel} />
                                </ImageCarouselSlideDiv>
                            ))}
                        </div>
                        {/* Add Arrows */}
                        <CarouselNavigationBullets className="swiper-pagination" />
                        <CarouselNavigationNext className="swiper-button-next" />
                        <CarouselNavigationPrevious className="swiper-button-prev" />
                    </div>
                )}
            </ImageCarouselSection>
        );
    }
}

// List
const ImageListSection = styled.section`
    label: ImageListSection;
    padding: ${({ padding }) => padding};

    display: flex;
    flex-direction: column;
    width: 100%;

    > div:nth-last-of-type(1) {
        align-self: center;
    }
`;
const ImageListContainer = styled.div`
    label: ImageListContainer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    > div {
        margin: 20px;
        max-width: 250px;
        max-height: 200px;
        img {
            max-width: 200px;
        }
    }
`;

const ImageList = ({ dataQa, panels, padding, subtitle, title, children, img_type = "gatsby_dynamic" }) => {
    if (!panels) return null;
    return (
        <ImageListSection padding={padding} data-qa={`content-section-header-${dataQa}`}>
            {title && <TitleWrapper {...title} dataQa={`content-section-main-title-${dataQa}`} />}
            {subtitle && <TitleWrapper {...subtitle} />}
            {panels && (
                <ImageListContainer>
                    {panels.map((panel, i) => (
                        <Image key={`image-${i}`} image={panel.image} img_type={img_type} />
                    ))}
                </ImageListContainer>
            )}
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </ImageListSection>
    );
};

// Root Component
const PageSectionImage = (props) => {
    switch (props.layout) {
        case "list":
            return <ImageList {...props} />;
        case "carousel":
            return <ImageCarousel {...props} />;
    }
};
export const PageSectionImagePropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["list", "carousel"]),
    panels: PropTypes.arrayOf(PropTypes.shape(ImagePropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes),
    subtitle: PropTypes.shape(TitleWrapperPropTypes),
    padding: PropTypes.string
};
PageSectionImage.defaultProps = {
    dataQa: "page-section-image",
    layout: "list",
    padding: "0"
};
PageSectionImage.propTypes = PageSectionImagePropTypes;
export default PageSectionImage;

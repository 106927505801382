// HOC to provide access to geo location data.
import React from "react";
import Cookies from "js-cookie";

const withGeoLocation = (ComponentToWrap) =>
    class extends React.Component {
        state = {
            oneTrustGeoLocation: null,
            explicitOptIn: false,
            currentGeoLocationCookie: null
        };

        componentDidMount() {
            let userIsFromGdprCountry;
            const geoLocationCookie = Cookies.get("onetrustGeolocationData");

            if (geoLocationCookie) {
                const parsedCookie = JSON.parse(geoLocationCookie);
                userIsFromGdprCountry = parsedCookie.country_code.toUpperCase() !== "US";
                this.setState((prevState) => ({
                    ...prevState,
                    explicitOptIn: userIsFromGdprCountry,
                    currentGeoLocationCookie: parsedCookie
                }));
                return;
            }

            window.addEventListener("oneTrustGeolocationInitialized", (event) => {
                const { continent, state, country } = event.detail;
                userIsFromGdprCountry = country.toUpperCase() !== "US";
                this.setState((prevState) => ({
                    ...prevState,
                    oneTrustGeoLocation: {
                        ...prevState.oneTrustGeoLocation,
                        state_code: state,
                        country_code: country,
                        continent_code: continent
                    },
                    explicitOptIn: userIsFromGdprCountry
                }));
            });
        }

        render() {
            const { currentGeoLocationCookie, oneTrustGeoLocation, explicitOptIn } = this.state;
            const geoLocation = !currentGeoLocationCookie ? oneTrustGeoLocation : currentGeoLocationCookie;
            return <ComponentToWrap {...this.props} geoLocation={geoLocation} explicitOptIn={explicitOptIn} />;
        }
    };

export default withGeoLocation;

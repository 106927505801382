import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const DropdownSectionDiv = styled.div`
    text-align: center;
    display: block;
    background: #fafafa;
    width: 100%;
`;

const DropdownLink = styled(Link)`
    border-top: 1px solid #dbdbdb;
    color: #4a4a4a;
    display: flex;
    font-family: effra_medium, sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;

    :hover {
        background: #fafafa;
        text-decoration: underline;
    }

    :last-of-type {
        width: 180px;
        border-radius: 0 0 8px 8px;
    }
`;

const DropDownLink = ({ link, resourcesLink }) => {
    // for section headings
    if (typeof link.url_slug === "undefined") {
        return <DropdownSectionDiv key={link.title}>{link.title} </DropdownSectionDiv>;
    }
    return (
        <DropdownLink key={link.title} to={`${resourcesLink}${link.url_slug}`}>
            {link.title}
        </DropdownLink>
    );
};

export const DropDownLinkPropTypes = {
    resourcesLink: PropTypes.string.isRequired,
    link: PropTypes.shape({
        title: PropTypes.string.isRequired
    }).isRequired
};

DropDownLink.propTypes = DropDownLinkPropTypes;

export default DropDownLink;

import React from "react";
import SubLevels from "./SubLevels";
import styled from "styled-components";
import PropTypes from "prop-types";

const SectionOpen = styled.div`
    position: relative;
    overflow: hidden;
    background: ${({ isOpenSubSection }) => (isOpenSubSection ? "#ececec" : "#fafafa")};
    max-height: ${({ isOpenSubSection }) => (isOpenSubSection ? "100vh" : "initial")};
    height: 41px;
    transition: all 0.3s;
    width: 180px;
`;

const SectionLabel = styled.button`
    display: block;
    position: relative;
    background: #fafafa;
    padding: 1em;
    font-size: 14px;
    width: 180px;
    text-align: left;
    color: #4a4a4a;
`;

const Arrow = styled.div`
    &::after {
        content: "";
        cursor: pointer;
        opacity: 0.8;
        position: absolute;
        top: ${({ isOpenSubSection }) => (isOpenSubSection ? "19px" : "14px")};
        left: 155px;
        width: 9px;
        height: 9px;
        border: 2px solid #4a4a4a;
        border-right: none;
        border-top: none;
        transform: ${({ isOpenSubSection }) => (isOpenSubSection ? "rotate(-225deg)" : "rotate(-45deg)")};
        text-align: left;
        transition: all 0.3s;
    }
`;

const SubSection = styled.div`
    max-height: ${({ isOpenSubSection }) => (isOpenSubSection ? "100vh" : 0)};
    padding: 0;
    opacity: ${({ isOpenSubSection }) => (isOpenSubSection ? 1 : 0)};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
    transition: ${({ isOpenSubSection }) => (isOpenSubSection ? "all 0.3s" : "height 0.3s")};
    background: #ececec;
    width: 180px;
`;

const Section = ({ section, openSubSection, handleSubClick, resourcesLink }) => {
    const { title, sublevels } = section;
    const isOpenSubSection = openSubSection === title;

    return (
        <React.Fragment key={title}>
            <SectionOpen
                onClick={(e) => handleSubClick(e, title)}
                onKeyDown={(e) => handleSubClick(e, title)}
                key={title}
                isOpenSubSection={isOpenSubSection}
            >
                <SectionLabel type="button">{title}</SectionLabel>
                <Arrow isOpenSubSection={isOpenSubSection} />
            </SectionOpen>
            <SubSection isOpenSubSection={isOpenSubSection} role="button">
                {sublevels && (
                    <SubLevels
                        resourcesLink={resourcesLink}
                        sublevels={sublevels}
                        isOpenSubSection={isOpenSubSection}
                    />
                )}
            </SubSection>
        </React.Fragment>
    );
};

export const SectionPropTypes = {
    handleSubClick: PropTypes.func.isRequired,
    section: PropTypes.shape({
        title: PropTypes.string.isRequired,
        sublevels: PropTypes.arrayOf(
            PropTypes.shape({
                url_slug: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired
            }).isRequired
        ).isRequired
    }).isRequired,
    resourcesLink: PropTypes.string.isRequired,
    openSubSection: PropTypes.string.isRequired
};

Section.propTypes = SectionPropTypes;

export default Section;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ButtonStyled, { ButtonStyledPropTypes } from "../Buttons/ButtonStyled";

const OverlayContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 100vw;
    position: fixed;
    z-index: 1000;
`;

const SpacerDiv = styled.div`
    flex: 1 0 auto;
`;

const FreeTrialBannerDiv = styled.div`
    label: BodyWrapper;
    @keyframes slideInFromBottom {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }

    flex-shrink: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;

    background-color: #f0c433;
    height: 100px;
    width: 100vw;
    animation: 0.1s ease-out 0s 1 slideInFromBottom;
`;

const FreeTrialBanner = ({ button, children, dataQa, visible }) => {
    return visible ? (
        <OverlayContainerDiv>
            <SpacerDiv />
            <FreeTrialBannerDiv data-qa={`FreeTrialBanner-${dataQa}`}>
                <ButtonStyled {...button} />
                {children}
            </FreeTrialBannerDiv>
        </OverlayContainerDiv>
    ) : null;
};
export const FreeTrialBannerPropTypes = {
    dataQa: PropTypes.string,
    visible: PropTypes.bool,
    button: PropTypes.shape(ButtonStyledPropTypes)
};
FreeTrialBanner.propTypes = FreeTrialBannerPropTypes;
FreeTrialBanner.defaultProps = {
    visible: true
};
export default FreeTrialBanner;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TitleWrapper from "../../component-styles/TitleWrapper";
import Image from "../Images/Image";

const ProductUnitCardStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ color }) => color};
    border-radius: 5px;
    max-width: 100%;
    width: 100%;
    min-width: 25%;
    height: 100%;
    min-height: 300px;

    > div:nth-of-type(1) {
        margin: 0;
        padding: 20px 0;
    }

    > div:nth-of-type(2) {
        text-align: center;
        margin: 0;
        padding: 0 0 20px;
    }
`;

const ProductUnitCard = ({ unit, description, icon, color }) => (
    <ProductUnitCardStyled color={color}>
        <div>
            <TitleWrapper {...unit} />
            <TitleWrapper {...description} />
        </div>
        <div>
            <Image image={icon} />
        </div>
    </ProductUnitCardStyled>
);

export const ProductUnitCardPropTypes = {
    unit: PropTypes.shape(TitleWrapper).isRequired,
    description: PropTypes.shape(TitleWrapper).isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired
};
ProductUnitCard.propTypes = ProductUnitCardPropTypes;
ProductUnitCard.defaultProps = {};
export default ProductUnitCard;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Logo from "./Logo";

const TopBorderFooterSection = styled.section`
    background: #262626;
`;
const FooterSection = styled.section`
    label: FooterSection;

    box-sizing: unset;
    background: #262626;
    color: #eeeeee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    width: 100%;
`;
const ContentDiv = styled.div`
    label: ContentDiv;

    display: flex;
    max-width: 95%;
    width: 100%;
    margin: 0 auto;
    padding: 1.5em 0;
    flex-direction: column !important;
    justify-content: center;
    align-content: center;
    text-align: center;

    @media only screen and (min-width: 1025px) {
        flex-direction: row !important;
        justify-content: space-between;
        align-content: center;
        text-align: left;
    }
`;

const CopyrightSectionDiv = styled.div`
    label: CopyrightSectionDiv;

    align-items: flex-start;
    display: inline-flex;
    justify-content: center;
    font-family: effra, sans-serif;
    font-size: 13px;
    font-weight: 100;
    line-height: 15.824px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;

    @media only screen and (max-width: 1025px) {
        padding-left: 0;
        justify-content: flex-start;
        align-items: center;
    }
`;
const CopyrightTextDiv = styled.div`
    label: CopyrightTextDiv;
    display: flex;
    flex-direction: column;
`;
const RSCopyrightTextSpan = styled.div`
    label: RSCopyrightTextSpan;
    flex-grow: 1;
    text-align: left;
`;
const IXLCopyrightTextSpan = styled.div`
    label: IXLCopyrightTextSpan;
    text-align: left;
`;
const IXLLogoDivider = styled.div`
    label: IXLLogoDivider;
    content: "";
    margin: 0 40px;
    width: 1px;
    top: 14px;
    left: 1px;
    bottom: 0;
    height: 45px;
    background: #eee;

    @media only screen and (max-width: 1025px) {
        margin: 2px 25px 0;
        height: 40px;
    }
`;

const NarwhalMeta = ({ config }) => {
    return (
        <TopBorderFooterSection>
            <FooterSection data-qa={"footer-legal-section"}>
                <ContentDiv>
                    {config.copyright.visible && (
                        <CopyrightSectionDiv>
                            {config.rs_logo.visible && <Logo dataQa="footer-legal-rs-logo" {...config.rs_logo} />}
                            <CopyrightTextDiv>
                                <RSCopyrightTextSpan data-qa={"footer-legal-copyright-date"}>
                                    ©1999-{new Date().getFullYear()}
                                </RSCopyrightTextSpan>
                                <RSCopyrightTextSpan data-qa={"footer-legal-copyright-text"}>
                                    {config.copyright.label}
                                    {config.copyright.icpLabel && (
                                        <a data-qa={"footer-icp-rs-link"} href={config.copyright.icpLink}>
                                            {config.copyright.icpLabel}
                                        </a>
                                    )}
                                </RSCopyrightTextSpan>
                                <IXLCopyrightTextSpan>{config.copyright.IXLLabel}</IXLCopyrightTextSpan>
                            </CopyrightTextDiv>
                            <IXLLogoDivider />
                            {config.ixl_logo.visible && <Logo {...config.ixl_logo} />}
                        </CopyrightSectionDiv>
                    )}
                </ContentDiv>
            </FooterSection>
        </TopBorderFooterSection>
    );
};

NarwhalMeta.propTypes = {
    rosetta_copyright: PropTypes.string,
    anti_piracy: PropTypes.string,
    anti_piracy_url: PropTypes.string,
    agreements: PropTypes.string,
    agreements_url: PropTypes.string,
    terms_conditions: PropTypes.string,
    terms_conditions_url: PropTypes.string,
    privacy_policy: PropTypes.string,
    privacy_policy_url: PropTypes.string
};

export default NarwhalMeta;

import React from "react";
import styled from "styled-components";
import { backgrounds, images } from "@resourcehub/resourcehub-assets";
import IDMeVerifyButton from "../../components/Vendor/IDMeVerifyButton";

const MainSection = styled.section`
    label: MainSection;

    /* General styles */
    img {
        border: none; // This prevents any weird borders when images are in link tags
    }

    a {
        color: #0098db;
    }

    /* Container styling */
    display: block;
    border: none;
    position: relative;
    overflow: hidden;
    background: #0081d1 url(${backgrounds.headerBlueJpg}) no-repeat;
    background-size: cover;

    .content {
        position: relative;

        .masthead-content {
            max-width: 1500px;
            width: 90%;
            padding: 3% 0;
            margin: 0 auto;
            text-align: center;

            @media only screen and (max-width: 768px) {
                display: flex;
                justify-content: center;
            }

            > div {
                display: inline-block;
                vertical-align: middle;
                width: 49%;

                @media only screen and (max-width: 768px) {
                    display: block;
                }
            }

            .masthead-headline {
                box-sizing: border-box;
                max-width: 38em;

                .discount-form {
                    width: 97%;
                    background-color: #fff;
                    padding: 15px;
                    text-align: left;
                    @media only screen and (max-width: 768px) {
                        width: unset;
                    }

                    h1,
                    h2 {
                        font-size: 30px;
                        margin-bottom: 0;
                    }

                    h1,
                    h2,
                    p,
                    div {
                        padding-right: 10px;
                    }

                    > div:first-of-type {
                        margin-top: 1em;
                        margin-bottom: 1em;
                    }
                }

                @media only screen and (max-width: 768px) {
                    max-width: unset;
                    width: 97% !important;
                }
            }

            .masthead-prodimg {
                padding-bottom: 20%;
                background-image: url(${images.usConsumerDiscountsDevicesPng});
                background-repeat: no-repeat;
                position: relative;
                @media screen and (max-width: 1550px) {
                    background-size: contain;
                }
                @media only screen and (max-width: 768px) {
                    display: none !important;
                }
            }
        }
    }
`;

const PageTemplateConsumerDiscounts = (config) => {
    const strings = config.page.content.strings;
    const urls = config.page.content.urls;

    return (
        <MainSection className="pageblock">
            <div className="content">
                <div className="masthead-content">
                    <div className="masthead-headline">
                        <div className="discount-form">
                            <h1>{strings.masthead.h1}</h1>
                            <div dangerouslySetInnerHTML={{ __html: strings.masthead.p }} />
                            <h2>{strings.centerSection.h2}</h2>
                            <IDMeVerifyButton url={urls.idmeHosted} />
                            <p>{strings.centerSection.p}</p>
                        </div>
                    </div>
                    <div className="masthead-prodimg" />
                </div>
            </div>
        </MainSection>
    );
};

export default PageTemplateConsumerDiscounts;

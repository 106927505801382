import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Swiper from "swiper";
import ProfileCard from "./ProfileCard";
import { slides } from "./slides";

// Carousel
const SwiperContainer = styled.div`
    label: SwiperContainer;
    width: 100%;
    overflow: hidden;
`;
const SwiperWrapper = styled.div`
    label: SwiperWrapper;
    padding: 50px 0 0;
    height: auto !important;

    .swiper-slide {
        max-width: 1102px;
        width: 100% !important;
    }
`;
const CarouselContainer = styled.div``;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;
    position: static;
    margin-bottom: 40px;

    .swiper-pagination-bullet {
        opacity: 1;
        margin: 0 12px;
        width: 16px;
        height: 16px;
        background-color: #999;
        transition: background-color 0.2s, width 0.2s, border-radius 0.2s;
    }

    .swiper-pagination-bullet-active {
        width: 96px;
        border-radius: 16px;
        background-color: #0098db;
    }
`;

const ProfileCardWrapper = styled.div`
    margin-bottom: 50px;

    @media (max-width: 765px) {
        margin-bottom: 25px;
    }
`;

const CarouselWrapper = ({ dataQa, children }) => {
    return (
        <>
            <CarouselContainer dataQa={dataQa}>
                <SwiperContainer className="swiper-profile-card-container">
                    <SwiperWrapper className="swiper-wrapper">{children}</SwiperWrapper>
                </SwiperContainer>
            </CarouselContainer>
            <CarouselNavigationBullets className="swiper-pagination" />
        </>
    );
};

const ProfileCardSlider = ({ dataQa, lang, customPanels }) => {
    useEffect(() => {
        const carouselOptions = {
            spaceBetween: 50,
            grabCursor: true,
            initialSlide: 1,
            slidesPerView: "auto",
            autoHeight: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            centeredSlides: true
        };

        new Swiper(".swiper-profile-card-container", carouselOptions);
    }, []);

    if (customPanels) {
        return (
            <CarouselWrapper>
                {customPanels.map((panel, index) => (
                    <ProfileCardWrapper
                        className="swiper-slide"
                        data-qa={`profile-card-slider-card-${dataQa}-${index}`}
                        key={`profile-card-slider-card-${dataQa}-${index}`}
                    >
                        <ProfileCard {...panel} />
                    </ProfileCardWrapper>
                ))}
            </CarouselWrapper>
        );
    }

    return (
        <>
            <CarouselWrapper>
                {slides[lang] &&
                    slides[lang].map((slide, index) => (
                        <ProfileCardWrapper
                            className="swiper-slide"
                            data-qa={`profile-card-slider-card-${dataQa}-${index}`}
                            key={`profile-card-slider-card-${dataQa}-${index}`}
                        >
                            <ProfileCard {...slide} />
                        </ProfileCardWrapper>
                    ))}
            </CarouselWrapper>
        </>
    );
};

const ProfileCardSliderPropTypes = {
    dataQa: PropTypes.string,
    lang: PropTypes.string,
    customPanels: PropTypes.shape({
        id: PropTypes.number.isRequired,
        profileImgSrc: PropTypes.string.isRequired,
        profileSummary: PropTypes.string.isRequired,
        profileName: PropTypes.string.isRequired,
        profileSignature: PropTypes.any,
        profileOccupation: PropTypes.string
    })
};

ProfileCardSlider.defaultProps = {
    dataQa: "profile-card-slider",
    customPanels: null
};

ProfileCardSliderPropTypes.propTypes = ProfileCardSliderPropTypes;
export default ProfileCardSlider;

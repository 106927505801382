import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import VideoPlayer, { VideoPlayerPropTypes } from "./VideoPlayer";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import BodyWrapper, { BodyWrapperPropTypes } from "../../component-styles/BodyWrapper";

const VideoTextWrapperDiv = styled.div`
    label: VideoTextWrapperDiv;

    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        flex-direction: column;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        flex-direction: column;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: column;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        justify-content: center;
        flex-direction: column;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        justify-content: ${({ justifyContent }) => justifyContent};
        ${({ direction }) => (direction === "image-left" ? "flex-direction: row;" : "flex-direction: row-reverse;")}
    }
`;
const VideoPlayerWrapper = styled.div`
    label: VideoPlayerWrapper;
    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        margin-bottom: 20px;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        margin-bottom: 20px;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        margin-bottom: 20px;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        margin-bottom: 20px;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        //min-height: 518px;
        min-width: 600px;
        margin-bottom: 0;
    }
`;
const TextWrapper = styled.div`
    label: TextWrapper;

    max-width: ${({ maxWidth }) => `${maxWidth}%`};
    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        text-align: center;
        div {
            text-align: center;
        }
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        text-align: center;
        div {
            text-align: center;
        }
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        text-align: center;
        div {
            text-align: center;
        }
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        text-align: center;
        div {
            text-align: center;
        }
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        text-align: left;
        padding: 20px;
        div {
            text-align: left;
        }
    }
`;

const VideoText = ({ body, children, dataQa, direction, className, video, title, maxWidth, justifyContent }) => (
    <VideoTextWrapperDiv
        data-qa={`content-section-header-${dataQa}`}
        direction={direction}
        justifyContent={justifyContent}
        className={className}
    >
        <VideoPlayerWrapper>
            <VideoPlayer {...video} data-qa={`video-text-video-${dataQa}`} boxShadow={null} />
        </VideoPlayerWrapper>
        <TextWrapper direction={direction} maxWidth={maxWidth}>
            {title && <TitleWrapper {...title} dataQa={`content-section-main-title-${dataQa}`} />}
            {body && <BodyWrapper {...body} dataQa={dataQa} />}
            {children && (
                <ChildContentWrapper data-qa={`image-text-children-${dataQa}`}>{children}</ChildContentWrapper>
            )}
        </TextWrapper>
    </VideoTextWrapperDiv>
);

export const VideoTextPropTypes = {
    dataQa: PropTypes.string,
    body: PropTypes.shape(BodyWrapperPropTypes),
    direction: PropTypes.oneOf(["image-left", "image-right"]),
    title: PropTypes.shape(TitleWrapperPropTypes),
    video: PropTypes.shape(VideoPlayerPropTypes).isRequired,
    renderTextWrapper: PropTypes.bool,
    justifyContent: PropTypes.string,
    maxWidth: PropTypes.number
};
VideoText.propTypes = VideoTextPropTypes;
VideoText.defaultProps = {
    renderTextWrapper: true,
    direction: "image-left",
    title: {
        type: "section-title"
    },
    justifyContent: "center"
};
export default VideoText;

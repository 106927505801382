import React from "react";
import styled from "styled-components";

const PageContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
`;

const Title = styled.h1`
    font-size: 52px;
    font-weight: 100;
    font-family: helvetica, sans-serif;
    padding-bottom: 0.2em;
    border-bottom: 1px solid #bbb;
    margin: 1em 0;

    @media (max-width: 640px) {
        font-size: 40px;
    }
`;

const ContentWrapper = styled.div`
    font-size: 22px;
    padding-bottom: 1em;

    @media (max-width: 640px) {
        font-size: 20px;
    }

    a {
        color: #0098db;
        text-decoration: underline;

        &:visited {
            color: purple;
        }
    }
`;

const PageTemplateConsumerLegalAccessibility = (config) => (
    <PageContainer>
        <Title>{config.page.content.sections[0].hero_title}</Title>
        <ContentWrapper>
            <p>{config.page.content.sections[0].paragraph_1}</p>
            <p>{config.page.content.sections[0].paragraph_2}</p>
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].paragraph_3 }} />
        </ContentWrapper>
    </PageContainer>
);

export default PageTemplateConsumerLegalAccessibility;

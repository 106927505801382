import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TitleWrapperStyle = styled.div`
    label: TitleWrapperStyle;
    text-align: center;
    ${({ align }) => {
        if (align === "left") {
            return `
                    text-align: left;
                  `;
        }
        if (align === "center") {
            return `
                      text-align: center;
                `;
        }
        if (align === "right") {
            return `
                      text-align: right;
                `;
        }
    }};
`;
const Title = styled.h1`
    label: Title;

    font-family: helvetica_bold, sans-serif;
    margin: 0;

    ${({ theme }) => {
        if (theme === "blue") {
            return `
                color: #0098DB;
            `;
        }
        if (theme === "black") {
            return `
                color: #262626;
            `;
        }
        if (theme === "yellow") {
            return `
                color: #f0c433;
            `;
        }
        if (theme === "white") {
            return `
                color: #ffffff;
            `;
        }
        if (theme === "green") {
            return `
                color: #098c33;
            `;
        }
    }};
    ${({ type }) => {
        switch (type) {
            case "banner-title":
                return `
                    font-size: 24px;
                    letter-spacing: 0.2px;
                    line-height: auto;
                  `;
            case "banner-sub-title":
                return `
                    font-family: effra,sans-serif;
                    font-size: 16px;
                    letter-spacing: .12px;
                    line-height: 19px;
                    font-weight: 400;
                `;
            case "page-title":
                return `
                    //Mobile-landscape (and larger)
                    @media (min-width: 320px) {
                        font-size: 40px;
                        letter-spacing: 0.2px;
                        line-height: auto;
                    }
                    //Mobile-landscape (and larger)
                    @media (min-width: 480px) {
                        font-size: 40px;
                        letter-spacing: 0.2px;
                        line-height: auto;
                    }
                    // Tablet-portrait (and larger)
                    @media (min-width: 768px) {
                        font-size: 32pt;
                        letter-spacing: 0.25px;
                        line-height: auto;
                    }
                    // Tablet-landscape (and larger)
                    @media (min-width: 992px) {
                        font-size: 36pt;
                        letter-spacing: 0.25px;
                        line-height: auto;
                    }
                    // Laptops (and longer)
                    @media (min-width: 1200px) {
                        font-size: 42pt;
                        letter-spacing: 0.25px;
                        line-height: 64px;
                    }
                  `;
            case "page-sub-title":
                return `
                    font-size: 18pt;
                    letter-spacing: 0.25px;
                    line-height: 48px;
                  `;
            case "section-title":
                return `
                    //Mobile-landscape (and larger)
                    @media (min-width: 320px) {
                        font-size: 24px;
                        letter-spacing: 0.12px;
                        line-height: 29px;
                    }
                    //Mobile-landscape (and larger)
                    @media (min-width: 480px) {
                        font-size: 24px;
                        letter-spacing: 0.12px;
                        line-height: 29px;
                    }
                    // Tablet-portrait (and larger)
                    @media (min-width: 768px) {
                        font-size: 26px;
                        letter-spacing: 0.25px;
                        line-height: 38px;
                        max-width: 87%;
                        margin: 0 auto;
                    }
                    // Tablet-landscape (and larger)
                    @media (min-width: 992px) {
                        font-size: 26px;
                        letter-spacing: 0.25px;
                        line-height: 37px;
                    }
                    // Laptops (and longer)
                    @media (min-width: 1200px) {
                        font-size: 33px;
                        letter-spacing: 0.25px;
                        line-height: 48px;
                        max-width: 80%;
                        text-align: center;
                        margin: 0 auto;
                    }
                `;
            case "sub-title":
                return `
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 32px;
                `;
            case "card-title":
                return `
                    font-size: 16pt;
                    letter-spacing: 0;
                    line-height: 32px;
                `;
            case "stone-title":
                return `
                    font-size: 24px;
                    letter-spacing: 0;
                    line-height: 28px;
                    max-height: 180px;
                    max-width: 200px;
                    padding: 15px;
                `;
            case "section-sub-title":
                return `
                    font-weight: 400;
              `;
            case "tooltip-title":
                return `
                    font-size: 21px;
                    font-weight: 400;
                `;
            case "unit-card-title":
                return `
                color: #ffffff;
                text-transform: uppercase;
                font-size: 10pt;
                padding-bottom: 3px;
                `;
            case "unit-card-sub-title":
                return `
                    color: #ffffff;
                    font-size: 14pt;
                `;
        }
    }};
`;
const TitleWrapper = ({ align, children, className, content, dataQa, heading, theme, type }) => (
    <TitleWrapperStyle align={align} theme={theme} type={type} className={className}>
        {content && !children && (
            <Title
                data-qa={dataQa}
                as={heading.toLowerCase()}
                align={align}
                theme={theme}
                type={type}
                dangerouslySetInnerHTML={{
                    __html: content.replace(
                        "Rosetta Stone",
                        "<span style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all', backgroundColor: 'red' }}>Rosetta&nbsp;Stone</span>"
                    )
                }}
            />
        )}
        {!content && children && (
            <Title data-qa={dataQa} as={heading.toLowerCase()} align={align} theme={theme} type={type}>
                {children}
            </Title>
        )}
    </TitleWrapperStyle>
);

export const TitleWrapperPropTypes = {
    content: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"]),
    theme: PropTypes.oneOf(["blue", "yellow", "black", "white"]),
    heading: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "div", "p"]),
    type: PropTypes.oneOf([
        "banner-title",
        "banner-sub-title",
        "page-title",
        "page-sub-title",
        "section-title",
        "stone-title",
        "sub-title",
        "card-title",
        "tooltip-title"
    ])
};
TitleWrapper.defaultProps = {
    align: "center",
    heading: "div",
    theme: "black",
    type: "section-title"
};
TitleWrapper.propsTypes = TitleWrapperPropTypes;
export default TitleWrapper;

import React, { useState } from "react";
import GutterWrapper from "../../component-styles/GutterWrapper";
import styled from "styled-components";
import { sectionStones } from "@resourcehub/resourcehub-assets";

const TemplateWrapper = styled.div`
    overflow-y: hidden;
    position: relative;
`;

const HeroHeader = styled.h1`
    font-family: effra_bold, sans-serif;
    color: #f0c433;
    font-size: 88px;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 66px;
        padding: 19px 0 0;
        letter-spacing: 0;
    }

    @media (max-width: 768px) {
        font-size: 48px;
        padding: 19px 0 0;
        line-height: 48px;
    }
`;

const BodyWrapper = styled.div`
    font-size: 16px;
    margin: auto;
    width: 90%;
    max-width: 760px;
    font-family: effra, sans-serif;
    line-height: 32px;

    a {
        text-decoration: underline;
        color: #0098db;

        &:visited {
            color: purple;
        }
    }
`;

const HeroBody = styled.div`
    font-family: effra_bold, sans-serif;
    font-weight: 500;
    font-size: 24px;
`;

const ParagraphTitle = styled.div`
    font-weight: 500;
    font-family: effra_bold, sans-serif;
`;

const ParagraphBody = styled.div`
    margin-bottom: 30px;
`;

const FaqContentContainer = styled.div`
    margin: 0 auto;
    z-index: 10000;
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
        display: initial;
    }
`;

const FaqContentWrapper = styled.div`
    width: 90%;
    max-width: 760px;
    padding: 8px 0 45px;
    flex-grow: 0;
`;

const Accordion = styled.div`
    border-bottom: 3px dotted #c5c5c5;
`;

const AccordionTitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const AccordionTitle = styled.div`
    display: flex;
    align-items: center;
    padding: 26px 0 -1px;
    cursor: pointer;
    font-size: 32px;
    font-weight: 700;
    font-family: effra_bold, sans-serif;
    margin: 0 0.5em;

    @media (max-width: 1024px) {
        font-size: 18px;
        padding: 16px 0;
        background-size: 18px;
    }

    @media (max-width: 400px) {
        font-size: 16px;
    }
`;
const AccordionContent = styled.div`
    display: ${({ isVisible }) => (isVisible ? "block" : "none")};

    h4 {
        font-weight: 500;
        margin: 0;
        padding: 0;
        font-family: effra_bold, sans-serif;
    }
`;

const DropdownArrow = styled.div`
    font-size: 32px;
    transform: ${({ isVisible }) => (isVisible ? "rotate(180deg)" : "rotate(90deg)")};
    color: #0098db;
`;

const YellowStoneLeft = styled.div`
    display: block;
    height: 395px;
    width: 100%;
    background: url(${sectionStones.yellowEdgeStoneSvg}) no-repeat;
    background-size: contain;
`;

const BlueStoneRight = styled.div`
    display: block;
    height: 790px;
    width: 100%;
    background: url(${sectionStones.blueEdgeStoneSvg}) no-repeat 100%;
    background-size: contain;
`;

const GrayStoneLeft = styled.div`
    display: block;
    height: 769px;
    width: 100%;
    background: url(${sectionStones.grayEdgeStoneSvg}) no-repeat;
    background-size: contain;
`;

const StoneBgContainer = styled.div`
    z-index: -5;
    overflow: hidden;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: fit-content;
`;

const FaqItem = ({ faqItem }) => {
    const [isContentVisible, setIsContentVisible] = useState(false);
    return (
        <Accordion onClick={() => setIsContentVisible(!isContentVisible)}>
            <AccordionTitleWrapper>
                <div>
                    <img src={faqItem.image} />
                </div>
                <AccordionTitle dangerouslySetInnerHTML={{ __html: faqItem.title }} />
                <DropdownArrow isVisible={isContentVisible}>^</DropdownArrow>
            </AccordionTitleWrapper>
            <AccordionContent isVisible={isContentVisible}>
                {faqItem.content.map((content) => (
                    <div key={content} dangerouslySetInnerHTML={{ __html: content }} />
                ))}
            </AccordionContent>
        </Accordion>
    );
};

const PageTemplateConsumerLegalDPAS = ({ page }) => {
    const [section_1, section_2] = page.content.sections;

    return (
        <TemplateWrapper>
            <GutterWrapper>
                <StoneBgContainer>
                    <YellowStoneLeft />
                    <BlueStoneRight />
                    <GrayStoneLeft />
                    <BlueStoneRight />
                    <GrayStoneLeft />
                    <BlueStoneRight />
                    <GrayStoneLeft />
                    <BlueStoneRight />
                </StoneBgContainer>
                <HeroHeader>{section_1.hero_heading}</HeroHeader>
                <BodyWrapper>
                    <HeroBody dangerouslySetInnerHTML={{ __html: section_1.hero_heading_body }} />
                    <div>
                        <ParagraphTitle>{section_1.our_commitment_title}</ParagraphTitle>
                        <ParagraphBody>{section_1.our_commitment_body}</ParagraphBody>
                    </div>
                    <div>
                        <ParagraphTitle>{section_1.info_tech_title}</ParagraphTitle>
                        <ParagraphBody>{section_1.info_tech_body_1}</ParagraphBody>
                        <ParagraphBody>{section_1.info_tech_body_2}</ParagraphBody>
                        <ParagraphBody dangerouslySetInnerHTML={{ __html: section_1.info_tech_body_3 }} />
                    </div>
                    <div>
                        <ParagraphTitle>{section_1.training_title}</ParagraphTitle>
                        <ParagraphBody>{section_1.training_body}</ParagraphBody>
                    </div>
                    <div>
                        <ParagraphTitle>{section_1.oversight_title}</ParagraphTitle>
                        <ParagraphBody>{section_1.oversight_body}</ParagraphBody>
                    </div>
                    <FaqContentContainer>
                        <FaqContentWrapper>
                            {section_2.faq_items.map((faqItem, index) => (
                                <FaqItem key={index} faqItem={faqItem} />
                            ))}
                        </FaqContentWrapper>
                    </FaqContentContainer>
                </BodyWrapper>
            </GutterWrapper>
        </TemplateWrapper>
    );
};

PageTemplateConsumerLegalDPAS.propTypes = {};
PageTemplateConsumerLegalDPAS.defaultProps = {};

export default PageTemplateConsumerLegalDPAS;

import React from "react";
import styled from "styled-components";
import YellowStone from "./YellowStone";
import BlueStone from "./BlueStone";

const ContentBackgroundContainer = styled.div`
    label: ContentBackgroundContainer;

    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    * {
        box-sizing: border-box;
        //border: 1px dashed red; // XXX KILL ME
    }
`;

const ColumnContainer = styled.div`
    label: ColumnContainer;

    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
`;

const LeftColumnContainer = styled.div`
    label: LeftColumnContainer;

    position: absolute;
    max-width: 49vw;
    top: -100px;

    svg {
        margin-bottom: 500px;
        g {
            fill: #fff7dc;
        }
        &:nth-of-type(3n + 2) {
            g {
                fill: #96cae2;
            }
        }
        &:nth-of-type(2) {
            transform: rotate(242deg);
        }
        &:nth-of-type(3n + 2) {
            transform: rotate(242deg);
        }
        &:nth-of-type(4) {
            g {
                fill: #dadada;
            }
        }
        //&:nth-of-type(7) {
        //  g {
        //    fill: #ecf9ff;
        //  }
        //}
    }
`;

const RightColumnContainer = styled.div`
    label: RightColumnContainer;
    position: absolute;
    max-width: 49vw;
    top: 400px;

    svg {
        &:nth-of-type(1) {
            margin-bottom: 500px;
        }
        &:nth-of-type(2) {
            transform: rotate(270deg);
            g {
                fill: #dadada;
            }
        }
        &:nth-of-type(4) {
            g {
                fill: #fff7dc;
            }
        }
        &:nth-of-type(6) {
            transform: rotate(180deg);
            g {
                fill: #dadada;
            }
        }
        &:nth-of-type(7) {
            g {
                fill: #ecf9ff;
            }
        }
        &:nth-of-type(4n + 3) {
            g {
                fill: #96cae2;
            }
        }
        &:nth-of-type(3n + 2) {
            transform: rotate(-52deg);
        }
    }
`;

const ContentBackground = () => (
    <ContentBackgroundContainer>
        <ColumnContainer>
            <LeftColumnContainer>
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
                <YellowStone />
            </LeftColumnContainer>
        </ColumnContainer>
        <ColumnContainer>
            <RightColumnContainer>
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
                <BlueStone />
            </RightColumnContainer>
        </ColumnContainer>
    </ContentBackgroundContainer>
);

export default ContentBackground;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const FooterSection = styled.section`
    label: FooterSection;

    background-color: #363534;

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    padding: 3em;
`;
const ContentDiv = styled.div`
    label: ContentDiv;

    color: #eeeeee;
    font-family: effra, sans-serif;
    font-weight: 100;

    display: flex;
    max-width: 1084px;
    width: 100%;

    line-height: 24px;
    font-size: 13pt;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        flex-direction: column !important;
        justify-content: center;
        align-content: center;
        padding: 1em 0;
        text-align: center;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        flex-direction: column !important;
        justify-content: center;
        align-content: center;
        padding: 1em 0;
        text-align: center;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        flex-direction: column !important;
        justify-content: center;
        align-content: center;
        padding: 1em 0;
        text-align: center;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        flex-direction: row !important;
        justify-content: center;
        align-content: center;
        padding: 1em 5%;
        text-align: center;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        flex-direction: row !important;
        justify-content: center;
        align-content: center;
        margin: auto;
        padding: 1em;
        text-align: center;
    }
`;

const Disclaimer = ({ content }) => {
    return (
        <FooterSection data-qa={"footer-disclaimer"}>
            <ContentDiv dangerouslySetInnerHTML={{ __html: content }} />
        </FooterSection>
    );
};

Disclaimer.propTypes = {
    visible: PropTypes.bool,
    content: PropTypes.element
};

export default Disclaimer;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Swiper from "swiper";

// Styles
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import PageSectionWrapper from "../component-styles/PageSectionWrapper";

// Components
import FeatureListHover, { FeatureListHoverPropTypes } from "../components/Features/FeatureListHover";

// Carousel
const FeatureListHoverCarouselSlideDiv = styled.div`
    label: FeatureListHoverCarouselSlideDiv;

    width: 100%;
    > div {
        margin-left: 2em;
        margin-right: 2em;
    }
`;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;

    .swiper-pagination-bullet {
        border: 2px solid #666666;
        height: 10px;
        width: 10px;
        margin-left: 13px !important;
        margin-right: 13px !important;
        background-color: inherit !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666 !important;
    }
`;
const CarouselNavigationNext = styled.div`
    label: CarouselNavigationNext;

    --swiper-navigation-color: #666666;
`;
const CarouselNavigationPrevious = styled.div`
    label: CarouselNavigationPrevious;

    --swiper-navigation-color: #666666;
`;
const FeatureListHoverCarouselLayout = styled.div`
    width: 100%;
    > div:nth-of-type(1) {
        margin-left: 2.5em;
        margin-right: 4em;
        margin-bottom: 50px;
    }
`;

class FeatureListHoverCarousel extends React.Component {
    componentDidMount() {
        new Swiper(".swiper-container", {
            cssMode: true,
            loop: true,
            centeredSlides: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            },
            mousewheel: true,
            keyboard: true
        });
    }

    render() {
        const { dataQa, panels, title } = this.props;
        if (!panels) return null;

        return (
            <PageSectionWrapper>
                <FeatureListHoverCarouselLayout>
                    {title && <TitleWrapper {...title}>{title.content}</TitleWrapper>}
                    {panels && (
                        <div className="swiper-container">
                            <div className="swiper-wrapper">
                                {panels.map((panel, i) => (
                                    <FeatureListHoverCarouselSlideDiv
                                        data-qa={`content-section-header-${dataQa}`}
                                        className="swiper-slide"
                                        key={`image-text-carousel-slide-${i}`}
                                    >
                                        <FeatureListHover dataQa={dataQa} {...panel} />
                                    </FeatureListHoverCarouselSlideDiv>
                                ))}
                            </div>

                            {/* Add Arrows */}

                            <CarouselNavigationBullets className="swiper-pagination" />
                            <CarouselNavigationNext className="swiper-button-next" />
                            <CarouselNavigationPrevious className="swiper-button-prev" />
                        </div>
                    )}
                </FeatureListHoverCarouselLayout>
            </PageSectionWrapper>
        );
    }
}

// List
const FeatureListHoverListLayout = styled.div`
    label: FeatureListHoverListLayout;

    display: flex;
    flex-direction: column;
    width: 100%;
`;
const FeatureListHoverListContainer = styled.div`
    label: FeatureListHoverListContainer;

    display: flex;
    flex-direction: column;
    > div {
        margin-bottom: 50px;
    }
`;
const FeatureListHoverTitleWrapper = styled(TitleWrapper)`
    margin-bottom: 40px;
`;
const FeatureListHoverList = ({ dataQa, panels, title }) => {
    if (!panels) return null;
    return (
        <PageSectionWrapper>
            <FeatureListHoverListLayout>
                {title && <FeatureListHoverTitleWrapper {...title}>{title.content}</FeatureListHoverTitleWrapper>}
                {panels && (
                    <FeatureListHoverListContainer>
                        {panels.map((panel, i) => (
                            <FeatureListHover dataQa={dataQa} key={`image-text-${i}`} {...panel} />
                        ))}
                    </FeatureListHoverListContainer>
                )}
            </FeatureListHoverListLayout>
        </PageSectionWrapper>
    );
};

// Root Component
const PageSectionFeatureListHover = (props) => {
    switch (props.layout) {
        case "list":
            return <FeatureListHoverList {...props} />;
        case "carousel":
            return <FeatureListHoverCarousel {...props} />;
    }
};
const PageSectionFeatureListHoverPropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["list", "carousel"]),
    panels: PropTypes.arrayOf(PropTypes.shape(FeatureListHoverPropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionFeatureListHover.defaultProps = {
    dataQa: "page-section-feature-list-hover",
    layout: "list"
};
PageSectionFeatureListHover.propTypes = PageSectionFeatureListHoverPropTypes;
export default PageSectionFeatureListHover;

import React from "react";
import styled from "styled-components";
import { images } from "@resourcehub/resourcehub-assets";
import {
    LevelColumnWrapper,
    LevelContainer,
    LevelItemContainer,
    LevelSubColumn
} from "./PageTemplateFeatureAudioCompanion";

const LanguageSelectorForm = styled.form`
    margin-top: 2em;
    width: 35%;
    max-width: 40em;
    display: flex;
    flex-flow: column;
    justify-content: center;

    @media (max-width: 1400px) {
        width: 80%;
        max-width: 32em;
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin-top: 2em;
    }

    label {
        display: block;
        text-align: left;
    }
    span {
        font-family: helvetica, sans-serif;
        font-size: 0.8em;
        text-transform: uppercase;
        display: block;
    }
`;

const LanguageSelectorWrapper = styled.div`
    background: url(${images.usConsumerFeaturesAudiocompanionArrowDownJpeg}), white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    height: 2em;
    overflow: hidden;
    width: 18.3em;
    margin-top: 0.5em;
    position: relative;

    @media (max-width: 500px) {
        width: 15em;
    }

    @media (max-width: 340px) {
        width: 13em;
    }

    select {
        background: transparent;
        border: none;
        width: 21em;
        display: block;
        margin: 0 auto;
        font-family: helvetica, sans-serif;
        font-size: 0.9em;
        font-weight: 100;
        padding: 0.5em;
        color: #555;
        text-align: center;
        position: relative;

        @media (max-width: 500px) {
            appearance: none;
            width: 15em;
        }

        @media (max-width: 340px) {
            width: 12em;
            margin: 0;
        }

        option {
            text-align: center;
        }
    }
`;

const FoundationHeader = styled.div`
    background-color: hsla(198, 80%, 68%, 1);
    padding: 1em 1em 2em;
    text-align: center;
    color: #eee;
    min-height: 155px;
    grid-column: 1 / -1;

    @media (max-width: 995px) {
        min-height: 130px;
    }

    @media (max-width: 540px) {
        min-height: 150px;
    }
`;

const ExplorationHeader = styled(FoundationHeader)`
    background-color: hsla(198, 80%, 58%, 1);
`;

const ConversationHeader = styled(FoundationHeader)`
    background-color: rgb(24, 162, 222);
`;

export const AudioCompanionLanguageSelector = ({ onLanguageChangeHandler, currentLanguage, config }) => {
    return (
        <LanguageSelectorForm>
            <label>
                <span>{config.page.content.sections.lang_selector.select_language_p}</span>
            </label>
            <LanguageSelectorWrapper>
                <select name="lang" value={currentLanguage} onChange={onLanguageChangeHandler}>
                    {config.page.content.sections.lang_selector.languages.map(({ name, value }, index) => (
                        <option value={value} key={index}>
                            {name}
                        </option>
                    ))}
                </select>
            </LanguageSelectorWrapper>
        </LanguageSelectorForm>
    );
};

const AudioCompanionLevelColumnWrapper = styled.div`
    display: flex;

    @media (max-width: 560px) {
        flex-direction: column;
    }
`;

const AudioCompanionColumn = styled.div`
    width: 50%;

    @media (max-width: 560px) {
        width: 100%;
    }
`;

const generateAudioCompanionUnitLink = (langCode, level, unit) => {
    const unitsOneToNineLink = `https://resources.rosettastone.com/ac/${langCode}/${langCode}_L${level}U0${unit}.zip`;

    if (unit < 10) {
        return unitsOneToNineLink;
    }

    return `https://resources.rosettastone.com/ac/${langCode}/${langCode}_L${level}U${unit}.zip`;
};

const AudioCompanionLevelUnit = ({ language, level, currentUnitTextIndex, currentUnit, config }) => (
    <LevelItemContainer>
        <a href={generateAudioCompanionUnitLink(language, level, currentUnit)}>
            {config.page.content.sections.levels[level][`unit_0${currentUnitTextIndex}_text`]}
        </a>
    </LevelItemContainer>
);

const AudioCompanionLevel = ({ language, level, config }) => {
    let baseUnit;
    let baseUnitTextIndex = 0;
    const audioCompanionLevels = [];

    switch (level) {
        case 1:
            baseUnit = 1;
            break;
        case 2:
            baseUnit = 5;
            break;
        case 3:
            baseUnit = 9;
            break;
        case 4:
            baseUnit = 13;
            break;
        case 5:
            baseUnit = 17;
            break;
        default:
            return null;
    }

    const lastAudioCompanionUnit = baseUnit + 3;

    for (
        let currentAudioCompanionUnit = baseUnit;
        currentAudioCompanionUnit <= lastAudioCompanionUnit;
        currentAudioCompanionUnit++
    ) {
        baseUnitTextIndex += 1;

        audioCompanionLevels.push(
            <AudioCompanionLevelUnit
                language={language}
                level={level}
                currentUnitTextIndex={baseUnitTextIndex}
                currentUnit={currentAudioCompanionUnit}
                config={config}
            />
        );
    }

    return <LevelSubColumn level={level}>{audioCompanionLevels}</LevelSubColumn>;
};

const LevelsOneToThree = ({ language, config }) => (
    <>
        <div>
            <FoundationHeader>
                {config.page.content.sections.columns.fundamentals.heading}
                <br />
                {config.page.content.sections.columns.fundamentals.text}
            </FoundationHeader>
            <div>
                <AudioCompanionLevelColumnWrapper>
                    <AudioCompanionColumn>
                        <LevelContainer level={1}>
                            {config.page.content.sections.levels[1].text}
                            <br />
                            {config.page.content.sections.levels[1].units_text}
                        </LevelContainer>
                        <AudioCompanionLevel language={language} level={1} config={config} />
                    </AudioCompanionColumn>
                    <AudioCompanionColumn>
                        <LevelContainer level={2}>
                            {config.page.content.sections.levels[2].text}
                            <br />
                            {config.page.content.sections.levels[2].units_text}
                        </LevelContainer>
                        <AudioCompanionLevel language={language} level={2} config={config} />
                    </AudioCompanionColumn>
                </AudioCompanionLevelColumnWrapper>
            </div>
        </div>
        <div>
            <ExplorationHeader>
                {config.page.content.sections.columns.exploration_and_clarity.heading}
                <br />
                {config.page.content.sections.columns.exploration_and_clarity.text}
            </ExplorationHeader>
            <div>
                <LevelColumnWrapper>
                    <div>
                        <LevelContainer level={3}>
                            {config.page.content.sections.levels[3].text}
                            <br />
                            {config.page.content.sections.levels[3].units_text}
                        </LevelContainer>
                        <AudioCompanionLevel language={language} level={3} config={config} />
                    </div>
                </LevelColumnWrapper>
            </div>
        </div>
    </>
);

const LevelsOneToFive = ({ language, config }) => (
    <>
        <div>
            <FoundationHeader>
                {config.page.content.sections.columns.fundamentals.heading}
                <br />
                {config.page.content.sections.columns.fundamentals.text}
            </FoundationHeader>
            <div>
                <AudioCompanionLevelColumnWrapper>
                    <AudioCompanionColumn>
                        <LevelContainer level={1}>
                            {config.page.content.sections.levels[1].text}
                            <br />
                            {config.page.content.sections.levels[1].units_text}
                        </LevelContainer>
                        <AudioCompanionLevel language={language} level={1} config={config} />
                    </AudioCompanionColumn>
                    <AudioCompanionColumn>
                        <LevelContainer level={2}>
                            {config.page.content.sections.levels[2].text}
                            <br />
                            {config.page.content.sections.levels[2].units_text}
                        </LevelContainer>
                        <AudioCompanionLevel language={language} level={2} config={config} />
                    </AudioCompanionColumn>
                </AudioCompanionLevelColumnWrapper>
            </div>
        </div>
        <div>
            <ExplorationHeader>
                {config.page.content.sections.columns.exploration_and_clarity.heading}
                <br />
                {config.page.content.sections.columns.exploration_and_clarity.text}
            </ExplorationHeader>
            <div>
                <AudioCompanionLevelColumnWrapper>
                    <AudioCompanionColumn>
                        <LevelContainer level={3}>
                            {config.page.content.sections.levels[3].text}
                            <br />
                            {config.page.content.sections.levels[3].units_text}
                        </LevelContainer>
                        <AudioCompanionLevel language={language} level={3} config={config} />
                    </AudioCompanionColumn>
                    <AudioCompanionColumn>
                        <LevelContainer level={4}>
                            {config.page.content.sections.levels[4].text}
                            <br />
                            {config.page.content.sections.levels[4].units_text}
                        </LevelContainer>
                        <AudioCompanionLevel language={language} level={4} config={config} />
                    </AudioCompanionColumn>
                </AudioCompanionLevelColumnWrapper>
            </div>
        </div>
        <div>
            <ConversationHeader>
                {config.page.content.sections.columns.conversation.heading}
                <br />
                {config.page.content.sections.columns.conversation.text}
            </ConversationHeader>
            <div>
                <LevelColumnWrapper>
                    <div>
                        <LevelContainer level={5}>
                            {config.page.content.sections.levels[5].text}
                            <br />
                            {config.page.content.sections.levels[5].units_text}
                        </LevelContainer>
                        <AudioCompanionLevel language={language} level={5} config={config} />
                    </div>
                </LevelColumnWrapper>
            </div>
        </div>
    </>
);

const AudioCompanionLanguageLevels = ({ language, config }) => {
    switch (language) {
        case "ARA":
            return <LevelsOneToThree language={language} config={config} />;
        case "CHI":
            return <LevelsOneToFive language={language} config={config} />;
        case "NED":
            return <LevelsOneToThree language={language} config={config} />;
        case "ENG":
            return <LevelsOneToFive language={language} config={config} />;
        case "EBR":
            return <LevelsOneToFive language={language} config={config} />;
        case "FAR":
            return <LevelsOneToThree language={language} config={config} />;
        case "TGL":
            return <LevelsOneToThree language={language} config={config} />;
        case "FRA":
            return <LevelsOneToFive language={language} config={config} />;
        case "DEU":
            return <LevelsOneToFive language={language} config={config} />;
        case "GRK":
            return <LevelsOneToThree language={language} config={config} />;
        case "HEB":
            return <LevelsOneToThree language={language} config={config} />;
        case "HIN":
            return <LevelsOneToThree language={language} config={config} />;
        case "GLE":
            return <LevelsOneToThree language={language} config={config} />;
        case "ITA":
            return <LevelsOneToFive language={language} config={config} />;
        case "JPN":
            return <LevelsOneToThree language={language} config={config} />;
        case "KOR":
            return <LevelsOneToThree language={language} config={config} />;
        case "LAT":
            return <LevelsOneToThree language={language} config={config} />;
        case "POL":
            return <LevelsOneToThree language={language} config={config} />;
        case "POR":
            return <LevelsOneToThree language={language} config={config} />;
        case "RUS":
            return <LevelsOneToFive language={language} config={config} />;
        case "ESP":
            return <LevelsOneToFive language={language} config={config} />;
        case "ESC":
            return <LevelsOneToFive language={language} config={config} />;
        case "SVE":
            return <LevelsOneToThree language={language} config={config} />;
        case "TUR":
            return <LevelsOneToThree language={language} config={config} />;
        case "VIE":
            return <LevelsOneToThree language={language} config={config} />;
        default:
            return null;
    }
};

export default AudioCompanionLanguageLevels;

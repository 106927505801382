import React from "react";
import PropTypes from "prop-types";
import { services } from "@resourcehub/resourcehub-assets";
import styled from "styled-components";

const { iconSelector } = services;

const FeatureContainer = styled.div`
    z-index: 4;
    width: 33%;
    padding: 4%;
    display: block;
`;

const FeatureImg = styled.img`
    height: 50px;
`;

const FeatureTitle = styled.div`
    width: 100%;
    font-size: 1.4em;
    font-family: effra_medium, sans-serif;
    padding: 0.4em 0;

    @media (max-width: 1024px) {
        font-size: 0.8em;
    }

    @media (max-width: 375px) {
        font-size: 0.9em;
    }
`;

const FeatureSubtitle = styled.div`
    width: 100%;
`;

const FeaturesWrapper = styled.div`
    padding: 0 10%;
    z-index: 4;
    position: relative;

    @media (max-width: 425px) {
        padding: 0 5%;
    }
`;

const FeaturesImgOne = styled.img`
    z-index: -1;
    position: absolute;
    top: 80px;
    right: -620px;
    transform: scaleX(-1) rotate(10deg);

    @media (max-width: 1080px) {
        display: none;
    }
`;

const FeaturesImgTwo = styled.img`
    z-index: -1;
    position: absolute;
    top: 700px;
    left: -550px;
    transform: scaleX(-1) rotate(180deg);

    @media (max-width: 1080px) {
        display: none;
    }

    @media (max-width: 760px) {
        top: 1110px;
    }
`;

const SectionTitle = styled.h2`
    padding: 1em 0;
    font-family: helvetica_bold, sans-serif;
    text-align: center;
    margin: 10px auto;
    width: 80%;
    z-index: 5;

    @media (max-width: 1024px) {
        text-align: left;
        margin: 40px 10%;
    }
`;

const FeaturesContent = styled.div`
    width: 100%;
    z-index: 4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 425px) {
        justify-content: space-evenly;
    }
`;

const Features = ({ data, img_bg_top, img_bg_bottom }) => {
    const features = [];

    for (let i = 1; i <= 15; i += 1) {
        const iconKey = `feature_${i}_img`;
        const iconName = data[iconKey];
        const icon = iconSelector(iconName);
        if (iconName) {
            features.push(
                <FeatureContainer key={data[`feature_${i}_title`]}>
                    <FeatureImg src={icon} alt={`${data[`feature_${i}_title`]} icon`} />
                    <FeatureTitle>{data[`feature_${i}_title`]}</FeatureTitle>
                    <FeatureSubtitle>{data[`feature_${i}_subtitle`]}</FeatureSubtitle>
                </FeatureContainer>
            );
        }
    }

    return (
        <FeaturesWrapper>
            <FeaturesImgOne src={img_bg_top} alt="blue background" />
            <FeaturesImgTwo src={img_bg_bottom} alt="yellow background" />
            <SectionTitle>{data.feature_section_title}</SectionTitle>
            <FeaturesContent>{features}</FeaturesContent>
        </FeaturesWrapper>
    );
};

Features.propTypes = {
    data: PropTypes.shape({
        feature_section_title: PropTypes.string.isRequired,
        feature_1_title: PropTypes.string.isRequired,
        feature_1_subtitle: PropTypes.string.isRequired,
        feature_1_img: PropTypes.string.isRequired
    }).isRequired,
    img_bg_top: PropTypes.string.isRequired
};

export default Features;

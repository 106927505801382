import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Swiper from "swiper";

// Styles
import ChildContentWrapper from "../component-styles/ChildContentWrapper";
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";

// Components
import ImageBanner, { ImageBannerPropTypes } from "../components/Images/ImageBanner";

// Carousel
const ImageBannerCarouselSection = styled.section`
    label: ImageBannerCarouselSection;

    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        flex-direction: column;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        flex-direction: column;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: column;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        flex-direction: column;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        flex-direction: row;
        ${({ direction }) => (direction === "content-left" ? "flex-direction: row;" : "flex-direction: row-reverse;")}
    }
`;
const ImageBannerCarouselSlideDiv = styled.div`
    label: ImageBannerCarouselSlideDiv;

    width: 100%;
    > div {
        margin-left: 2em;
        margin-right: 2em;
    }
`;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;

    .swiper-pagination-bullet {
        border: 2px solid #666666;
        height: 10px;
        width: 10px;
        margin-left: 13px !important;
        margin-right: 13px !important;
        background-color: inherit !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666 !important;
    }
`;
const CarouselNavigationNext = styled.div`
    label: CarouselNavigationNext;

    --swiper-navigation-color: #666666;
`;
const CarouselNavigationPrevious = styled.div`
    label: CarouselNavigationPrevious;

    --swiper-navigation-color: #666666;
`;
class ImageBannerCarousel extends React.Component {
    componentDidMount() {
        new Swiper(".swiper-container", {
            cssMode: true,
            loop: true,
            centeredSlides: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            },
            mousewheel: true,
            keyboard: true
        });
    }
    render() {
        const { children, dataQa, panels, title } = this.props;
        if (!panels) return null;
        return (
            <ImageBannerCarouselSection data-qa={`content-section-header-${dataQa}`}>
                {title && <TitleWrapper {...title} />}
                {panels && (
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            {panels.map((panel, i) => (
                                <ImageBannerCarouselSlideDiv
                                    className="swiper-slide"
                                    key={`image-text-carousel-slide-${i}`}
                                >
                                    <ImageBanner {...panel} />
                                </ImageBannerCarouselSlideDiv>
                            ))}
                        </div>

                        {/* Add Arrows */}

                        <CarouselNavigationBullets className="swiper-pagination" />
                        <CarouselNavigationNext className="swiper-button-next" />
                        <CarouselNavigationPrevious className="swiper-button-prev" />
                    </div>
                )}
                {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
            </ImageBannerCarouselSection>
        );
    }
}

// List
const ImageBannerListSection = styled.section`
    label: ImageBannerListSection;

    display: flex;
    flex-direction: column;
    width: 100%;
`;
const ImageBannerListContainer = styled.div`
    label: ImageBannerListContainer;

    display: flex;
    flex-direction: column;
`;
const ImageBannerList = ({ dataQa, panels, title, children }) => {
    if (!panels) return null;
    return (
        <ImageBannerListSection data-qa={`content-section-header-${dataQa}`}>
            {title && <TitleWrapper {...title} />}
            {panels && (
                <ImageBannerListContainer>
                    {panels.map((panel, i) => (
                        <ImageBanner key={`image-banner-${i}`} {...panel} />
                    ))}
                </ImageBannerListContainer>
            )}
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </ImageBannerListSection>
    );
};

// Root Component
const PageSectionImageBanner = (props) => {
    switch (props.layout) {
        case "list":
            return <ImageBannerList {...props} />;
        case "carousel":
            return <ImageBannerCarousel {...props} />;
    }
};
export const PageSectionImageBannerPropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["list", "carousel"]),
    panels: PropTypes.arrayOf(PropTypes.shape(ImageBannerPropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionImageBanner.defaultProps = {
    layout: "list",
    dataQa: "content-section-image-content"
};
PageSectionImageBanner.propTypes = PageSectionImageBannerPropTypes;
export default PageSectionImageBanner;

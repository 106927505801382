import { useEffect, useState } from "react";

export const useFeatureSelect = () => {
    const [intervalId, setIntervalId] = useState("");
    const [selected, setSelected] = useState("1");

    const createInterval = () => {
        if (typeof window !== "undefined") {
            const intervalId = window.setInterval(() => incrementCircle(), 4000);
            setIntervalId(intervalId);
        }
    };

    const incrementCircle = () => {
        const startNum = 1;
        const endNum = 6;
        setSelected((prevSelected) => {
            const isLastFeature = parseInt(prevSelected) === endNum;
            if (isLastFeature) {
                return startNum.toString();
            }
            const incremented = parseInt(prevSelected) + 1;
            return incremented.toString();
        });
    };

    const changeSelected = (index) => {
        clearInterval(intervalId);
        const indexNum = parseInt(index, 10);
        setSelected(indexNum.toString());
    };

    useEffect(() => {
        createInterval();
        return () => clearInterval(intervalId);
    }, []);

    return { selected, changeSelected };
};

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { sectionStones } from "@resourcehub/resourcehub-assets";
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import Image from "../Images/Image";

// import Swiper bundle with all modules installed
import Swiper from "swiper";

const ProductSampleWrapperDiv = styled.div`
    label: BodyWrapper;

    display: inline-block;
    margin: 0;
    padding: 0;
    background-image: url(${sectionStones.yellowBlueStonesSvg});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        transform: scale(0.7);
    }
    @media (min-width: 400px) {
        transform: scale(0.8);
    }
    @media (min-width: 530px) {
        transform: scale(0.9);
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        transform: scale(1);
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        transform: scale(1);
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        transform: scale(1);
    }
`;
// Carousel Layout
const SwiperContainer = styled.div`
    label: SwiperContainer;

    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    @media (max-width: 530px) {
        .swiper-button-prev {
            display: none;
        }
        .swiper-button-next {
            display: none;
        }
    }

    .swiper-slide {
        text-align: center;
        font-size: 24px;
        width: 100% !important;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const SwiperWrapper = styled.div`
    label: SwiperWrapper;
`;
const SwiperPagination = styled.div`
    label: SwiperPagination;
`;
const SwiperPageLeft = styled.div`
    label: SwiperPageLeft;
`;
const SwiperPageRight = styled.div`
    label: SwiperPageRight;
`;
const ProductSampleLayout = styled.div`
    label: ProductSampleLayout;
    display: flex;
    flex-direction: column;
    > div:nth-of-type(1) {
    }
    > div:nth-of-type(2) {
        display: flex;
        flex-direction: row;
        > div {
            margin: 10px;
        }
    }
`;
const Challenge = styled.div`
    label: Challenge;

    background: #fff;
    border-radius: 30px;
    display: inline-block;
    padding: 7px 30px;
    margin: 0 auto 1em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    font-weight: 700;
    font-size: 22px;
`;
const ChallengeOption = styled(Image)`
    label: ChallengeOption;

    position: relative;
    ${({ is_correct }) => {
        if (is_correct) {
            return `
                :before {
                    border-radius: 2em; // ... and turn it into a circle
                    border: solid 2em #88D800; // create a colored area...;
                    content: " "; // the circle itself is empty
                    display: block;
                    height: 0;
                    left: 26%;
                    opacity: 80%;
                    animation: flickerAnimation 5s infinite;
                    position: absolute; // the circle is then put into place;
                    top: 24%;
                    width: 0;
                    z-index: 100;
                }
                :after {
                    content: " ";
                    display: block;
                    width: 0.8em; // the short bar of the mark is half as long as the long bar
                    height: 1.6em;
                    border: solid white;
                    border-width: 0 0.3em 0.3em 0; // creates the inverted "L" shape
                    border-radius: 5px;
                    position: absolute;
                    left: 45%;
                    top: 37%;
                    transform: rotate(45deg);
                    margin-top: -0.2em;
                    -webkit-transform: rotate(45deg); // tilts the shape
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    z-index: 110;
                    animation: flickerAnimation 5s infinite;
                }
                @keyframes flickerAnimation { /* flame pulses */
                    0%   { opacity:.8; }
                    50%  { opacity:0; }
                    100% { opacity:.8; }
                }
            `;
        }
    }}
`;

class ProductSample extends React.Component {
    componentDidMount() {
        new Swiper(".swiper-container", {
            centeredSlides: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            }
        });
    }

    render() {
        const { children, panels } = this.props;
        return (
            <ProductSampleWrapperDiv>
                <SwiperContainer className="swiper-container">
                    <SwiperWrapper className="swiper-wrapper">
                        {panels.map((panel, i) => (
                            <div key={`quote-attribution-${i}`} className="swiper-slide">
                                <ProductSampleSlide panel={panel} />
                            </div>
                        ))}
                    </SwiperWrapper>
                    <SwiperPageLeft className="swiper-button-next" />
                    <SwiperPageRight className="swiper-button-prev" />
                    <SwiperPagination className="swiper-pagination" />
                </SwiperContainer>
                {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
            </ProductSampleWrapperDiv>
        );
    }
}

const ProductSampleSlide = ({ panel }) => (
    <ProductSampleLayout>
        <Challenge>{panel.challenge_text}</Challenge>
        <div>
            <ChallengeOption image={panel.left_option.image} is_correct={panel.left_option.is_correct} />
            <ChallengeOption image={panel.right_option.image} is_correct={panel.right_option.is_correct} />
        </div>
    </ProductSampleLayout>
);

export const ProductSamplePropTypes = {
    // TODO: Add prop types
    panels: PropTypes.array.isRequired
};
ProductSample.propTypes = ProductSamplePropTypes;
ProductSample.defaultProps = {};
export default ProductSample;

import React from "react";
import GutterWrapper from "../../component-styles/GutterWrapper";
import styled from "styled-components";

const CCPAWrapper = styled.div`
    h1 {
        font-family: helvetica, sans-serif;
        font-weight: 100;
        font-size: 2.3em;
        margin-bottom: 0;
    }

    a {
        text-decoration: underline;
        color: blue;

        &:visited {
            color: purple;
        }
    }

    h1 + p {
        border-bottom: 1px solid #222;
        margin-top: 0;
    }

    h2 {
        font-size: 1.2em;
        font-family: helvetica, helvetica_bold, sans-serif;
        font-weight: 700;
        padding-bottom: 10px;
        margin-bottom: 0;
    }

    p {
        line-height: 19px;
        text-align: justify;
        font-size: 90%;
        font-family: helvetica, sans-serif;
        padding: 0.5em 0;
    }
`;

const PageTemplateConsumerLegalCCPA = (config) => (
    <GutterWrapper>
        <CCPAWrapper>
            <h1 dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_heading }} />
            <p>{config.page.content.sections[0].hero_p_1}</p>
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_p_2 }} />
            <h2>{config.page.content.sections[1].cookie_heading}</h2>

            {config.page.content.sections[1].cookie_paragraphs.map((paragraph, index) => (
                <div key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
            ))}
        </CCPAWrapper>
    </GutterWrapper>
);

PageTemplateConsumerLegalCCPA.propTypes = {};
PageTemplateConsumerLegalCCPA.defaultProps = {};

export default PageTemplateConsumerLegalCCPA;

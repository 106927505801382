import React, { useState } from "react";
import styled from "styled-components";

const GDPRMoreInfo = styled.div`
    font-size: 11px;
`;

const GDPRContainer = styled.div`
    font-family: effra, sans-serif;
    max-width: 280px;
    color: #4a4a4a;

    a,
    button {
        cursor: pointer;
        color: black;
    }
`;

const GDPRLabel = styled.label`
    display: block;
    padding: 0 0 4px;
    font-size: 17px;
`;

const GDPRCheckbox = styled.input`
    width: auto;
    height: auto;
    margin: auto 6px auto auto;
    border: unset;
    display: inline-block;
`;

const GDPRButton = styled.button`
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
    box-sizing: border-box;
    margin: 0em;
    font-family: effra, sans-serif;
    padding: 1px 6px;
    border-width: 2px;
    border-style: solid;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
`;

const ExplicitOptIn = ({ dataQa, labels, input }) => {
    const {
        form_privacy1,
        form_privacy2,
        form_privacyUrl,
        from_privacy3,
        form_privacyemail,
        form_privacy4,
        form_updateagreement1,
        form_updateagreement2,
        form_updateagreement3
    } = labels;
    const [isMoreOpen, setIsMoreOpen] = useState(false);
    const handleClick = () => setIsMoreOpen((prevIsMoreOpen) => !prevIsMoreOpen);

    return (
        <GDPRContainer>
            <GDPRLabel data-qa={`${dataQa}-label`} htmlFor="ExplicitOptIn">
                <GDPRCheckbox data-qa={dataQa} autoComplete="off" {...input} />
                {form_updateagreement1}
                &nbsp;
                <GDPRButton type="button" onClick={() => handleClick()}>
                    {form_updateagreement2}
                </GDPRButton>
                &nbsp;
                {form_updateagreement3}
            </GDPRLabel>
            {isMoreOpen && (
                <GDPRMoreInfo>
                    {form_privacy1}
                    &nbsp;
                    <a href={form_privacyUrl} target="_blank" rel="noopener noreferrer">
                        {form_privacy2}
                    </a>
                    {from_privacy3}
                    &nbsp;
                    <a href={`mailto:${form_privacyemail}`}>{form_privacyemail}</a>
                    {form_privacy4}
                </GDPRMoreInfo>
            )}
        </GDPRContainer>
    );
};

export default ExplicitOptIn;

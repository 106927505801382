import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Code was moved to be executed via this function since React doesn't
// execute code injected into a script tag via dangerouslySetInnerHTML
const loadGTMScript = (gtm_id) =>
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", gtm_id);

const GoogleTagManager = ({ gtm_id }) => {
    if (!gtm_id) {
        console.error("GTM ID is required");
        return;
    }

    useEffect(() => {
        loadGTMScript(gtm_id);
    }, []);

    return (
        <>
            <noscript>
                <iframe
                    title="GTM"
                    src={`https://www.googletagmanager.com/ns.html?id=${gtm_id}`}
                    height="0"
                    width="0"
                    style={{
                        display: "none",
                        visibility: "hidden"
                    }}
                />
            </noscript>
        </>
    );
};

GoogleTagManager.defaultProps = {
    gtm_id: ""
};

GoogleTagManager.propTypes = {
    gtm_id: PropTypes.string.isRequired
};

export default GoogleTagManager;

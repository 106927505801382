import React from "react";
import styled from "styled-components";
import LanguagesPricingMobile from "./LanguagesPricingMobile";
import MobileNavLink from "./MobileNavLink";

const SideNavWrapper = styled.div`
    label: SideNavWrapper;

    background: #262626;
    box-sizing: border-box;
    color: #fff;
    display: ${(props) => (props.isOpen ? "block" : "none")};
    font-size: 14px;
    min-height: 100vh;
    padding: 1em 2em;
    position: absolute;
    right: ${(props) => (props.isOpen ? "0" : "-300px")};
    top: 70px;
    transition-duration: 0.5s;
    width: 300px;
    z-index: 1000;
`;

class MobileNav extends React.Component {
    state = {
        isOpen: false,
        dropdownName: "Mobile Nav"
    };

    componentDidUpdate(prevProps) {
        const { openDropdown } = this.props;
        const { dropdownName } = this.state;
        if (prevProps.openDropdown !== openDropdown) {
            this.setState({
                isOpen: openDropdown === dropdownName
            });
        }
    }

    render() {
        const { config } = this.props;
        const { isOpen } = this.state;
        return (
            <SideNavWrapper data-qa="mobile-nav-hamburger-menu-2020" isOpen={!!isOpen}>
                {config.select_language && config.select_language.visible && (
                    <LanguagesPricingMobile dataQa="mobile-nav-languages-pricing-2020" config={config.select_language}>
                        {config.select_language.label}
                    </LanguagesPricingMobile>
                )}
                {config.vertical_consumer && config.vertical_consumer.visible && (
                    <MobileNavLink
                        dataQa="mobile-nav-consumer-2020"
                        destination={config.vertical_consumer.destination}
                        external={config.vertical_consumer.external}
                        openNew={config.vertical_consumer.new_window}
                    >
                        {config.vertical_consumer.label}
                    </MobileNavLink>
                )}
                {config.vertical_enterprise && config.vertical_enterprise.visible && (
                    <MobileNavLink
                        dataQa="mobile-nav-enterprise-2020"
                        destination={config.vertical_enterprise.destination}
                        external={config.vertical_enterprise.external}
                        openNew={config.vertical_enterprise.new_window}
                    >
                        {config.vertical_enterprise.label}
                    </MobileNavLink>
                )}
                {config.vertical_education_primary && config.vertical_education_primary.visible && (
                    <MobileNavLink
                        dataQa="mobile-nav-education-primary-2020"
                        destination={config.vertical_education_primary.destination}
                        external={config.vertical_education_primary.external}
                        openNew={config.vertical_education_primary.new_window}
                    >
                        {config.vertical_education_primary.label}
                    </MobileNavLink>
                )}
                {config.vertical_education_students && config.vertical_education_students.visible && (
                    <MobileNavLink
                        dataQa="mobile-nav-education-students-2020"
                        destination={config.vertical_education_students.destination}
                        external={config.vertical_education_students.external}
                        openNew={config.vertical_education_students.new_window}
                    >
                        {config.vertical_education_students.label}
                    </MobileNavLink>
                )}
                {config.support && config.support.visible && (
                    <MobileNavLink
                        dataQa="mobile-nav-support-2020"
                        destination={config.support.destination}
                        external={config.support.external}
                        openNew={config.support.new_window}
                    >
                        {config.support.label}
                    </MobileNavLink>
                )}
                {config.contact && config.contact.visible && (
                    <MobileNavLink
                        dataQa="mobile-nav-contact-2020"
                        destination={config.contact.destination}
                        external={config.contact.external}
                        openNew={config.contact.new_window}
                    >
                        {config.contact.label}
                    </MobileNavLink>
                )}
                {config.blog && config.blog.visible && (
                    <MobileNavLink
                        dataQa="mobile-blog-2020"
                        destination={config.blog.destination}
                        external={config.blog.external}
                        openNew={config.blog.new_window}
                    >
                        {config.blog.label}
                    </MobileNavLink>
                )}
            </SideNavWrapper>
        );
    }
}

export default MobileNav;

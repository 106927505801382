import React from "react";
import GutterWrapper from "../../component-styles/GutterWrapper";
import styled from "styled-components";

const PrivacyShieldWrapper = styled.div`
    h1 {
        font-family: helvetica, sans-serif;
        font-weight: 100;
        font-size: 2.3em;
        margin-bottom: 0;
        border-bottom: 1px solid #222;

        @media (max-width: 640px) {
            font-size: 2em;
        }
    }

    a {
        text-decoration: underline;
        color: blue;

        &:visited {
            color: purple;
        }
    }

    h2 {
        font-size: 1.2em;
        font-family: helvetica, helvetica_bold, sans-serif;
        font-weight: 700;
        padding-bottom: 10px;
        margin-bottom: 0;
    }

    p {
        line-height: 19px;
        text-align: justify;
        font-size: 90%;
        font-family: helvetica, sans-serif;
        padding: 0.5em 0;
    }
`;

const PageTemplateConsumerLegalPrivacyShield = (config) => (
    <GutterWrapper>
        <PrivacyShieldWrapper>
            <h1 dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_heading }} />
            <h2>{config.page.content.sections[0].hero_heading_p_1}</h2>
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_1 }} />
            <h2>{config.page.content.sections[0].hero_heading_p_2}</h2>
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_2_1 }} />
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_2_2 }} />
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_2_3 }} />
            <h2>{config.page.content.sections[0].hero_heading_p_3}</h2>
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_3_1 }} />
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_3_2 }} />
            <h2>{config.page.content.sections[0].hero_heading_p_4}</h2>
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_4_1 }} />
            <h2>{config.page.content.sections[0].hero_heading_p_5}</h2>
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_5_1 }} />
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_5_2 }} />
            <h2>{config.page.content.sections[0].hero_heading_p_6}</h2>
            <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_body_p_6_1 }} />
        </PrivacyShieldWrapper>
    </GutterWrapper>
);

PageTemplateConsumerLegalPrivacyShield.propTypes = {};
PageTemplateConsumerLegalPrivacyShield.defaultProps = {};

export default PageTemplateConsumerLegalPrivacyShield;

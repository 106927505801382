import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Slide, toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import { emailCollectionStore } from "@resourcehub/resourcehub-data";
import { emailServices } from "@resourcehub/resourcehub-services";

import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import ButtonStyled, { ButtonStyledPropTypes } from "../Buttons/ButtonStyled";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import Link, { LinkPropTypes } from "../Links/Link";
import FormFieldInput, { FormFieldInputPropTypes } from "../FormFields/FormFieldInput";

import ReactTooltip from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";
import BodyWrapper from "../../component-styles/BodyWrapper";

const { emailCollectionActions, emailCollectionConstants, emailCollectionSelectors } = emailCollectionStore;

const statusOptions = Object.keys(emailCollectionConstants.submissionStatus);

const EmailCollectionBarWrapperDiv = styled.div`
    label: EmailCollectionBarWrapperDiv;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-height: 50px;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        flex-direction: column;
        > div {
            margin: 5px 0;
            width: 100%;
        }
        div {
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        flex-direction: column;
        > div {
            margin: 5px 0;
            width: 100%;
        }
        div {
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: column;
        > div {
            margin: 5px 0;
            width: 100%;
        }
        div {
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        flex-direction: row;
        > div {
            margin: 0 5px;
            width: initial;
        }
        div {
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        flex-direction: row;
        > div {
            margin: 0 5px;
            width: initial;
        }

        div {
            text-align: inherit;
            align-items: inherit;
        }
    }

    @-webkit-keyframes fadeOut {
        0% {
            height: 50px;
        }
        100% {
            height: 0;
        }
    }

    @keyframes fadeOut {
        0% {
            height: 50px;
        }
        100% {
            height: 0;
        }
    }

    ${({ status }) => {
        switch (status) {
            case emailCollectionConstants.submissionStatus.error:
                return `
                    background-color: #b40000;
                `;
            case emailCollectionConstants.submissionStatus.success:
                return `
                    background-color: #29b614;
                    -webkit-animation-duration: 10s;animation-duration: 2s;
                    animation-name: fadeOut;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                `;
            case emailCollectionConstants.submissionStatus.complete:
                return `
                    height: 0;
                    transition: height 0.5s ease;
                `;
            default:
                return `
                    background-color: #0098db;
                `;
        }
    }};
`;

const ToastContainerStyled = styled(ToastContainer)`
    .Toastify__toast--error {
        background: #b40000;
    }
`;

const SuccessWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const SuccessIcon = styled.div`
    width: 32px;
    height: 32px;
    margin-right: 10px;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU4ICg4NDY2MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+Q29ycmVjdDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJDb3JyZWN0Ij4KICAgICAgICAgICAgPHBhdGggZD0iTTE2LDMwLjczNjg0MjEgQzI0LjEzODg1NzIsMzAuNzM2ODQyMSAzMC43MzY4NDIxLDI0LjEzODg1NzIgMzAuNzM2ODQyMSwxNiBDMzAuNzM2ODQyMSw3Ljg2MTA5MTQ1IDI0LjEzODg4NTgsMS4yNjMxNTc4OSAxNiwxLjI2MzE1Nzg5IEM3Ljg2MTA2Mjg0LDEuMjYzMTU3ODkgMS4yNjMxNTc4OSw3Ljg2MTA2Mjg0IDEuMjYzMTU3ODksMTYgQzEuMjYzMTU3ODksMjQuMTM4ODg1OCA3Ljg2MTA5MTQ1LDMwLjczNjg0MjEgMTYsMzAuNzM2ODQyMSBaIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIuNTI2MzE1NzkiPjwvcGF0aD4KICAgICAgICAgICAgPHBhdGggZD0iTTcuNjA2MjA4LDE3LjIxODg4IEM3LjI5Mzg0LDE2LjkwNjQgNy4yOTM4NTYsMTYuNCA3LjYwNjI1NiwxNi4wODc1MiBMOC43Mzg4NjQsMTQuOTU0OTYgQzkuMDUxMDA4LDE0LjY0MjgxNiA5LjU1Njk5MiwxNC42NDI0OTYgOS44Njk1MiwxNC45NTQyNTYgTDEzLjgzNDY3MiwxOC45MDk2IEwyMi43MzU4NCwxMC4wMDcwNCBDMjMuMDQ4OCw5LjY5Mzk2OCAyMy41NTY2NCw5LjY5NDY0IDIzLjg2ODgsMTAuMDA4NTQ0IEwyNC45OTY4LDExLjE0MjY3MiBDMjUuMzA3NjgsMTEuNDU1MzI4IDI1LjMwNzA0LDExLjk2MDY0IDI0Ljk5NTIsMTIuMjcyNDY0IEwxNC4zOTM0NCwyMi44NzU2OCBDMTQuMDgxMDA4LDIzLjE4ODE2IDEzLjU3NDQsMjMuMTg4IDEzLjI2MTk4NCwyMi44NzU1MiBMNy42MDYyMDgsMTcuMjE4ODggWiIgaWQ9IlBhdGgiIGZpbGw9IiNGRkZGRkYiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
        no-repeat center;
`;

const PrivacyToolTip = styled(ReactTooltip)`
    box-shadow: 0 0 2px #cccccc;
    background-color: rgba(34, 34, 34, 1);

    > div:nth-of-type(1) {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    > div:nth-of-type(2) {
        > div {
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
        }
    }

    a {
        text-decoration: underline;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        width: 90vw;
        line-height: 1;
        font-size: 11pt !important;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        line-height: 1;
        font-size: 11pt !important;
        width: 98vw;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        line-height: 1.5;
        font-size: 12pt !important;
        width: 90vw;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        line-height: 2;
        font-size: 14pt !important;
        width: 50vw;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        line-height: 2;
        font-size: 14pt !important;
        width: 50vw;
    }
`;

class EmailCollectionBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            status: null,
            submitEnabled: true
        };
        this.handleComplete = this.handleComplete.bind(this);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            if (this.props.submission_status === emailCollectionConstants.submissionStatus.received) {
                const { returnCode } = this.props.response_data;
                switch (returnCode) {
                    case "0":
                        this.handleComplete();
                        break;
                    default:
                        this.handleError();
                }
            }
        }
    };

    componentDidMount = () => {
        const params = new URLSearchParams(document.location.search.substring(1));
        const cid = params.get("cid"); // is the string "Jonathan"
        const consentType = (window.gdpr && window.gdpr.email_consent) || "unknown";
        const visitCountryCode = (window.gdpr && window.gdpr.country_code) || "unknown";
        this.setState({
            consentType,
            visitCountryCode,
            cid
        });
    };

    handleComplete = () => {
        this.setState({
            status: emailCollectionConstants.submissionStatus.success
        });
        setTimeout(() => {
            this.props.setEmailCollectionStatus(emailCollectionConstants.submissionStatus.complete);
            this.setState({
                status: emailCollectionConstants.submissionStatus.complete
            });
        }, 3000);
    };

    handleError = () => {
        const errorTimeout = 3000;
        const { error_message } = this.props;
        // Show Toaster With Error Messages
        toast.error(error_message.content, {
            position: "bottom-center",
            autoClose: errorTimeout,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
        // Change Banner Color State to Error
        this.setState({
            status: emailCollectionConstants.submissionStatus.error,
            submitEnabled: false
        });

        // Change back to normal after timeout
        setTimeout(() => {
            this.props.setEmailCollectionStatus(emailCollectionConstants.submissionStatus.open);
            this.setState({
                status: null,
                submitEnabled: true
            });
        }, errorTimeout);
    };

    handleChange = ({ field, value }) => {
        this.setState({
            [field]: value
        });
    };

    submitForm = () => {
        if (this.state.email) {
            const isValid = emailServices.validateEmail(this.state.email);
            if (isValid) {
                this.props.setEmailCollectionSubmission({ ...this.props, ...this.state });
            } else {
                this.handleError();
            }
        } else {
            this.handleError();
        }
    };

    render = () => {
        const {
            button,
            children,
            dataQa,
            email_field,
            title,
            subtitle,
            legal,
            legal_link,
            privacy_tooltip,

            success_message
        } = this.props;
        // test error
        const { status, submitEnabled } = this.state;
        return (
            <>
                <ToastContainerStyled transition={Slide} />
                <EmailCollectionBarWrapperDiv data-qa={`EmailCollectionBar-${dataQa}`} status={status}>
                    {status === emailCollectionConstants.submissionStatus.success && (
                        <>
                            <SuccessWrapper>
                                <SuccessIcon />
                                <TitleWrapper {...success_message} />
                            </SuccessWrapper>
                        </>
                    )}
                    {status !== emailCollectionConstants.submissionStatus.success &&
                        status !== emailCollectionConstants.submissionStatus.complete && (
                            <>
                                <div>
                                    <TitleWrapper {...title} />
                                    <TitleWrapper {...subtitle} />
                                </div>
                                <div>
                                    <FormFieldInput
                                        {...email_field}
                                        sendValue={({ field, value }) => this.handleChange({ field, value })}
                                        validator={emailServices.validateEmail}
                                    />
                                </div>
                                <div>
                                    <ButtonStyled {...button} onClick={this.submitForm} disabled={!submitEnabled} />
                                </div>
                                {privacy_tooltip && (
                                    <div>
                                        <TitleWrapper {...legal} />
                                        <Link
                                            {...legal_link}
                                            destination={null}
                                            data-tip="custom show and hide"
                                            data-event="click"
                                        />
                                        <PrivacyToolTip
                                            globalEventOff="click"
                                            clickable={true}
                                            place="top"
                                            type="dark"
                                            effect="solid"
                                        >
                                            <TitleWrapper {...privacy_tooltip.title} />
                                            <BodyWrapper {...privacy_tooltip.body} />
                                        </PrivacyToolTip>
                                    </div>
                                )}
                            </>
                        )}
                    {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
                </EmailCollectionBarWrapperDiv>
            </>
        );
    };
}

export const EmailCollectionBarPropTypes = {
    dataQa: PropTypes.string,

    website: PropTypes.string.isRequired,
    web_path: PropTypes.string.isRequired,
    demo_type: PropTypes.string.isRequired,
    newsletter_type: PropTypes.string.isRequired,

    button: PropTypes.shape(ButtonStyledPropTypes).isRequired,
    email_field: PropTypes.shape(FormFieldInputPropTypes).isRequired,
    subtitle: PropTypes.shape(TitleWrapperPropTypes),
    title: PropTypes.shape(TitleWrapperPropTypes),
    legal: PropTypes.shape(TitleWrapperPropTypes),
    legal_link: PropTypes.shape(LinkPropTypes),
    success_message: PropTypes.shape(TitleWrapperPropTypes),

    submission_status: PropTypes.oneOf(statusOptions),
    submission_data: PropTypes.object
};
EmailCollectionBar.propTypes = EmailCollectionBarPropTypes;
EmailCollectionBar.defaultProps = {};

// Selectors
const mapStateToProps = (state) => ({
    response_data: emailCollectionSelectors.getResponseData(state),
    submission_status: emailCollectionSelectors.getSubmissionStatus(state),
    submission_data: emailCollectionSelectors.getResponseData(state)
});

export { EmailCollectionBar };
export default connect(mapStateToProps, emailCollectionActions)(EmailCollectionBar);

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import PageSectionWrapper from "../component-styles/PageSectionWrapper";
import QuoteBranded, { QuoteBrandedPropTypes } from "../components/Quotes/QuoteBranded";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";

// Carousel
const QuoteBrandedCarouselSlideDiv = styled.div`
    label: ImageCarouselSlideDiv;

    width: 100%;
    > div {
        margin-left: 2em;
        margin-right: 2em;
    }
`;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;

    .swiper-pagination-bullet {
        border: 2px solid #666666;
        height: 10px;
        width: 10px;
        margin-left: 13px !important;
        margin-right: 13px !important;
        background-color: inherit !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666 !important;
    }
`;
const CarouselNavigationNext = styled.div`
    label: CarouselNavigationNext;

    --swiper-navigation-color: #666666;
`;
const CarouselNavigationPrevious = styled.div`
    label: CarouselNavigationPrevious;

    --swiper-navigation-color: #666666;
`;
const QuoteBrandedCarouselLayout = styled.div`
    width: 100%;
    > div:nth-of-type(1) {
        margin-left: 2.5em;
        margin-right: 4em;
        margin-bottom: 50px;
    }
`;
class QuoteBrandedCarousel extends React.Component {
    componentDidMount() {
        new Swiper(".swiper-container", {
            cssMode: true,
            loop: true,
            centeredSlides: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            },
            mousewheel: true,
            keyboard: true
        });
    }
    render() {
        const { dataQa, panels, title } = this.props;
        if (!panels) return null;
        return (
            <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
                <QuoteBrandedCarouselLayout>
                    {title && <TitleWrapper {...title} />}
                    {panels && (
                        <div className="swiper-container">
                            <div className="swiper-wrapper">
                                {panels.map((panel, i) => (
                                    <QuoteBrandedCarouselSlideDiv
                                        className="swiper-slide"
                                        key={`image-text-carousel-slide-${i}`}
                                    >
                                        <QuoteBranded {...panel} />
                                    </QuoteBrandedCarouselSlideDiv>
                                ))}
                            </div>

                            {/* Add Arrows */}

                            <CarouselNavigationBullets className="swiper-pagination" />
                            <CarouselNavigationNext className="swiper-button-next" />
                            <CarouselNavigationPrevious className="swiper-button-prev" />
                        </div>
                    )}
                </QuoteBrandedCarouselLayout>
            </PageSectionWrapper>
        );
    }
}

// List Layout
const QuoteBrandedListSection = styled.section`
    label: QuoteBrandedListSection;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    > div:last-of-type {
        margin-top: 50px;
    }
`;
const QuoteBrandedListContainer = styled.div`
    label: QuoteBrandedListContainer;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;
const QuoteBrandedList = ({ dataQa, panels, title }) => {
    if (!panels) return null;
    return (
        <QuoteBrandedListSection data-qa={`content-section-header-${dataQa}`}>
            {title && <TitleWrapper {...title} />}
            {panels && (
                <QuoteBrandedListContainer>
                    {panels.map((panel, i) => (
                        <QuoteBranded key={`image-${i}`} {...panel} />
                    ))}
                </QuoteBrandedListContainer>
            )}
        </QuoteBrandedListSection>
    );
};

// Root Components
const PageSectionQuoteBranded = (props) => {
    switch (props.layout) {
        case "list":
            return <QuoteBrandedList {...props} />;
        case "carousel":
            return <QuoteBrandedCarousel {...props} />;
    }
};
export const PageSectionQuoteBrandedPropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["list", "carousel"]),
    panels: PropTypes.arrayOf(PropTypes.shape(QuoteBrandedPropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionQuoteBranded.defaultProps = {
    dataQa: "qa",
    layout: "list"
};
PageSectionQuoteBranded.propTypes = PageSectionQuoteBrandedPropTypes;
export default PageSectionQuoteBranded;

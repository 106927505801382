import React from "react";
import GutterWrapper from "../../component-styles/GutterWrapper";
import styled from "styled-components";
import { sectionStones } from "@resourcehub/resourcehub-assets";

const StoneContainer = styled.div`
    position: relative;
`;

const StoneBgWrapper = styled.div`
    z-index: -5;
    overflow: hidden;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: fit-content;
`;

const YellowStoneLeft = styled.div`
    display: block;
    height: 395px;
    width: 100%;
    background: url(${sectionStones.yellowEdgeStoneSvg}) no-repeat;
    background-size: contain;
`;

const BlueStoneRight = styled.div`
    display: block;
    height: 790px;
    width: 100%;
    background: url(${sectionStones.blueEdgeStoneSvg}) no-repeat 100%;
    background-size: contain;
`;

const PageWrapper = styled.div`
    width: 90%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    color: #262626;
    margin: 0 auto;
    padding-bottom: 70px;

    p {
        color: #262626;
        font-size: 24px;
        margin-bottom: 15px;
        line-height: 32px;
    }

    h1 {
        font-family: helvetica_bold, sans-serif;
        font-size: 112px;
        font-weight: 700;
        color: #f0c433;
        margin: 40px 0 25px;
        text-align: center;

        @media (max-width: 760px) {
            padding-top: 20px;
            font-size: 48px;
            letter-spacing: -1.82px;
            line-height: 48px;
            margin-top: 15px;
            margin-bottom: 5px;
        }
    }

    h2 {
        font-family: helvetica_bold, sans-serif;
        font-weight: 700;
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;
        color: #0098db;
        font-family: effra, sans-serif;
        &:visited {
            color: purple;
        }
    }

    li {
        content: "•";
        padding-right: 7px;
        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }
    }
`;

const PageTemplateConsumerLegalAgreements = (config) => (
    <>
        <StoneContainer>
            <StoneBgWrapper>
                <YellowStoneLeft />
                <BlueStoneRight />
            </StoneBgWrapper>
        </StoneContainer>
        <GutterWrapper>
            <PageWrapper>
                <h1 dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_heading }} />
                <p dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].hero_p_1 }} />
                {config.page.content.sections[0].agreement_sections.map((agreementSection, index) => (
                    <div key={index}>
                        <h2>{agreementSection.title}</h2>
                        <ul>
                            {agreementSection.agreement_links.map(({ title, link }) => (
                                <li key={title}>
                                    <a href={link}>{title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </PageWrapper>
        </GutterWrapper>
    </>
);

PageTemplateConsumerLegalAgreements.propTypes = {};
PageTemplateConsumerLegalAgreements.defaultProps = {};

export default PageTemplateConsumerLegalAgreements;

import React from "react";
import styled from "styled-components";
import { componentServices, resourceServices, verticalServices } from "@resourcehub/resourcehub-services";
import AnchorFallback from "../AnchorFallback/AnchorFallback";
import ButtonStyled from "../Buttons/ButtonStyled";
import * as PropTypes from "prop-types";

const CardStyle = styled(AnchorFallback)`
    label: CardStyle;

    background-color: #ffffff;
    margin: 20px;
    position: relative;
    text-decoration: none !important;
    min-width: 380px;
    max-width: 380px;
    width: 380px;

    @media (max-width: 480px) {
        min-width: 100%;
    }

    :hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0 4px 20px 0;
        top: -1px;
    }
`;
const CardContentDiv = styled.div`
    label: CardContentDiv;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 275px;
`;
const CardImageDiv = styled.div`
    label: CardImageDiv;

    flex: 0;
    max-height: 150px;
    overflow: hidden;
    position: relative;
    text-align: center;

    img {
        overflow: hidden;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
`;
const CardTitleDiv = styled.div`
    label: CardTitleDiv;
    padding: 10px;
    flex: 1;

    @media (max-width: 360px) {
        padding: 10px 30px;
        text-align: center;
    }
`;

const CardDurationSpan = styled.div`
    label: CardDurationSpan;

    background: rgba(0, 0, 0, 0.9);
    border-color: rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    color: rgb(255, 255, 255, 0.9);
    font-size: 14px;
    opacity: 0.9;
    padding: 6px;
    right: 4px;
    top: 4px;
    position: absolute;
`;

const CardActionDiv = styled.div`
    label: CardActionDiv;
    flex: 0;
`;

CardActionDiv.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};
/**
 * This Component uses Gatsby Link for in app navigation.
 * @param {Object} node { node: data: { itemInfo } }
 * @param {Object} copyData { articleTitles}
 * @returns Component
 */
const CardStyled = ({ node, copyData }) => {
    const { url_slug, thumbnail_url, thumbnail_alt_text, asset_type, title, duration } = node.data;

    // If combined verticals (CN), find vertical prefix
    const urlPrefix = verticalServices.getHubPrefixFromVertical(copyData.market);

    const action = componentServices.getAction(copyData, asset_type, duration);

    // Create link using rest of path
    const cardLink = `${urlPrefix}${copyData.resources_link}content/${url_slug}/`;

    return (
        <CardStyle destination={cardLink} useLink>
            <CardContentDiv>
                <CardImageDiv>
                    {duration && <CardDurationSpan>{duration}</CardDurationSpan>}
                    <img src={thumbnail_url} alt={thumbnail_alt_text || title} />
                </CardImageDiv>
                <CardTitleDiv>{resourceServices.resourcesTrimTitle(title)}</CardTitleDiv>
                <CardActionDiv className="card__action">
                    <ButtonStyled destination={cardLink} text={action} size={"mini"} theme={"blue_white"} />
                </CardActionDiv>
            </CardContentDiv>
        </CardStyle>
    );
};

export default CardStyled;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SectionLabel = styled.div`
    label: SectionLabel;

    font-size: 40px;
    font-family: helvetica_bold, sans-serif;
    margin-bottom: 40px;
    position: relative;
    z-index: 4;
`;

const AwardsSection = styled.section`
    label: AwardsSection;

    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: 60px 20px;
    text-align: center;
`;
const LogoWrapperDiv = styled.div`
    label: LogoWrapperDiv;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
`;
const LogoImage = styled.img`
    label: LogoImage;

    width: 200px;
    vertical-align: middle;
    height: 90px;
    margin: 0 20px;
    position: relative;
    z-index: 4;
`;

const AwardsStyled = ({ config }) => (
    <AwardsSection>
        <SectionLabel>{config.title}</SectionLabel>
        <LogoWrapperDiv>
            {config.images.map((c, i) => (
                <div key={`company-logo-${i}`}>
                    <LogoImage src={c.src} alt={c.name} />
                </div>
            ))}
        </LogoWrapperDiv>
    </AwardsSection>
);

AwardsStyled.propTypes = {
    config: PropTypes.shape({
        title: PropTypes.string,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                src: PropTypes.string,
                alt: PropTypes.string,
                destination: PropTypes.string
            })
        )
    }).isRequired
};

export default AwardsStyled;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import NavChatHeader from "./ChatHeader";
import BrandLogo from "./BrandLogo";
import SecondaryNav from "./SecondaryNav";
import zIndex from "../../styles/z-indexes";

import { NAV_TOP_HEIGHT } from "../../styles/style-constants";
import { connect } from "react-redux";
import { cartStore } from "@resourcehub/resourcehub-data";
import CartPanel from "../CartPanel/CartPanel";
import { icons } from "@resourcehub/resourcehub-assets";
import AboutNav from "./AboutNav";

const { cartActions } = cartStore;

const PrimaryNavWrapper = styled.section`
    label: PrimaryNavWrapper;

    align-items: center;
    background-color: #fff;
    border-bottom: 8px solid #c19e2a;
    box-shadow: 0 8px 0 #d8af2f;
    display: flex;
    justify-content: space-between;
    min-height: ${NAV_TOP_HEIGHT};
    position: relative;
    top: 0;
    width: 100%;
    z-index: ${zIndex.nav_primary};
`;

const PrimaryNavLeftyWrapper = styled.nav`
    label: PrimaryNavLeftyWrapper;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
`;

const CartIconContainer = styled.div`
    padding: 23px 11px;
`;

const CartIcon = ({ setIsOverlayVisible, setIsCartVisible }) => (
    <CartIconContainer>
        <a
            onClick={() => {
                setIsOverlayVisible(true);
                setIsCartVisible(true);
            }}
        >
            <img src={icons.iconCartSvg} alt="Cart Icon" />
        </a>
    </CartIconContainer>
);

const NarwhalNav = ({ openDropdown, setOpenDropdown, config, setIsOverlayVisible, setIsCartVisible }) => {
    if (!config) return null;
    return (
        <>
            {config.primary && config.primary.visible && (
                <PrimaryNavWrapper data-qa="main-nav-bar">
                    <PrimaryNavLeftyWrapper>
                        <BrandLogo {...config.primary.rs_logo} />
                    </PrimaryNavLeftyWrapper>
                    <NavChatHeader id="lp-chat-header" />
                    {config.secondary && config.secondary.hasCart && <CartPanel />}
                </PrimaryNavWrapper>
            )}
            {config.secondary && config.secondary.visible && <SecondaryNav config={config.secondary} />}
        </>
    );
};

NarwhalNav.propTypes = {
    config: PropTypes.object.isRequired,
    setOpenDropdown: PropTypes.func.isRequired,
    openDropdown: PropTypes.string
};

const mapStateToProps = () => ({});

export { NarwhalNav };

export default connect(mapStateToProps, { ...cartActions })(NarwhalNav);

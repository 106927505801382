import React from "react";
import PropTypes from "prop-types";
import { verticalType } from "../../component-proptypes";
import DesktopFilter from "./DesktopFilter";
import MobileFilter from "./MobileFilter";

const FilterBar = ({ vertical, copyData }) => {
    const { resources_link: resourcesLink, market, search: placeholder } = copyData;

    return (
        <>
            <MobileFilter vertical={vertical} market={market} resourcesLink={resourcesLink} placeholder={placeholder} />
            <DesktopFilter
                vertical={vertical}
                market={market}
                resourcesLink={resourcesLink}
                placeholder={placeholder}
            />
        </>
    );
};

FilterBar.propTypes = {
    vertical: verticalType.isRequired,
    copyData: PropTypes.shape({
        resources_link: PropTypes.string.isRequired
    }).isRequired
};

export default FilterBar;

import React from "react";
import styled from "styled-components";

const GdprContainer = styled.div`
    font-size: 16px;
    text-align: left;
    color: #333;
    background-color: #f8f6f0;
    padding: 20px;
    margin: 10px auto;
    max-width: 472px;
    border: 1px solid #eaeaea;

    label {
        width: 100%;
    }
`;

const GdprCheckbox = styled.input`
    cursor: pointer;
`;

const GdprMoreInfoLink = styled.span`
    color: #0098db;
    cursor: pointer;
    text-decoration: underline;
`;

const GdprMoreInfoText = styled.div`
    text-align: left;
    font-size: 0.8em;
    margin-top: 10px;

    a {
        color: #0098db;
        text-decoration: underline;
    }
`;

const GdprEmailCheckbox = ({
    toggleEmailInfoText,
    updateGdprEmailConsent,
    displayEmailInfoText,
    gdpr_checkbox_text,
    gdpr_more_info_text,
    gdpr_more_info_link_text
}) => {
    return (
        <>
            <GdprContainer data-qa="gdpr_email_consent_container">
                <label onChange={updateGdprEmailConsent}>
                    <GdprCheckbox data-qa={"gdpr_email_consent_checkbox"} type={"checkbox"} autoComplete={"off"} />
                    {gdpr_checkbox_text}
                </label>
                <GdprMoreInfoLink onClick={toggleEmailInfoText}>{gdpr_more_info_link_text}</GdprMoreInfoLink>
                {displayEmailInfoText && <GdprMoreInfoText dangerouslySetInnerHTML={{ __html: gdpr_more_info_text }} />}
            </GdprContainer>
        </>
    );
};

export default GdprEmailCheckbox;

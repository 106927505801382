import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { arrayServices } from "@resourcehub/resourcehub-services";
import { NavLinkStyled } from "./PrimaryNavLink";
import { CONTENT_TOP } from "../../styles/style-constants";

const LanguagesAndPricingWrapperDiv = styled.div`
    label: LanguagesAndPricingWrapperDiv;
`;
const LanguagesDropdownDiv = styled.div`
    label: LanguagesDropdownDiv;

    background: #0098db;
    display: ${(props) => (props.isOpen ? "inline-block" : "none")};
    font-family: effra, sans-serif;
    margin: 0 auto;
    padding: 30px;
    position: absolute;
    right: 200px;
    top: ${CONTENT_TOP};
    transform-origin: center top;
    transition: all 0.3s;
    z-index: 100;
`;
const LanguagesTitleDiv = styled.div`
    label: LanguagesTitleDiv;

    font-size: 30px;
    margin-bottom: 20px;
    color: #fff;
`;
const LanguagesListDiv = styled.div`
    label: LanguagesListDiv;

    display: flex;
    flex-direction: row;
`;
const LanguageListColumnDiv = styled.div`
    label: LanguageListColumnDiv;

    display: flex;
    flex-direction: column;
    margin: 0;
    padding-right: 20px;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #fff;
`;
const LanguagesFooterDiv = styled.div`
    label: LanguagesFooterDiv;

    margin: 20px 0 0;
    padding: 15px 0 0;
    display: inline-block;
    border-top: solid 1px #fff;
    font-size: 16px;
    font-weight: 700;
`;
const LanguageLinkAnchor = styled.a`
    label: LanguageLinkAnchor;

    color: #ffffff;
    display: block;
    font-family: effra_bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    padding: 12px;
    text-align: left;
    text-decoration: none;
    white-space: nowrap;
    :hover {
        text-decoration: none !important;
        background: #ecc200;
        border-radius: 5px;
    }
    small {
        font-family: effra, sans-serif;
        font-size: 16px;
        font-weight: bolder;
    }
`;

class LanguagesPricingDesktop extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dropdownName: "Languages & Pricing"
        };
    }

    componentDidUpdate(prevProps) {
        const { openDropdown } = this.props;
        const { dropdownName } = this.state;
        if (prevProps.openDropdown !== openDropdown) {
            if (openDropdown !== dropdownName) {
                this.setState({
                    isOpen: false
                });
            }
        }
    }

    togglePane = () => {
        const { isOpen } = this.state;
        if (isOpen) {
            this.closePane();
        } else {
            this.openPane();
        }
    };

    openPane = () => {
        const { setOpenDropdown } = this.props;
        const { dropdownName } = this.state;
        this.setState({
            isOpen: true
        });
        setOpenDropdown(dropdownName);
    };

    closePane = () => {
        const { setOpenDropdown } = this.props;
        this.setState({
            isOpen: false
        });
        setOpenDropdown(null);
    };

    render = () => {
        const { config } = this.props;
        const { isOpen } = this.state;
        const {
            for_young_learners,
            homeschool_label,
            homeschool_destination,
            label,
            language_options_featured,
            language_options_other,
            product_title,
            select_language_label
        } = config;
        return (
            <LanguagesAndPricingWrapperDiv>
                <NavLinkStyled data-qa="main-nav-languages-pricing-2020" onClick={this.togglePane}>
                    {label}
                </NavLinkStyled>
                <LanguagesDropdownDiv data-qa="main-nav-languages-pricing-dropdown-2020" isOpen={isOpen}>
                    <LanguagesTitleDiv>{select_language_label}</LanguagesTitleDiv>
                    <LanguagesListDiv>
                        <LanguageListColumnDiv>
                            {language_options_featured.map((language, idx) => {
                                const key = `language-${idx}`;
                                return (
                                    <div key={key}>
                                        <LanguageLinkAnchor
                                            href={language.url}
                                            title={`${language.title} ${language.small}`}
                                            tabIndex="-1"
                                            featured
                                        >
                                            {language.title} {language.small && <small>({language.small})</small>}
                                        </LanguageLinkAnchor>
                                    </div>
                                );
                            })}
                        </LanguageListColumnDiv>
                        {arrayServices
                            .partitionArray(language_options_other, language_options_featured.length)
                            .map((sub, i) => {
                                const columnKey = `${i}_col`;
                                return (
                                    <LanguageListColumnDiv key={columnKey}>
                                        {sub.map((language, idx) => {
                                            const key = `language-${idx}`;
                                            return (
                                                <div key={key}>
                                                    <LanguageLinkAnchor
                                                        href={language.url}
                                                        title={`${language.title} ${language.small}`}
                                                        tabIndex="-1"
                                                    >
                                                        {language.title}{" "}
                                                        {language.small && <small>({language.small})</small>}
                                                    </LanguageLinkAnchor>
                                                </div>
                                            );
                                        })}
                                    </LanguageListColumnDiv>
                                );
                            })}
                    </LanguagesListDiv>
                    {!for_young_learners && homeschool_label && homeschool_destination && (
                        <LanguagesFooterDiv>
                            <LanguageLinkAnchor
                                href={homeschool_destination}
                                title={`${product_title} ${homeschool_label}`}
                                tabIndex="-1"
                            >
                                {product_title} <small>{homeschool_label}</small>
                            </LanguageLinkAnchor>
                        </LanguagesFooterDiv>
                    )}
                </LanguagesDropdownDiv>
            </LanguagesAndPricingWrapperDiv>
        );
    };
}

LanguagesPricingDesktop.propTypes = {
    openDropdown: PropTypes.string,
    setOpenDropdown: PropTypes.func.isRequired,
    config: PropTypes.shape({
        visible: PropTypes.bool,
        external: false,
        new_window: false,

        // Labels
        language_options_featured: PropTypes.PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                title: PropTypes.string,
                small: PropTypes.string
            })
        ).isRequired,
        language_options_other: PropTypes.PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                title: PropTypes.string,
                small: PropTypes.string
            })
        ).isRequired,

        label: PropTypes.string.isRequired,
        select_language_label: PropTypes.string.isRequired,

        // Homeschool
        product_title: PropTypes.string,
        homeschool_label: PropTypes.string,
        homeschool_destination: PropTypes.string
    })
};

LanguagesPricingDesktop.defaultProps = {
    openDropdown: null
};

export default LanguagesPricingDesktop;

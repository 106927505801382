import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import algoliaSearch from "algoliasearch/lite";
import { InstantSearch, Hits, SearchBox, Highlight, Stats } from "react-instantsearch-dom";
import { verticalServices } from "@resourcehub/resourcehub-services";
import algoliaConfig from "../../../../../.secrets/algolia.json";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { icons } from "@resourcehub/resourcehub-assets";

const AisHitsItem = styled(Link)`
    display: flex;
    width: 100%;
    border-radius: 0;
    justify-content: space-between;
    max-height: 70px;
    align-items: center;
    border-top: 1px solid #dbdbdb;
    :hover {
        cursor: pointer;
    }

    @media (max-width: 1140px) {
        max-height: 85px;
    }
`;

const AisHitsItemImage = styled.img`
    width: 22%;
    display: flex;
    max-height: 40px;

    @media (max-width: 480px) {
        display: none;
    }
`;

const SearchBar = styled.div`
    z-index: 5;
    max-height: 41px;
    width: ${({ isOpen }) => (isOpen ? "400px" : "260px")};
    position: relative;
    font-family: effra_medium, sans-serif;
    color: #4a4a4a;
    border-radius: 8px;
    margin-right: 1em;
    font-size: 14px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
    transition: box-shadow 0.1s ease-in-out, width 0.3s;
    background-color: white;
    &:hover {
        box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
    }

    input {
        opacity: 0.7;
        background: url(${icons.iconSearchSvg}) no-repeat 10px !important;
        transition: width 0.3s;
        height: 41px;
        padding: 0 20px 0 40px;
        width: 100%;
        margin: 0;
        z-index: 41;
        border: none;
        &:focus {
            border: none;
            outline: 1px solid #72c6ec;
            box-shadow: 0 0 8px #72c6ec;
        }
    }

    .ais-SearchBox-form * {
        position: relative;
        transition: box-shadow 0.1s ease-in-out, width 0.3s;
        margin: 0 auto 10px;
        font-family: effra, sans-serif;
        background: #fff;
        color: #4a4a4a;
        border-radius: 8px 8px 0 0;
        font-size: 16px;
    }

    .ais-SearchBox-submit,
    .ais-SearchBox-reset {
        display: none;
    }

    a {
        display: flex;
        text-decoration: none;
        cursor: pointer;
        color: inherit;
        padding: 10px 1em;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            padding: 9px calc(1em - 1px);
        }
    }
    a:last-of-type {
        padding: 10px 1em 1em;

        &:focus {
            padding: 9px calc(1em - 1px) calc(1em - 1px);
        }
    }

    @media (max-width: 375px) {
        width: 225px;
    }
`;

const DropdownActiveStyles = css`
    height: ${({ isOpen }) => (isOpen ? "auto" : "inherit")};

    div {
        height: ${({ isOpen }) => (isOpen ? "inherit" : "0px")};
        display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    }
`;

const DropdownBody = styled.div`
    position: absolute;
    background: white;
    border-radius: 0 0 8px 8px;
    width: 100%;
    top: 41px;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 20px 0;
    overflow-y: scroll;
    max-height: 500px;

    ul {
        padding: 0;
        margin: 0;
        li {
            display: flex;
            width: 100%;
            border-top: 1px solid #dbdbdb;
            &:hover {
                background: #eee;
            }
        }
    }

    ${DropdownActiveStyles}
`;

const StyledStats = styled(Stats)`
    position: relative;
    bottom: 0;
    left: 0;
    padding: 0.5em 1em;
    font-size: 10px;
    text-align: right;
    font-family: effra, sans-serif;
    font-weight: 100;
    border-top: 1px solid #dbdbdb;
`;

const StyledHighlight = styled(Highlight)`
    margin: 0;
    padding: 0 1em;
    width: 78%;
    font-size: 15px;
    line-height: 16px;
    text-align: left;

    .ais-Highlight-highlighted {
        background-color: rgba(235, 194, 29, 0.6);
        height: 14px;
    }

    @media (max-width: 430px) {
        font-size: 14px;
    }

    @media (max-width: 380px) {
        font-size: 12px;
        width: 100%;
        padding: 0;
    }
`;

const Search = ({ resourcesLink, market, vertical, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchClient, setSearchClient] = useState(null);
    const searchBar = useRef(null);

    useEffect(() => {
        setSearchClient(algoliaSearch(algoliaConfig.appId, algoliaConfig.apiKey));
        document.addEventListener("keyup", escPressed, false);
        document.addEventListener("mousedown", handleClickOutside, false);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
            document.removeEventListener("keyup", escPressed, false);
        };
    }, []);

    const escPressed = (event) => {
        if (event.keyCode === 27) {
            setIsOpen(false);
        }
    };

    const handleClickOpen = () => setIsOpen(true);

    const handleClickClose = () => setIsOpen(false);

    const handleClickOutside = (event) => {
        if (searchBar.current.contains(event.target)) {
            return;
        }
        handleClickClose();
    };

    const Hit = ({ hit }) => {
        const { thumbnail_url, title, url_slug } = hit.data;
        const verticalPrefix = verticalServices.getHubPrefixFromVertical(market);
        const hitLink = `${verticalPrefix}${resourcesLink}content/${url_slug}`;
        if (hit.data.title === "null for no_index no_follow") {
            return null;
        }
        return (
            <AisHitsItem to={hitLink}>
                <AisHitsItemImage src={thumbnail_url} alt={title} />
                <StyledHighlight attribute="data.title" hit={hit} />
            </AisHitsItem>
        );
    };

    if (!searchClient) return null;

    const indexName = `${market.toString().toLowerCase()}-${vertical}`;

    return (
        <SearchBar
            onClick={() => handleClickOpen()}
            onKeyPress={() => handleClickOpen()}
            ref={searchBar}
            role="button"
            isOpen={isOpen}
        >
            <InstantSearch searchClient={searchClient} indexName={indexName}>
                <SearchBox translations={{ placeholder }} isOpen={isOpen} />
                <DropdownBody isOpen={isOpen}>
                    <Hits hitComponent={Hit} resourcesLink={resourcesLink} market={market} isOpen={isOpen} />
                    <StyledStats isOpen={isOpen} />
                </DropdownBody>
            </InstantSearch>
        </SearchBar>
    );
};

export const SearchPropTypes = {
    market: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    resourcesLink: PropTypes.string.isRequired,
    vertical: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
};

Search.propTypes = SearchPropTypes;

export default Search;

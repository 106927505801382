import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { images } from "@resourcehub/resourcehub-assets";
import { verticalServices } from "@resourcehub/resourcehub-services";
import CardStyled from "../Cards/CardStyled";

const CardsContainer = styled.div`
    label: CardsContainer;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2em 1em;
`;

const RelatedTitle = styled.h2`
    label: RelatedTitle;

    color: #262626;
    font-family: helvetica_bold, sans-serif;
    font-size: 40px;
    letter-spacing: 0.2px;
    line-height: 48px;
`;

const RelatedContentWrapper = styled.div`
    label: RelatedContentWrapper;

    ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : "background-color: auto;")}
`;

const random = (range) => Math.floor(Math.random() * range);

/**
 * @param {boolean} usesStandardCard
 * @param {*} edge
 * @param {*} copyData
 * @param {*} siteMetadata
 */
const createCards = (usesStandardCard, edge, copyData, siteMetadata) => (
    <CardStyled copyData={copyData} key={edge.node.id} node={edge.node} siteMetadata={siteMetadata} />
);

const determineRelated = (items, copyData, siteMetadata) => {
    const len = items.length;
    let related = "";
    // Business, Services, and China should use normal 'Standard' Card
    const usesStandardCard =
        siteMetadata.vertical === "business" ||
        siteMetadata.vertical === "services" ||
        siteMetadata.domain === ".com" ||
        verticalServices.isCombinedVertical(copyData.market);
    if (len >= 4) {
        const rand = random(len - 4);
        related = items
            .slice(rand, rand + 4)
            .map((edge) => createCards(usesStandardCard, edge, copyData, siteMetadata));
    } else if (items && items.length > 0) {
        related = items.map((edge) => createCards(usesStandardCard, edge, copyData, siteMetadata));
    }
    return related;
};

const RelatedCardsStyled = ({ items, title = "Similar Resources", showBorders, copyData, siteMetadata, bgColor }) => {
    if (!items || (Array.isArray(items) && items.length < 1)) return <div />;
    const relatedCards = determineRelated(items, copyData, siteMetadata);
    return (
        <RelatedContentWrapper bgColor={bgColor}>
            {showBorders && <img src={images.relatedTopSvg} alt="section border" />}
            <RelatedTitle>{title}</RelatedTitle>
            <CardsContainer>{relatedCards}</CardsContainer>
            {showBorders && <img src={images.relatedBottomSvg} alt="section border" />}
        </RelatedContentWrapper>
    );
};

RelatedCardsStyled.propTypes = {
    copyData: PropTypes.shape({
        read_article: PropTypes.string.isRequired,
        read_case_study: PropTypes.string.isRequired,
        read_ebook: PropTypes.string.isRequired,
        read_fact_sheet: PropTypes.string.isRequired,
        read_guide: PropTypes.string.isRequired,
        view_infographic: PropTypes.string.isRequired,
        listen_now: PropTypes.string.isRequired,
        read_report: PropTypes.string.isRequired,
        watch_video: PropTypes.string.isRequired,
        watch_webinar: PropTypes.string.isRequired,
        read_white_paper: PropTypes.string.isRequired
    }).isRequired,
    siteMetadata: PropTypes.shape({
        vertical: PropTypes.string.isRequired,
        domain: PropTypes.string.isRequired,
        businessLocalized: PropTypes.string.isRequired
    }).isRequired
};

export default RelatedCardsStyled;

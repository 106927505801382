import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Link from "../Links/Link";
import { GatsbyImage } from "gatsby-plugin-image";

const ImageWrapperDiv = styled.div`
    label: ImageWrapperDiv;

    align-items: ${({ alignItems }) => alignItems};
    display: flex;
    flex-direction: column;
`;

const Image = ({ alt, dataQa, align_items, destination, object_fit, new_window, className, image, isSeoImage }) => {
    const withLink = destination && destination.length >= 1;
    const imageHeight = image.height ? image.height : "100%";
    const imageWidth = image.width ? image.width : "100%";

    if (!image) return null;

    if (image.type === "static" && isSeoImage) {
        return (
            <div style={{ overflow: "hidden" }} data-qa={dataQa}>
                <img
                    src={image.src}
                    alt={alt}
                    style={{ height: imageHeight, width: imageWidth, objectFit: "contain" }}
                />
            </div>
        );
    }

    if (image.type === "static") {
        return (
            <ImageWrapperDiv alignItems={align_items}>
                <div style={{ overflow: "hidden" }} data-qa={dataQa}>
                    <img
                        src={image.src}
                        alt={alt}
                        style={{ height: imageHeight, width: imageWidth, objectFit: "contain" }}
                    />
                </div>
            </ImageWrapperDiv>
        );
    }

    if (withLink) {
        return (
            <ImageWrapperDiv data-qa={dataQa} alignItems={align_items} className={className}>
                <Link destination={destination} new_window={new_window}>
                    <GatsbyImage image={image.src} alt={alt} objectFit={object_fit} />
                </Link>
            </ImageWrapperDiv>
        );
    }

    return (
        <ImageWrapperDiv data-qa={dataQa} alignItems={align_items}>
            <GatsbyImage image={image.src} alt={alt} title={alt} objectFit={object_fit} />
        </ImageWrapperDiv>
    );
};

export const ImagePropTypes = {
    alt: PropTypes.string,
    height: PropTypes.string,
    object_fit: PropTypes.string,
    width: PropTypes.string,
    align_items: PropTypes.string,
    isSeoImage: PropTypes.bool
};

Image.propTypes = ImagePropTypes;
Image.defaultProps = { object_fit: "contain", align_items: "center", img_type: "gatsby_dynamic", isSeoImage: false };
export default Image;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";

const FormFieldInputWrapperDiv = styled.div`
    label: FormFieldInputWrapperDiv;

    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 5px;
`;

const FormFieldLabelStyled = styled.label`
    label: FormFieldLabelStyled;

    background-color: inherit;
    color: #ffffff;
    left: 5px;
    padding-left: 5px;
    position: relative;
    transition: all 0.1s ease-in-out;
    z-index: 1000;

    ${({ hasFocus, value }) => {
        if (hasFocus || value) {
            return `
                top: 0px;
                font-size: 0.75em;
                color: #ffffff;
            `;
        }
        return `
                top: 30px;
                font-size: 0.75em;
                color: #777777;
            `;
    }}
`;
const FormFieldInputStyled = styled.input`
    label: FormFieldInputStyled;

    background-color: #fff;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    color: #262626;
    font-family: effra, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 42px;
    letter-spacing: 0.12px;
    line-height: 19px;
    outline: none;
    padding-left: 16px;
    width: 100%;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        min-width: 100%;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        min-width: 100%;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        min-width: 375px;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        min-width: 375px;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        min-width: 375px;
    }

    ${({ hasFocus, isValid, value }) => {
        if (hasFocus || value) {
            return "border-color: #979797;";
        }
        return isValid ? "border-color: #ff0000;" : "border-color: #979797;";
    }}
`;

const FormFieldInput = (props) => {
    const ref = useRef();
    const [fieldValue, setFieldValue] = useState(props.value || "");
    const [isValid, setIsValid] = useState(false);
    const [hasFocus, setFocus] = useState(false);
    const { children, dataQa, label, field, height, width } = props;
    const { id, name, placeholder, required, type } = field;

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);

    const handleChange = (event) => {
        const { id, validator, sendValue } = props;
        const { value } = event.target;

        if (validator) {
            const isValid = validator(value);
            setIsValid(isValid);
            setFieldValue(value);
            if (isValid && sendValue) {
                sendValue && sendValue({ field: id, value });
            }
        } else {
            setFieldValue(value);
            sendValue && sendValue({ field: id, value });
        }
    };
    return (
        <FormFieldInputWrapperDiv data-qa={`FormFieldInput-${dataQa}`} height={height} width={width}>
            <FormFieldLabelStyled htmlFor={name} hasFocus={hasFocus} value={fieldValue}>
                {label.content}
            </FormFieldLabelStyled>
            <FormFieldInputStyled
                hasFocus={hasFocus}
                id={id}
                isValid={isValid}
                name={name}
                onBlur={() => setFocus(false)}
                onChange={handleChange}
                onFocus={() => setFocus(true)}
                placeholder={placeholder}
                ref={ref}
                required={required}
                type={type}
                value={fieldValue}
            />
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </FormFieldInputWrapperDiv>
    );
};

export const FormFieldInputPropTypes = {
    dataQa: PropTypes.string,
    height: PropTypes.string,
    sendValue: PropTypes.func,
    validator: PropTypes.func,
    width: PropTypes.string,
    field: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        type: PropTypes.oneOf(["text", "email", "tel"]),
        value: PropTypes.string
    }),
    label: PropTypes.shape({
        content: PropTypes.string,
        position: PropTypes.oneOf(["hover", "left", "top", "right", "none"])
    })
};
FormFieldInput.propTypes = FormFieldInputPropTypes;
FormFieldInput.defaultProps = {
    field: {
        required: false
    },
    label: {
        position: "none"
    },
    validate: true
};
export default FormFieldInput;

import PropTypes from "prop-types";

export const verticalType = PropTypes.oneOf([
    "k12",
    "education-primary",
    "business",
    "enterprise",
    "hed",
    "education-secondary",
    "services",
    "publicsector",
    "pubsec"
]);

export const fieldTypes = {
    error: PropTypes.bool.isRequired,
    data: PropTypes.string.isRequired,
    handleInputChange: PropTypes.func.isRequired
};

export const domainType = PropTypes.oneOf([".com", ".de", ".co.uk", ".fr", ".es", ".espanol", ".br", ".it", ".cn"]);

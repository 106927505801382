/*
 * This is needed because we have filenames as properties in airtable.
 * This prevents us from using gatsby image loading or type checking.
 * Having this selector allows the build process to detect invalid image names and properties entered in airtable.
 * This can be removed when we get rid of the need for Pages-Home in Airtable.
 * */

import icon_24Svg from "../icons/icon-24.svg";
import iconAssessmentsSvg from "../icons/icon-assessments.svg";
import iconBookPng from "../icons/icon-book.png";
import iconBriefcasePng from "../icons/icon-briefcase.png";
import iconDynamicSvg from "../icons/icon-dynamic.svg";
import iconFoundationalSvg from "../icons/icon-foundational.svg";
import iconGamesSvg from "../icons/icon-games.svg";
import iconGlobePng from "../icons/icon-globe.png";
import iconHeartPng from "../icons/icon-heart.png";
import iconImplementationSvg from "../icons/icon-implementation.svg";
import iconMobileSvg from "../icons/icon-mobile.svg";
import iconPlacementSvg from "../icons/icon-placement.svg";
import iconProficiencySvg from "../icons/icon-proficiency.svg";
import iconReadingSvg from "../icons/icon-reading.svg";
import iconReportingSvg from "../icons/icon-reporting.svg";
import iconTrainingSvg from "../icons/icon-training.svg";
import iconTransferableSvg from "../icons/icon-transferable.svg";
import iconTruaccentSvg from "../icons/icon-truaccent.svg";
import iconTutoringSvg from "../icons/icon-tutoring.svg";

export default (descriptor) => {
    switch (descriptor) {
        case "icon-24":
        case "icon_24":
            return icon_24Svg;
        case "icon-assessments":
        case "icon_assessments":
            return iconAssessmentsSvg;
        case "icon-book.png":
        case "icon_book.png":
            return iconBookPng;
        case "icon-briefcase.png":
        case "icon_briefcase.png":
            return iconBriefcasePng;
        case "icon-dynamic":
        case "icon_dynamic":
            return iconDynamicSvg;
        case "icon-foundational":
        case "icon_foundational":
            return iconFoundationalSvg;
        case "icon-games":
        case "icon_games":
            return iconGamesSvg;
        case "icon-globe.png":
        case "icon_globe.png":
            return iconGlobePng;
        case "icon-heart.png":
        case "icon_heart.png":
            return iconHeartPng;
        case "icon-implementation":
        case "icon_implementation":
            return iconImplementationSvg;
        case "icon-mobile":
        case "icon_mobile":
            return iconMobileSvg;
        case "icon-placement":
        case "icon_placement":
            return iconPlacementSvg;
        case "icon-proficiency":
        case "icon_proficiency":
            return iconProficiencySvg;
        case "icon-reading":
        case "icon_reading":
            return iconReadingSvg;
        case "icon-reporting":
        case "icon_reporting":
            return iconReportingSvg;
        case "icon-training":
        case "icon_training":
            return iconTrainingSvg;
        case "icon-transferable":
        case "icon_transferable":
            return iconTransferableSvg;
        case "icon-truaccent":
        case "icon_truaccent":
            return iconTruaccentSvg;
        case "icon-tutoring":
        case "icon_tutoring":
            return iconTutoringSvg;
        default:
            console.log(`Missing icon ${descriptor}`);
    }
};

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { images } from "@resourcehub/resourcehub-assets";
import Link, { LinkPropTypes } from "../Links/Link";

const AppStoreRatingStyle = styled.div`
    label: AppStoreRating;

    /* Center slide text vertically */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: effra_bold, sans-serif;
    width: 100%;
    flex-wrap: wrap;
    > div {
        margin-bottom: 10px;
        margin-top: 10px;
    }
`;

const RatingsWrapper = styled.div`
    flex: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > div {
        flex: 0;
        display: flex;
        flex-direction: row;
        > div {
            flex: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
`;
const RatingsNumber = styled.div`
    label: RatingsNumber;

    color: #0098db;
    font-size: 5rem;
    line-height: 0.8em;
    padding-right: 10px;
`;
const RatingsOutOf = styled.div`
    label: RatingsOutOf;
    flex: 0;
    color: #363534;

    font-size: 1.5rem;
    white-space: nowrap;

    font-weight: 700;
    line-height: 23.92px;
    // font-size: 20.8px;
`;
const RatingsTotal = styled.div`
    label: RatingsTotal;

    flex: 0;
    color: #848484;
    white-space: nowrap;

    font-size: 1.2rem;
    font-weight: 700;
    line-height: 22.08px;
    //font-size: 19.2px;
`;
const RatingsAppStore = styled.div`
    label: RatingsAppStore;

    flex: 0;

    color: #848484;
    font-size: 1.3rem;
    font-weight: 700;
    margin-block-end: 0;
    margin-block-start: 20px;
    margin-inline-end: 0;
    margin-inline-start: 0;
    white-space: nowrap;
`;
const AppStoreLink = styled(Link)`
    label: AppStoreLink;

    color: #0098db;
    flex: 0;
    font-family: effra_bold, sans-serif;
    font-size: 1.3rem;
    line-height: normal;
    margin: 0;
    padding-left: 8px;
    text-decoration: none;
    white-space: nowrap;
`;

const GraphsWrapper = styled.div`
    flex: 1;
`;
const GraphWrapper = styled.div`
    label: GraphWrapper;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
const BarWrapper = styled.div`
    label: BarWrapper;
    width: 100%;
    height: 6px;
    padding: 9px 0 9px 4px;
`;
const BarNegative = styled.div`
    label: BarNegative;
    background: #ccc;
    border-radius: 4px;
    height: 6px;
    overflow: auto;
    width: 100%;
`;
const BarPositive = styled.div`
    label: BarPositive;
    background: #0098db;
    height: 6px;
    border-radius: 4px;
    ${({ width }) => `width: ${width}%`}
`;
const StarsWrapper = styled.div`
    label: StarsWrapper;
    width: 225px;
    height: 20px;
    display: flex;
    justify-content: flex-end;
`;
const Star = styled.div`
    label: Star;
    height: 20px;
    width: 20px;
    margin-right: 2px;
    background-size: contain;
    display: flex;
    background-repeat: no-repeat;
    ${({ icon }) => `background-image: url("${icon}");`}
`;

const AppStoreRating = ({ app_store, labels, ratings }) => {
    return (
        <AppStoreRatingStyle>
            <RatingsWrapper>
                <div>
                    <RatingsNumber>{ratings.customerRatings}</RatingsNumber>
                    <div>
                        <RatingsOutOf>{`${labels.out_of} 5`}</RatingsOutOf>
                        <RatingsTotal>
                            <span>{ratings.totalRatings}</span>
                        </RatingsTotal>
                    </div>
                </div>

                <RatingsAppStore>
                    {labels.check_it_out}
                    <AppStoreLink {...app_store} />
                </RatingsAppStore>
            </RatingsWrapper>
            <GraphsWrapper>
                {ratings.graphBars.map((s, graphIndex) => (
                    <GraphWrapper key={`graph-${graphIndex}`}>
                        <StarsWrapper>
                            {Array.from({ length: s.stars }, () => {
                                return <Star icon={images.ratingsStarSvg} {...s} key={`star-${Math.random()}`} />;
                            })}
                            <br />
                        </StarsWrapper>
                        <BarWrapper>
                            <BarNegative>
                                <BarPositive width={s.percentage} />
                            </BarNegative>
                        </BarWrapper>
                    </GraphWrapper>
                ))}
            </GraphsWrapper>
        </AppStoreRatingStyle>
    );
};

export const AppStoreRatingPropTypes = {
    app_store: PropTypes.shape(LinkPropTypes),
    ratings: PropTypes.shape({
        graphBars: PropTypes.arrayOf(
            PropTypes.shape({
                stars: PropTypes.number,
                percentage: PropTypes.number
            })
        ),
        customerRatings: PropTypes.string,
        totalRatings: PropTypes.string
    }).isRequired,
    labels: PropTypes.shape({
        check_it_out: PropTypes.string,
        app_store: PropTypes.string,
        out_of: PropTypes.string,
        ratings: PropTypes.string
    }).isRequired
};
AppStoreRating.propTypes = AppStoreRatingPropTypes;
AppStoreRating.defaultProps = {};
export default AppStoreRating;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const AppDeviceWrapper = styled.div`
    display: ${({ isDesktopView }) => (isDesktopView ? "flex" : "none")};
    flex-direction: column;
    align-items: center;
    margin: 1rem 0 4rem;

    @media (max-width: 1024px) {
        display: ${({ isDesktopView }) => (isDesktopView ? "none" : "flex")};
        margin: 4rem 0;
    }

    @media (max-width: 492px) {
        margin: 3rem 0 4rem;
    }
`;

const AppDeviceIcons = styled.img`
    display: block;
    margin: 20px 0 0;
    width: 50%;
    max-height: 20px;
    z-index: 100;

    @media (max-width: 1024px) {
        display: block;
        width: 100%;
    }
`;

const AppRating = styled.div`
    display: block;
    margin: 14px 0 0;
    font-size: 16px;
    font-family: effra-medium, sans-serif;
    z-index: 100;

    @media (max-width: 1024px) {
        width: 100%;
        max-height: 20px;
        text-align: center;
    }
`;

const AppStars = styled.span`
    color: #0098db;
    display: inline-block;
    margin: 0 4px;
    position: relative;
`;

const AppStarsFilled = styled.span`
    position: absolute;
    left: 0%;
    top: 0%;
    width: 92.5%;
    height: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
`;

const Rating = ({ isDesktopView }) => (
    <AppDeviceWrapper isDesktopView={isDesktopView}>
        <AppDeviceIcons
            src="https://www.rosettastone.com/lp/standard-page-assets/home_assets/2018/device-icons.svg"
            alt="App Device Icon"
        />
        <AppRating>
            Rated
            <AppStars>
                <span>&#9734; &#9734; &#9734; &#9734; &#9734;</span>
                <AppStarsFilled>&#9733; &#9733; &#9733; &#9733; &#9733;</AppStarsFilled>
            </AppStars>
            in the App Store.
        </AppRating>
    </AppDeviceWrapper>
);

export const RatingPropTypes = {
    isDesktopView: PropTypes.bool.isRequired
};

Rating.defaultProps = {
    isDesktopView: false
};

Rating.propTypes = RatingPropTypes;

export default Rating;

import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// import Swiper bundle with all modules installed
import Swiper from "swiper";

// Styles
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import PageSectionWrapper from "../component-styles/PageSectionWrapper";
import ChildContentWrapper from "../component-styles/ChildContentWrapper";

// Components
import CardResource, { CardResourcePropTypes } from "../components/Cards/CardResource";

// List Layout
const CardResourceListLayout = styled.div`
    label: CardResourceListLayout;
`;
const CardResourceListContainer = styled.div`
    label: CardResourceListContainer;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;
const CardResourceList = ({ dataQa, panels, title, children }) => {
    if (!panels) return null;
    return (
        <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
            <CardResourceListLayout>
                {title && <TitleWrapper {...title} />}
                <CardResourceListContainer>
                    {panels.map((panel, index) => (
                        <CardResource key={`card-resource-${index}`} {...panel} />
                    ))}
                </CardResourceListContainer>
            </CardResourceListLayout>
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </PageSectionWrapper>
    );
};

// Carousel Layout
const DesktopCardCarouselWrapper = styled.div`
    label: DesktopCardCarouselWrapper;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        display: none;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        display: none;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        display: none;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        display: none;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        display: none;
    }
    // Laptops (and longer)
    @media (min-width: 1400px) {
        display: block;
    }
`;
const MobileCardCarouselWrapper = styled.div`
    label: MobileCardCarouselWrapper;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        display: block;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        display: block;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        display: block;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        display: block;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        display: block;
    }
    // Laptops (and longer)
    @media (min-width: 1400px) {
        display: none;
    }
`;
const ContentWrapper = styled.div`
    label: ContentWrapper;
    // Taking out for now.  Need to get a static image bar to prod.  Will revisit with carousel.
`;
const CarouselContainer = styled.div`
    label: CarouselContainer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & > div {
        right: unset;
        left: unset;
        margin: 10px;
        position: relative;
        z-index: auto;
    }
`;
const NextButton = styled.div`
    label: NextButton;

    --swiper-theme-color: #a2a2a2;
    height: 500px !important;
    display: ${({ visible }) => (visible ? "block;" : "none;")};
`;
const PrevButton = styled.div`
    label: PrevButton;
    height: 500px !important;
    --swiper-theme-color: #a2a2a2;
    display: ${({ visible }) => (visible ? "block;" : "none;")};
`;
class DesktopCardCarousel extends Component {
    state = {
        activeCard: 1,
        maxCardsBeforePaging: 4
    };

    // Lifecycle Methods
    componentDidMount() {
        const { autoplay, autoplayDelay, panels } = this.props;
        const options = {
            grabCursor: true,
            slidesPerView: "auto",
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        };
        if (autoplay && panels.length > this.state.maxCardsBeforePaging) {
            options.autoplay = {
                delay: autoplayDelay,
                disableOnInteraction: false
            };
        }

        new Swiper(".desktop-swiper-container", options);
    }

    render() {
        const { panels } = this.props;
        const { activeCard } = this.state;
        return (
            <CarouselContainer>
                <PrevButton className="swiper-button-prev" visible={panels.length > this.state.maxCardsBeforePaging} />
                <div className="desktop-swiper-container">
                    <div className="swiper-wrapper">
                        {panels.map((card, index) => (
                            <CardResource
                                active={activeCard === index + 1}
                                className="swiper-slide"
                                description={card.description}
                                image={card.image}
                                key={`card-${index}`}
                                title={card.title}
                            />
                        ))}
                    </div>
                </div>
                <NextButton className="swiper-button-next" visible={panels.length > this.state.maxCardsBeforePaging} />
            </CarouselContainer>
        );
    }
}
class MobileCardCarousel extends Component {
    state = {
        activeCard: 1
    };

    componentDidMount() {
        const options = {
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: "auto"
        };
        if (this.props.autoplay) {
            options.autoplay = {
                delay: this.props.autoplayDelay,
                disableOnInteraction: false
            };
        }

        new Swiper(".mobile-swiper-container", options);
    }

    render() {
        const { cards } = this.props;
        const { activeCard } = this.state;
        return (
            <CarouselContainer>
                <PrevButton className="swiper-button-prev" />
                <div className="mobile-swiper-container">
                    <div className="swiper-wrapper">
                        {cards.map((card, index) => (
                            <CardResource
                                active={activeCard === index + 1}
                                className="swiper-slide"
                                description={card.description}
                                image={card.image}
                                key={`card-${index}`}
                                title={card.title}
                            />
                        ))}
                    </div>
                </div>
                <NextButton className="swiper-button-next" />
            </CarouselContainer>
        );
    }
}

class CardResourceCarousel extends Component {
    componentDidMount() {
        new Swiper(".swiper-container", {
            cssMode: true,
            loop: true,
            centeredSlides: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            },
            mousewheel: true,
            keyboard: true
        });
    }
    render() {
        const { dataQa, panels, title } = this.props;
        return (
            <PageSectionWrapper data-qa={`content-section-header-${dataQa}`}>
                <ContentWrapper>
                    {title && <TitleWrapper {...title} />}
                    <DesktopCardCarouselWrapper>
                        <DesktopCardCarousel panels={panels} />
                    </DesktopCardCarouselWrapper>
                    <MobileCardCarouselWrapper>
                        <MobileCardCarousel panels={panels} />
                    </MobileCardCarouselWrapper>
                </ContentWrapper>
            </PageSectionWrapper>
        );
    }
}

// Root Component
const PageSectionCardResource = (props) => {
    switch (props.layout) {
        case "carousel":
            return <CardResourceCarousel {...props} />;
        case "list":
            return <CardResourceList {...props} />;
    }
};
export const PageSectionCardResourcePropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["carousel", "list"]),

    // Carousel Options
    autoplay: PropTypes.bool,
    autoplayDelay: PropTypes.number,

    panels: PropTypes.arrayOf(PropTypes.shape(CardResourcePropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionCardResource.defaultProps = {
    autoplay: false,
    autoplayDelay: 2500,
    layout: "list"
};
PageSectionCardResource.propTypes = PageSectionCardResourcePropTypes;
export default PageSectionCardResource;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ProductSample, { ProductSamplePropTypes } from "./ProductSample";
import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import BodyWrapper, { BodyWrapperPropTypes } from "../../component-styles/BodyWrapper";

const ProductSampleTextWrapperDiv = styled.div`
    label: ProductSampleTextWrapperDiv;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        //transform: scale(0.75);
        flex-direction: column;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        flex-direction: column;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: column;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        flex-direction: column;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        flex-direction: row;
        ${({ direction }) => (direction === "image-left" ? "flex-direction: row;" : "flex-direction: row-reverse;")}
    }
`;
const TextWrapper = styled.div`
    label: TextWrapper;

    padding: 20px;
    width: 100%;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        text-align: center;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        text-align: center;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        text-align: center;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        text-align: center;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        text-align: left;
    }
`;

const ProductSampleText = ({ body, children, dataQa, direction, panels, title }) => (
    <ProductSampleTextWrapperDiv data-qa={`page-section-header-${dataQa}`} direction={direction}>
        {panels && <ProductSample panels={panels} />}
        <TextWrapper>
            {title && (
                <TitleWrapper
                    {...title}
                    data-qa={title ? `content-section-main-title-${dataQa}` : `content-section-title-${dataQa}`}
                ></TitleWrapper>
            )}
            <BodyWrapper {...body} data-qa={`content-section-body-${dataQa}`} />
            {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
        </TextWrapper>
    </ProductSampleTextWrapperDiv>
);

export const ProductSampleTextPropTypes = {
    dataQa: PropTypes.string,
    body: PropTypes.shape(BodyWrapperPropTypes),
    direction: PropTypes.oneOf(["image-left", "image-right"]),

    ...TitleWrapperPropTypes,
    ...ProductSamplePropTypes
};
ProductSampleText.propTypes = ProductSampleTextPropTypes;
ProductSampleText.defaultProps = {
    direction: "image-left",
    title_type: "section-title"
};
export default ProductSampleText;

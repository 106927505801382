import React from "react";
import styled, { keyframes } from "styled-components";

const LdsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
    height: 100vh;
`;

const Circle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const LdsRing = styled.div`
    display: block;
    position: relative;
    width: 64px;
    height: 64px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 6px;
        border: 6px solid #2497ce;
        border-radius: 50%;
        animation: ${LdsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #2497ce transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
`;

const LoadingCircle = () => (
    <Container>
        <Circle>
            <LdsRing>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </LdsRing>
            <div>Loading...</div>
        </Circle>
    </Container>
);

export default LoadingCircle;

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { leadGenerationStore } from "@resourcehub/resourcehub-data";

import ButtonStyled, { ButtonStyledPropTypes } from "../Buttons/ButtonStyled";
import { Form, Field } from "react-final-form";

import "react-toastify/dist/ReactToastify.css";
import withLocation from "../../hoc/withLocation";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import ExplicitOptIn from "./ExplicitOptIn";
import withGeoLocation from "../../hoc/withGeoLocation";

const { leadGenerationActions, leadGenerationConstants, leadGenerationSelectors } = leadGenerationStore;

const FormWrapper = styled.div`
    background-color: #f5f5f5;
    border-radius: 16px;
    border: 1px solid #f5f5f5;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    max-width: 654px;
    padding: 25px;
    width: 100%;

    > form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        max-width: 100%;
        padding: 25px 5px;
        width: 100%;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        max-width: 100%;
        padding: 25px 5px;
        width: 100%;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        max-width: 654px;
        padding: 25px;
        width: 100%;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        max-width: 654px;
        padding: 25px;
        width: 100%;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        max-width: 654px;
        padding: 25px;
        width: 100%;
    }
`;

const FieldLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;

    > div:nth-of-type(2) {
        width: 100%;
    }
    > div:nth-of-type(3) {
        min-height: 17px;
    }

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        align-items: center;
        flex: 0 0 100%;
        justify-content: center;
        max-width: 95%;
        width: 95%;
        margin-left: 10px;
        margin-right: 10px;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        align-items: center;
        flex: 0 0 100%;
        max-width: 95%;
        width: 95%;
        margin-left: 10px;
        margin-right: 10px;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        align-items: center;
        flex: 0 0 100%;
        max-width: 95%;
        width: 95%;
        margin-left: 10px;
        margin-right: 10px;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        align-items: flex-start;
        flex: 0 0 50%;
        max-width: 43%;
        min-width: 43%;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        align-items: flex-start;
        flex: 0 0 50%;
        max-width: 43%;
        min-width: 43%;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const LabelStyled = styled.label`
    color: #262626;
    font-family: effra, sans-serif;
    font-size: 10pt;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
    ${(props) => {
        if (props["aria-required"]) {
            return `
            :after {
                content:" *";
                color: red;
              }
            `;
        }
    }}
`;

const InputStyled = styled.input`
    border-radius: 6px;
    border: 1px solid #d7d7d7;
    box-sizing: border-box;
    color: #262626;
    display: block;
    font-family: effra, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.12px;
    line-height: 19px;
    padding: 5px 7px;
    width: 100%;

    ${({ error }) => {
        if (error) {
            return `
                border-color: #f53f46;
            `;
        }
    }};
`;

const ButtonLayout = styled.div`
    margin-top: 10px;
    text-align: center;
    width: 100%;
`;

const ErrorStyled = styled.div`
    color: #f53f46;
    font-size: 10pt;
`;

class LeadGenerationForm extends React.Component {
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            if (this.props.submission_status === leadGenerationConstants.submissionStatus.received) {
                const { returnCode } = this.props.response_data;
                switch (returnCode) {
                    case "0":
                        this.handleComplete();
                        break;
                    default:
                        this.handleError();
                }
            }
        }
    };
    onSubmit = async (values) => {
        const { routeLocation, routeSearch, geoLocation, context, source } = this.props;
        // Add additional data to post
        const formData = Object.assign({}, values, geoLocation, routeLocation, routeSearch, context, { source });
        if (localStorage.getItem("gclid")) {
            formData.gclid = JSON.parse(localStorage.getItem("gclid")).value;
        }

        this.props.setLeadGenerationSubmission(formData);
    };
    validate = () => {};
    required = (value) => (value ? undefined : true);
    render = () => {
        const { fields, title, button, labels, dataQaLocation } = this.props;
        return (
            <FormWrapper data-qa={"on-page-request-pricing-section"} className={"google-tag-manager-ene-pricing-form"}>
                <TitleWrapper {...title} />
                <Form
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Field
                                name={fields.first_name.field_name}
                                validate={fields.first_name.required_error && this.required}
                            >
                                {({ input, meta }) => (
                                    <FieldLayout>
                                        <div>
                                            <LabelStyled
                                                aria-required={fields.first_name.required}
                                                data-qa={"page-gate-form-first-name-input-field-label"}
                                            >
                                                {fields.first_name.label}
                                            </LabelStyled>
                                        </div>
                                        <div>
                                            <InputStyled
                                                {...input}
                                                data-qa={"page-gate-form-first-name-input-field"}
                                                type={fields.first_name.field_type}
                                                error={meta.touched && meta.error}
                                            />
                                        </div>
                                        <div>
                                            {meta.touched && meta.error && (
                                                <ErrorStyled>{fields.first_name.required_error}</ErrorStyled>
                                            )}
                                        </div>
                                    </FieldLayout>
                                )}
                            </Field>
                            <Field
                                name={fields.last_name.field_name}
                                validate={fields.last_name.required_error && this.required}
                            >
                                {({ input, meta }) => (
                                    <FieldLayout>
                                        <div>
                                            <LabelStyled
                                                aria-required={fields.last_name.required}
                                                data-qa={"page-gate-form-last-name-input-field-label"}
                                            >
                                                {fields.last_name.label}
                                            </LabelStyled>
                                        </div>
                                        <div>
                                            <InputStyled
                                                {...input}
                                                data-qa={"page-gate-form-last-name-input-field"}
                                                error={meta.touched && meta.error}
                                                type={fields.last_name.field_type}
                                            />
                                        </div>
                                        <div>
                                            {meta.touched && meta.error && (
                                                <ErrorStyled>{fields.last_name.required_error}</ErrorStyled>
                                            )}
                                        </div>
                                    </FieldLayout>
                                )}
                            </Field>
                            <Field
                                name={fields.email.field_name}
                                validate={fields.email.required_error && this.required}
                            >
                                {({ input, meta }) => (
                                    <FieldLayout>
                                        <div>
                                            <LabelStyled
                                                aria-required={fields.email.required}
                                                data-qa={"page-gate-form-email-input-field-label"}
                                            >
                                                {fields.email.label}
                                            </LabelStyled>
                                        </div>
                                        <div>
                                            <InputStyled
                                                {...input}
                                                data-qa={"page-gate-form-email-input-field"}
                                                error={meta.touched && meta.error}
                                                type={fields.email.field_type}
                                                pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$"
                                            />
                                        </div>
                                        <div>
                                            {meta.touched && meta.error && (
                                                <ErrorStyled>{fields.email.required_error}</ErrorStyled>
                                            )}
                                        </div>
                                    </FieldLayout>
                                )}
                            </Field>
                            <Field
                                name={fields.phone.field_name}
                                validate={fields.phone.required_error && this.required}
                            >
                                {({ input, meta }) => (
                                    <FieldLayout>
                                        <div>
                                            <LabelStyled
                                                aria-required={fields.phone.required}
                                                data-qa={"page-gate-form-phone-number-input-field-label"}
                                            >
                                                {fields.phone.label}
                                            </LabelStyled>
                                        </div>
                                        <div>
                                            <InputStyled
                                                {...input}
                                                data-qa={"page-gate-form-phone-number-input-field"}
                                                error={meta.touched && meta.error}
                                                type={fields.phone.field_type}
                                            />
                                        </div>
                                        <div>
                                            {meta.touched && meta.error && (
                                                <ErrorStyled>{fields.phone.required_error}</ErrorStyled>
                                            )}
                                        </div>
                                    </FieldLayout>
                                )}
                            </Field>
                            <Field name={fields.role.field_name} validate={fields.role.required_error && this.required}>
                                {({ input, meta }) => (
                                    <FieldLayout>
                                        <div>
                                            <LabelStyled
                                                aria-required={fields.role.required}
                                                data-qa={"page-gate-form-role-input-field-label"}
                                            >
                                                {fields.role.label}
                                            </LabelStyled>
                                        </div>
                                        <div>
                                            <InputStyled
                                                {...input}
                                                data-qa={"page-gate-form-role-input-field"}
                                                error={meta.touched && meta.error}
                                                type={fields.role.field_type}
                                            />
                                        </div>
                                        <div>
                                            {meta.touched && meta.error && (
                                                <ErrorStyled>{fields.role.required_error}</ErrorStyled>
                                            )}
                                        </div>
                                    </FieldLayout>
                                )}
                            </Field>
                            <Field
                                name={fields.organization_name.field_name}
                                validate={fields.organization_name.required_error && this.required}
                            >
                                {({ input, meta }) => (
                                    <FieldLayout>
                                        <div>
                                            <LabelStyled
                                                aria-required={fields.organization_name.required}
                                                data-qa={"page-gate-form-company-input-field-label"}
                                            >
                                                {fields.organization_name.label}
                                            </LabelStyled>
                                        </div>
                                        <div>
                                            <InputStyled
                                                {...input}
                                                data-qa={"page-gate-form-company-input-field"}
                                                error={meta.touched && meta.error}
                                                type={fields.organization_name.field_type}
                                            />
                                        </div>
                                        <div>
                                            {meta.touched && meta.error && (
                                                <ErrorStyled>{fields.organization_name.required_error}</ErrorStyled>
                                            )}
                                        </div>
                                    </FieldLayout>
                                )}
                            </Field>
                            <Field
                                name={fields.organization_size.field_name}
                                validate={fields.organization_size.required_error && this.required}
                            >
                                {({ input, meta }) => (
                                    <FieldLayout>
                                        <div>
                                            <LabelStyled
                                                aria-required={fields.organization_size.required}
                                                data-qa={"page-gate-form-institution-size-input-field-label"}
                                            >
                                                {fields.organization_size.label}
                                            </LabelStyled>
                                        </div>
                                        <div>
                                            <InputStyled
                                                as={"select"}
                                                {...input}
                                                data-qa={"page-gate-form-institution-size-input-field"}
                                                error={meta.touched && meta.error}
                                                type={fields.organization_size.field_type}
                                            >
                                                {fields.organization_size.options.map((o, i) => (
                                                    <option
                                                        data-qa={`institution-size-option-${i}`}
                                                        key={`option-${i}`}
                                                        value={o.value}
                                                    >
                                                        {o.label}
                                                    </option>
                                                ))}
                                            </InputStyled>
                                        </div>
                                        <div>
                                            {meta.touched && meta.error && (
                                                <ErrorStyled>{fields.organization_size.required_error}</ErrorStyled>
                                            )}
                                        </div>
                                    </FieldLayout>
                                )}
                            </Field>
                            {this.props.explicitOptIn && (
                                <Field initialValue={false} name={"explicitOptIn"} type={"checkbox"}>
                                    {({ input }) => (
                                        <FieldLayout>
                                            <ExplicitOptIn
                                                dataQa={`${dataQaLocation}-gate-form-opt-in-input-field`}
                                                labels={labels}
                                                input={input}
                                            />
                                        </FieldLayout>
                                    )}
                                </Field>
                            )}
                            <ButtonLayout>
                                <ButtonStyled {...button} data-qa={"page-gate-form-submit-button"} />
                            </ButtonLayout>
                        </form>
                    )}
                />
            </FormWrapper>
        );
    };
}

export const LeadGenerationFormPropTypes = {
    dataQa: PropTypes.string,
    title: PropTypes.shape(TitleWrapperPropTypes),
    button: PropTypes.shape(ButtonStyledPropTypes).isRequired,
    fields: PropTypes.object.isRequired,
    explicitOptIn: PropTypes.bool.isRequired
};
LeadGenerationForm.propTypes = LeadGenerationFormPropTypes;
LeadGenerationForm.defaultProps = {
    explicitOptIn: false,
    source: ""
};

// Selectors
const mapStateToProps = (state) => ({
    getFieldValue: (field) => leadGenerationSelectors.getFieldValue(state, field),
    formData: leadGenerationStore.leadGenerationSelectors.getAllData(state)
});

export { LeadGenerationForm };
export default withLocation(withGeoLocation(connect(mapStateToProps, leadGenerationActions)(LeadGenerationForm)));

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PageSectionWrapper, { PageSectionWrapperPropTypes } from "../component-styles/PageSectionWrapper";

const ContentWrapper = styled.div`
    label: ContentWrapper;
    display: flex;
    flex-direction: column;
    align-items: ${({ align }) => align};
`;

const PageSectionEmpty = ({ children, align, wrapper }) => (
    <PageSectionWrapper {...wrapper}>
        <ContentWrapper align={align}>{children}</ContentWrapper>
    </PageSectionWrapper>
);
export const PageSectionEmptyPropTypes = {
    wrapper: PropTypes.shape(PageSectionWrapperPropTypes),
    align: PropTypes.oneOf(["center", "flex-end", "flex-start"])
};
PageSectionEmpty.defaultProps = {
    align: "center"
};
PageSectionEmpty.propTypes = PageSectionEmptyPropTypes;
export default PageSectionEmpty;

import styled from "styled-components";
import zIndex from "../styles/z-indexes";

const OverlayStyled = styled.div`
    label: OverlayDiv;

    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: ${zIndex.overlay_main};
    background-color: rgba(33, 33, 33, 0.7);
    opacity: ${(props) => (props.showOverlay ? 1 : 0)};
    pointer-events: ${(props) => (props.showOverlay ? "auto" : "none")};
    transition: all 0.3s;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        display: none;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        display: none;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        display: block;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        display: block;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        display: block;
    }
`;

export default OverlayStyled;

import React from "react";
import { connect } from "react-redux";
import { componentServices } from "@resourcehub/resourcehub-services";
import ContentBackground from "./ContentBackground";
import { domainType, verticalType } from "../../component-proptypes";
import VideoPlayer from "../../components/Videos/VideoPlayer";
import styled from "styled-components";

const ContentWrapper = styled.div`
    .media__container {
        div {
            @media (max-width: 768px) {
                width: 100%;
            }

            iframe {
                width: 100% !important;
                left: 0;
                padding: 0;
            }
        }
    }
`;

const ContentCard = styled.div`
    width: 98%;
    position: relative;
    margin: 2em auto;
    padding: 20px;
    border-radius: 4px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
    max-width: 1152px;
    z-index: 1;

    h1 {
        font-size: 2.4em;
        padding: 1em;
        text-align: center;
        margin: 0;
        overflow-wrap: normal;

        @media (max-width: 768px) {
            font-size: 1.4em;
        }
    }

    h2 {
        font-size: 2em;
        font-weight: bold;
        padding: 0.5em;
        text-align: center;
    }

    h3,
    h4,
    h5 {
        line-height: 1.4em;
        font-size: 1.4em;
        font-weight: bold;
        padding: 0.6em 0;
    }

    object,
    iframe {
        position: relative;
        width: ~"calc(80vw - 40px)" !important;
        left: ~"calc((-40vw) + 50% + 20px)";
    }

    @media (max-width: 600px) {
        padding: 15px;
    }
    @media (max-width: 470px) {
        padding: 15px 10px;
    }
    @media (max-width: 430px) {
        padding: 0;
    }
`;

const ContentContainer = styled.div`
    font-family: effra, sans-serif;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    @media (min-width: 768px) {
        padding-top: 0;
    }
`;

const ContentFlipbook = styled.object`
    width: ~"calc(80vw - 40px)";
    height: ~"calc(80vw * 1.15)" !important;
    max-height: 90vh;

    @media (min-width: 1440px) {
        width: 1132px;
    }

    @media (min-width: 768px) {
        min-height: 780px;
    }
`;

const FlipbookButtonWrapper = styled.div`
    height: 8vh;
    margin: 0 auto;
    text-align: center;
    padding: 40px 0 30px;
`;

const MediaContainer = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
    padding: 40px 0 30px;
    position: relative;
    margin: 0 auto;

    @media (max-width: 768px) {
        padding: 0;
    }

    > div,
    object {
        width: 100%;
    }

    object {
        height: 92vh;
    }

    iframe {
        padding: 0;
    }
`;

const ContentYoutube = styled(VideoPlayer)`
    text-align: center;
    margin: 0 auto;
    left: 0;
`;

const Content = styled.div`
    margin: 0.5em auto;
    width: 90%;
    font-size: 1.4em;
    line-height: 1.55em;
    text-align: justify;
    p {
        margin: 0.5em auto;
    }

    strong {
        font-weight: bold;
    }

    table {
        padding: 0.8em 0;
        border-collapse: collapse;
    }

    table,
    th,
    td {
        border: 1px solid black;
    }

    ol,
    ul {
        list-style: disc;
        padding-left: 1em;
    }

    img {
        display: block;
        position: relative;
        left: 50%;
        transform: translate(-50%) !important;
        max-width: ~"calc(80vw - 40px)";
        height: auto !important;
    }

    @media (min-width: 1440px) {
        img {
            max-width: 1112px;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0;
        font-size: 1em;
        width: 96%;
        p {
            font-size: 1em;
        }

        ol,
        ul {
            padding-left: 1em;
            font-size: 0.8em;
        }

        img {
            max-width: ~"calc(95vw - 42px)";
        }
    }

    @media (max-width: 425px) {
        img {
            max-width: ~"calc(98vw - 20px)";
        }
    }
`;

const FlipbookButton = styled.a`
    box-sizing: border-box;
    padding: 1em 5em;
    margin: 0;
    font-size: 14px;
    font-family: effra_medium, sans-serif;
    box-shadow: none;
    background-color: #ecc200;
    border-radius: 8px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.1s ease-in-out;
    position: relative;
    justify-content: center;
    letter-spacing: 1.47px;
`;

class ContentTemplate extends React.Component {
    state = {
        isMobile: false
    };

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize = () => {
        this.setState({
            isMobile: document.body.getBoundingClientRect().width <= 768
        });
    };

    renderFlipOrBtn = (content, downloadPDF) => {
        const { isMobile } = this.state;
        if (isMobile) {
            return (
                <FlipbookButtonWrapper>
                    <FlipbookButton href={content.media_url} download title={downloadPDF}>
                        {downloadPDF}
                    </FlipbookButton>
                </FlipbookButtonWrapper>
            );
        }
        return (
            <ContentFlipbook aria-label={content.title} data={content.media_url}>
                <p>
                    Your browser does not support PDFs.{" "}
                    <a href={content.media_url} download title="Download the PDF">
                        Download the PDF
                    </a>
                    .
                </p>
            </ContentFlipbook>
        );
    };

    renderFlipbook = (content, downloadPDF) => {
        const { isMobile } = this.state;
        const containerBackground = isMobile ? "none" : "url(../../static/blue-spinner.gif) center center no-repeat";
        if (content.media_url && !/youtube/.test(content.media_url)) {
            return (
                <>
                    <MediaContainer style={{ background: containerBackground }}>
                        {this.renderFlipOrBtn(content, downloadPDF)}
                    </MediaContainer>
                </>
            );
        }
        return null;
    };

    render() {
        const { item, copyData } = this.props;
        const content = item.data;
        const { asset_type, media_url } = content;
        const isVideo = asset_type === "video" || asset_type === "webinar";
        const isFlipbook = !isVideo && media_url;
        return (
            <ContentWrapper>
                <ContentContainer data-uberflip={content.uberflip_url}>
                    <ContentBackground />
                    <ContentCard>
                        {isVideo && (
                            <MediaContainer>
                                <ContentYoutube controls={true} currentVideoUrl={content.media_url} />
                            </MediaContainer>
                        )}
                        <h1>{content.title}</h1>
                        <Content dangerouslySetInnerHTML={componentServices.createMarkup(content.content)} />
                        {isFlipbook && this.renderFlipbook(content, copyData.download_pdf)}
                    </ContentCard>
                </ContentContainer>
            </ContentWrapper>
        );
    }
}

ContentTemplate.propTypes = {
    domain: domainType.isRequired,
    vertical: verticalType.isRequired
};

const mapStateToProps = (state) => ({
    // TODO: Move these to selectors
    hasSubmitted: state.formSubmitted
});

const ContentTemplateConnected = connect(mapStateToProps, {})(ContentTemplate);

export default ContentTemplateConnected;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import zIndex from "../../styles/z-indexes";
import { Link } from "gatsby";

const AboutNavLinkStyled = styled(Link)`
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    padding: 0 13px;

    &:hover {
        text-decoration: none;
    }
`;

const AboutNavWrapper = styled.div`
    width: 100%;
    margin-top: 8px;
    position: relative;
    z-index: ${zIndex.nav_secondary};
    background: #262626;
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
`;

const AboutNavLink = ({ label, destination }) => <AboutNavLinkStyled to={destination}>{label}</AboutNavLinkStyled>;

const AboutNav = ({ config }) => (
    <AboutNavWrapper data-qa="sub-nav-bar">
        {config.home && config.home.visible && <AboutNavLink dataQa="sub-nav-home-2021" {...config.home} />}
        {config.contact && config.contact.visible && <AboutNavLink dataQa="sub-nav-contact-2021" {...config.contact} />}
        {config.careers && config.careers.visible && <AboutNavLink dataQa="sub-nav-careers-2021" {...config.careers} />}
    </AboutNavWrapper>
);

AboutNav.propTypes = {
    config: PropTypes.object.isRequired
};

export default AboutNav;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ProductUnitCard from "./ProductUnitCard";
import Swiper from "swiper";

const ProductUnitCardsStyled = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
    }
`;

const SwiperContainer = styled.div`
    label: SwiperContainer;
    display: flex;
    padding-bottom: 50px;

    .swiper-slide {
        text-align: center;
        font-size: 24px;
        width: 23.6% !important;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 680px) {
            width: 31% !important;
        }
        @media (max-width: 480px) {
            width: 48% !important;
        }
        &:nth-child(0) {
            max-width: 100%;
            max-height: 300px;
            width: 100%;
            height: 100%;
        }

        img {
            width: 75% !important;
            height: 75% !important;
        }
    }
`;
const SwiperWrapper = styled.div`
    label: SwiperWrapper;
`;

const SwiperPagination = styled.div`
    label: SwiperPagination;
`;

const ProductUnitCards = ({ panels }) => {
    React.useEffect(() => {
        new Swiper(".swiper-container", {
            breakpoints: {
                680: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 20
                },
                480: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 20
                },
                380: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 20
                }
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            }
        });
    }, []);

    return (
        <ProductUnitCardsStyled>
            <SwiperContainer className="swiper-container">
                <SwiperWrapper className="swiper-wrapper">
                    {panels.map((card, i) => (
                        <div key={`card-resource-${i}`} className="swiper-slide">
                            <ProductUnitCard {...card} />
                        </div>
                    ))}
                </SwiperWrapper>
                <SwiperPagination className="swiper-pagination" />
            </SwiperContainer>
        </ProductUnitCardsStyled>
    );
};

export const ProductUnitCardsPropTypes = {
    // TODO: Add prop types
    panels: PropTypes.array.isRequired
};
ProductUnitCards.propTypes = ProductUnitCardsPropTypes;
ProductUnitCards.defaultProps = {};
export default ProductUnitCards;

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { InlineWidget } from "react-calendly";
import { leadGenerationStore } from "@resourcehub/resourcehub-data";
import { images } from "@resourcehub/resourcehub-assets";

import "react-toastify/dist/ReactToastify.css";
import Image from "../Images/Image";

const { leadGenerationActions } = leadGenerationStore;

const thankYouMultilanguagePng = {
    src: images.thankYouMultilanguagePng,
    type: "static"
};

const LayoutDiv = styled.div`
    label: LayoutDiv;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;
const Calendly = styled.div`
    label: Calendly;
    display: block;
    flex: 0;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        .calendly-inline-widget {
            width: 100vw;
            height: 1110px !important;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        .calendly-inline-widget {
            width: 100vw;
            height: 1110px !important;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .calendly-inline-widget {
            width: 100vw;
            height: 1110px !important;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        .calendly-inline-widget {
            width: 60vw;
            height: 700px !important;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        .calendly-inline-widget {
            width: 60vw;
            flex: 0;
        }
    }
`;
const ThankYouImageContainer = styled.div`
    label: ThankYouImageContainer;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        width: 50%;
        padding: 40px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        width: 50%;
        padding: 40px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        width: 60%;
        padding: 60px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        width: 100%;
        padding: 80px;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        width: 100%;
        padding: 100px;
    }
`;

class LeadGenerationSuccess extends React.Component {
    render = () => {
        return (
            <LayoutDiv>
                <ThankYouImageContainer>
                    <Image image={thankYouMultilanguagePng} />
                </ThankYouImageContainer>
                <Calendly>
                    <InlineWidget
                        url="https://calendly.com/rosetta-stone-sales/quick-call?embed_domain=www.rosettastone.com&embed_type=Inline"
                        prefill={
                            {
                                // email: receivedData.email,
                                // firstName: receivedData.first_name,
                                // lastName: receivedData.last_name,
                                // customAnswers: {
                                //     a1: receivedData.phone_number
                                // }
                            }
                        }
                    />
                </Calendly>
            </LayoutDiv>
        );
    };
}

export const LeadGenerationSuccessPropTypes = {
    dataQa: PropTypes.string
};
LeadGenerationSuccess.propTypes = LeadGenerationSuccessPropTypes;
LeadGenerationSuccess.defaultProps = {};

// Selectors
const mapStateToProps = () => ({
    // response_data: leadGenerationSelectors.getResponseData(state),
    // submission_status: leadGenerationSelectors.getSubmissionStatus(state),
    // submission_data: leadGenerationSelectors.getResponseData(state)
});

export { LeadGenerationSuccess };
export default connect(mapStateToProps, leadGenerationActions)(LeadGenerationSuccess);

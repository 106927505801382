import React from "react";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import styled from "styled-components";

const PlayerWrapper = styled.div`
    //border: red solid 1px;
    padding-top: 56.25%; // Percentage ratio for 16:9
    box-shadow: ${({ boxShadow }) => `5px 10px 25px ${boxShadow}`};
    display: flex;
    position: relative; // Set to relative
    min-height: 100%;
    min-width: 320px;
    height: auto;
    width: auto;
`;

const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;

    video {
        &:focus {
            outline: none;
        }
    }
`;

const VideoPlayer = ({ currentVideoUrl, width, height, boxShadow, ...props }) => (
    <PlayerWrapper boxShadow={boxShadow} width={width} height={height}>
        <StyledReactPlayer {...props} url={currentVideoUrl} width="100%" height="100%" />
    </PlayerWrapper>
);

VideoPlayer.defaultProps = {
    boxShadow: "#eee",
    width: "100%",
    height: "100%"
};

export const VideoPlayerPropTypes = {
    currentVideoUrl: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    boxShadow: PropTypes.string
};

VideoPlayer.propTypes = VideoPlayerPropTypes;

export default VideoPlayer;

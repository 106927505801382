import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import PrimaryNavLink from "./PrimaryNavLink";
import { CONTENT_TOP } from "../../styles/style-constants";

const SearchWrapperDiv = styled.div`
    label: SearchWrapperDiv;
`;
const SearchOpenImage = styled.img`
    label: SearchOpenImage;

    width: 20px;
    margin: -5px 0;
    transition: all 0.3s;
    display: ${(props) => (props.isOpen ? "none" : "inline-block")};
`;
const SearchCloseImage = styled.img`
    label: SearchCloseImage;

    width: 20px;
    margin: -5px 0;
    transition: all 0.3s;
    display: ${(props) => (props.isOpen ? "inline-block" : "none")};
`;
const SearchPanelDiv = styled.div`
    label: SearchPanelDiv;

    position: absolute;
    width: 370px;
    right: 100px;
    background: #ffffff;
    display: ${(props) => (props.isOpen ? "inline-block" : "none")};

    margin: 0 auto;
    top: ${CONTENT_TOP};
    transform-origin: center top;
    transition: all 0.3s;
`;
const SearchForm = styled.form`
    label: SearchForm;

    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: effra, sans-serif;
`;
const SearchTextbox = styled.input`
    label: SearchTextbox;

    font-family: effra, sans-serif;
    font-size: 16px;
    border-style: solid;
    border-color: darkslategrey;
    border-width: 1px;
    box-sizing: border-box;
    padding: 30px 70px 30px 30px;
    position: relative;
    width: 100%;

    // TODO Pull this icon local.
    background-image: url("/lp/common-modules/assets/modules_2018/search.svg");
    background-repeat: no-repeat;
    background-position: center right 30px;
`;

class Search extends React.PureComponent {
    state = {
        isOpen: false,
        dropdownName: "Search"
    };

    componentDidUpdate(prevProps) {
        const { openDropdown } = this.props;
        const { dropdownName } = this.state;
        if (prevProps.openDropdown !== openDropdown) {
            if (openDropdown !== dropdownName) {
                this.setState({
                    isOpen: false
                });
            }
        }
    }

    openPane = () => {
        const { setOpenDropdown } = this.props;
        const { dropdownName } = this.state;
        this.setState({
            isOpen: true
        });
        setOpenDropdown(dropdownName);
    };

    closePane = () => {
        const { setOpenDropdown } = this.props;
        this.setState({
            isOpen: false
        });
        setOpenDropdown(null);
    };

    render() {
        const { openDropdown, searchLink, searchPlaceholder } = this.props;
        const { isOpen } = this.state;
        const searchShown = openDropdown === "search";
        return (
            <SearchWrapperDiv>
                <PrimaryNavLink>
                    <SearchOpenImage
                        data-qa="main-nav-search-open-icon-2020"
                        src="https://www.rosettastone.com/lp/home_2018/assets/search.svg"
                        alt="Search"
                        searchShown={searchShown}
                        isOpen={isOpen}
                        onClick={this.openPane}
                    />
                    <SearchCloseImage
                        data-qa="main-nav-search-close-icon-2020"
                        src="https://www.rosettastone.com/lp/home_2018/assets/x_thick.svg"
                        alt="Exit Nav"
                        searchShown={searchShown}
                        isOpen={isOpen}
                        onClick={this.closePane}
                    />
                </PrimaryNavLink>
                <SearchPanelDiv isOpen={isOpen}>
                    <SearchForm
                        data-qa="main-nav-search-form-2020"
                        action={searchLink}
                        autoComplete="off"
                        method="get"
                        name="search_form"
                    >
                        <SearchTextbox type="text" name="q" placeholder={searchPlaceholder} />
                    </SearchForm>
                </SearchPanelDiv>
            </SearchWrapperDiv>
        );
    }
}

Search.propTypes = {
    // searchLinkLabel: PropTypes.string.isRequired,
    // searchLink: PropTypes.string.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    setOpenDropdown: PropTypes.func.isRequired,
    openDropdown: PropTypes.string
};

Search.defaultProps = {
    openDropdown: null
};

export default Search;

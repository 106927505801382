import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image, { ImagePropTypes } from "../Images/Image";

const FeatureIconContent = styled.div`
    label: FeatureIconContent;

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    font-family: effra, sans-serif;

    > div:nth-of-type(1) {
        // Icon
    }
    > div:nth-of-type(2) {
        // Feature
        font-family: effra_medium, sans-serif;
        ${({ align }) => {
            switch (align) {
                case "left":
                    return `
                        text-align: left;
                        padding-left: 15px;
                    `;
                case "center":
                    return "justify-content: center";
                case "right":
                    return "justify-content: flex-end";
            }
        }};
        > div:nth-of-type(1) {
            // Feature Title
            font-size: 18pt;
        }
        > div:nth-of-type(2) {
            // Feature Description
            font-size: 11pt;
            padding-top: 4px;
        }
    }
`;
const FeatureIcon = ({ align, children, dataQa, description, className, icon, label, layout }) => (
    <FeatureIconContent data-qa={dataQa} layout={layout} className={className} align={align}>
        <Image image={icon} height={"32px"} width={"32px"} />
        <div>
            <div>{label}</div>
            <div>{description}</div>
        </div>
        {children}
    </FeatureIconContent>
);

export const FeatureIconPropTypes = {
    dataQa: PropTypes.string,
    icon: PropTypes.shape(ImagePropTypes).isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    layout: PropTypes.oneOf(["icon_left"]),
    align: PropTypes.oneOf(["left", "center", "right"])
};
FeatureIcon.propTypes = FeatureIconPropTypes;
FeatureIcon.defaultProps = {
    layout: "icon_left",
    align: "left"
};
export default FeatureIcon;

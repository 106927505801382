import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const PanelWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4.5rem;

    @media (max-width: 760px) {
        margin: 0;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
`;

const Panel = styled.div`
    flex: 0.3;

    @media (max-width: 760px) {
        width: 80%;
        margin-bottom: 2rem;
    }
`;

const MobileViewImgLink = styled.a`
    display: none;

    @media (max-width: 760px) {
        width: 100%;
        display: block;
    } ;
`;

const ThumbnailStyles = styled.img`
    width: 100%;
    filter: ${({ isActiveVideo }) => (!isActiveVideo ? "brightness(0.5)" : "none")};
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        filter: brightness(1);
    }
`;

const VideoThumbnail = styled(ThumbnailStyles)`
    display: block;
    margin: 0;
    box-shadow: ${(props) => `5px 10px 25px ${props.boxShadow}`};

    @media (max-width: 760px) {
        display: none;
    }
`;

const MobileVideoThumbnail = styled.img`
    display: none;
    box-shadow: ${(props) => `5px 10px 25px ${props.boxShadow}`};

    @media (max-width: 760px) {
        width: 100%;
        display: block;
    }
`;

const VideoThumbnails = ({ panels, setCurrentVideoUrl, currentVideoUrl, boxShadow }) => (
    <PanelWrapper>
        {panels &&
            panels.map(({ video_url, asset_url }, index) => {
                const isActiveVideo = currentVideoUrl === video_url;

                return (
                    <Panel key={index}>
                        <MobileViewImgLink href={video_url} target="_blank">
                            <MobileVideoThumbnail src={asset_url} boxShadow={boxShadow} />
                        </MobileViewImgLink>
                        <VideoThumbnail
                            src={asset_url}
                            onClick={() => setCurrentVideoUrl(video_url)}
                            boxShadow={boxShadow}
                            isActiveVideo={isActiveVideo}
                        />
                    </Panel>
                );
            })}
    </PanelWrapper>
);

const VideoThumbnailsPropTypes = {
    panels: PropTypes.arrayOf(
        PropTypes.shape({
            asset_url: PropTypes.string.isRequired,
            video_url: PropTypes.string.isRequired
        })
    ).isRequired,
    setCurrentVideoUrl: PropTypes.func.isRequired,
    boxShadow: PropTypes.string
};

VideoThumbnails.propTypes = VideoThumbnailsPropTypes;

export default VideoThumbnails;

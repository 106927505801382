import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const SubLevelLabelSection = styled.div`
    position: relative;
    background: #ececec;
    padding: 1em;
    font-size: 12px;
    text-align: left;
    color: #4a4a4a;
`;

const SubLevelMobileLink = styled(Link)`
    border-top: 1px solid #dbdbdb;
    text-align: left;
    padding: 1em 1.4em;
    opacity: 0.7;
    font-size: 0.8em;
    background: #ececec;
    color: #4a4a4a;
    text-decoration: none;
    cursor: pointer;
    display: block;
    ${({ isOpenSubSection }) =>
        !isOpenSubSection &&
        `
        padding: 0;
        margin: 0;
        height: 0;
        width: 0;
    `};
    font-family: effra_medium, sans-serif;

    &:first-child {
        border-top: 0;
    }

    @media (max-width: 375px) {
        font-size: 0.45em;
        padding: 1em;
    }
`;

const SubLevels = ({ sublevels, resourcesLink, isOpenSubSection }) => {
    const subLinks = sublevels.map(({ url_slug, title }) => {
        if (!url_slug) {
            return <SubLevelLabelSection key={title}>{title}</SubLevelLabelSection>;
        }

        return (
            <SubLevelMobileLink key={url_slug} to={`${resourcesLink}${url_slug}/`} isOpenSubSection={isOpenSubSection}>
                {title}
            </SubLevelMobileLink>
        );
    });
    return <>{subLinks}</>;
};

export const SubLevelsPropTypes = {
    sublevels: PropTypes.arrayOf(
        PropTypes.shape({
            url_slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    resourcesLink: PropTypes.string.isRequired,
    isOpenSubSection: PropTypes.bool.isRequired
};

SubLevels.propTypes = SubLevelsPropTypes;

export default SubLevels;

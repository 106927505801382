import React, { useState } from "react";
import styled from "styled-components";
import AudioCompanionLanguageLevels, { AudioCompanionLanguageSelector } from "./AudioCompanionLanguageLevels";
import { images } from "@resourcehub/resourcehub-assets";

const LevelBoxContainer = styled.div``;

export const LevelColumnWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-template-rows: auto;

    @media (max-width: 550px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @media (max-width: 380px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
`;

export const LevelSubColumn = styled.div`
    ${({ columns }) => {
        switch (columns) {
            case 2:
                return `
                   width: 50%; 
              `;
            default:
                return `
                   width: 100%; 
              `;
        }
    }};
`;

export const LevelContainer = styled.div`
    ${({ level }) => {
        switch (level) {
            case 1:
                return `
                  background-color: hsl(49, 100%, 76%);
              `;
            case 2:
                return `
                  background-color: hsl(49, 100%, 66%);
              `;
            case 3:
                return `
                  background-color: hsl(49, 100%, 56%);
              `;
            case 4:
                return `
                  background-color: hsl(49, 100%, 51%);
              `;
            case 5:
                return `
                  background-color: hsl(49, 100%, 46%);
              `;
            default:
                return null;
        }
    }};
    text-align: center;
    color: black;
`;

export const LevelItemContainer = styled.div`
    color: hsla(198, 80%, 68%, 1);
    text-decoration: none;
    font-weight: bold;
    padding: 1em;
    text-align: center;
    background-color: #efefef;
    outline: 1px solid rgba(0, 0, 0, 0.04);
    font-size: 1vw;

    @media (max-width: 1330px) {
        font-size: 1.25vw;
    }

    @media (max-width: 887px) {
        font-size: 1.9vw;
    }

    @media (max-width: 600px) {
        font-size: 2.7vw;
    }

    @media (max-width: 480px) {
        font-size: 3.5vw;
    }

    @media (max-width: 335px) {
        font-size: 4.5vw;
    }

    &:nth-child(2n + 2) {
        background-color: #fbfbfb;
        outline: 1px solid rgba(0, 0, 0, 0.04);
    }
`;

const Wrapper = styled.section`
    max-width: 90%;
    margin: 0 auto;
    padding: 6rem;
    background-color: #f8f6f0;

    @media (max-width: 450px) {
        padding: 2rem;
    }
`;

const AudioCompanionHero = styled.header`
    background-color: #c7bda2;
    background-image: url(${images.usConsumerAcHeroSmilephoneJpeg});
    background-size: cover;
    background-position: 100% 20%;
    background-clip: padding-box;
    padding: 1em;
    font-size: 3.2vw;
    h1 {
        font-family: helvetica, sans-serif;
        font-size: 1.29em;
        font-weight: 100;
        margin: 5em 0 0;
        color: white;

        @media (max-width: 640px) {
            font-size: 2rem;
            margin: 3rem 0 0;
        }
    }

    p {
        font-family: helvetica, sans-serif;
        font-size: 0.62em;
        margin: 0.2em 0 6em;
        line-height: 1.2;
        width: 19em;
        color: white;

        @media (max-width: 640px) {
            font-size: 1rem;
        }
    }
`;

const LanguageSelectorSection = styled.section`
    box-sizing: content-box;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f6f0;
    padding: 0 6rem;

    @media (max-width: 1400px) {
        flex-direction: column;
    }

    @media (max-width: 860px) {
        padding: 0 3rem;
    }

    @media (max-width: 450px) {
        padding: 0;
    }
`;

const LanguageSelectorParagraphs = styled.div`
    width: 60%;
    max-width: 30em;
    font-size: 24px;

    @media (max-width: 1400px) {
        width: 80%;
        max-width: 32em;
    }
`;

const AudioCompanionTableMp3 = styled.p`
    &::before {
        content: "";
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        background: url(${images.usConsumerFeaturesAcMp3IconPng});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        padding-right: 0.5em;
        top: 0.25em;
    }
`;

const PageTemplateFeaturesAudioCompanion = (config) => {
    const [currentLanguage, setCurrentLanguage] = useState("ESP");

    return (
        <>
            <AudioCompanionHero>
                <h1>{config.page.content.sections.hero.h1}</h1>
                <p>{config.page.content.sections.hero.p_1}</p>
            </AudioCompanionHero>
            <LanguageSelectorSection>
                <LanguageSelectorParagraphs>
                    <p>{config.page.content.sections.lang_selector.p_1}</p>
                    <p>{config.page.content.sections.lang_selector.p_2}</p>
                    <p>{config.page.content.sections.lang_selector.p_3}</p>
                </LanguageSelectorParagraphs>
                <AudioCompanionLanguageSelector
                    currentLanguage={currentLanguage}
                    onLanguageChangeHandler={(event) => setCurrentLanguage(event.target.value)}
                    config={config}
                />
            </LanguageSelectorSection>
            <Wrapper>
                <AudioCompanionTableMp3>{config.page.content.sections.lang_selector.mp3_p_1}</AudioCompanionTableMp3>
                <LevelBoxContainer>
                    <LevelColumnWrapper>
                        <AudioCompanionLanguageLevels language={currentLanguage} config={config} />
                    </LevelColumnWrapper>
                </LevelBoxContainer>
            </Wrapper>
        </>
    );
};

export default PageTemplateFeaturesAudioCompanion;

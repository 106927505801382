import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export const determineGdprEmailConsent = () => {
    const [emailConsent, setEmailConsent] = useState("");
    const [country, setCountry] = useState("");
    const [displayEmailInfoText, setDisplayEmailInfoText] = useState(false);
    const [displayEmailConsentCheckbox, setDisplayEmailConsentCheckbox] = useState(false);

    useEffect(() => {
        let userIsFromGdprCountry;
        const geoLocationCookie = Cookies.get("onetrustGeolocationData");

        if (geoLocationCookie) {
            const parsedCookie = JSON.parse(geoLocationCookie);
            userIsFromGdprCountry = parsedCookie.country_code.toUpperCase() !== "US";

            setCountry(parsedCookie.country_code);
            setEmailConsent(userIsFromGdprCountry ? "explicit" : "implicit");
            setDisplayEmailConsentCheckbox(userIsFromGdprCountry);
            return;
        }

        window.addEventListener("oneTrustGeolocationInitialized", (event) => {
            const { country } = event.detail;
            userIsFromGdprCountry = country.toUpperCase() !== "US";

            if (userIsFromGdprCountry) {
                setDisplayEmailConsentCheckbox(true);
            }
            setCountry(country);
            setEmailConsent(userIsFromGdprCountry ? "explicit" : "implicit");
            setDisplayEmailConsentCheckbox(userIsFromGdprCountry);
        });
    }, []);

    const updateGdprEmailConsent = (event) => {
        if (event.target.checked) {
            setEmailConsent("active");
        } else {
            setEmailConsent(null);
        }
    };

    const toggleEmailInfoText = () => {
        setDisplayEmailInfoText(() => !displayEmailInfoText);
    };

    return {
        emailConsent,
        country,
        displayEmailConsentCheckbox,
        displayEmailInfoText,
        toggleEmailInfoText,
        updateGdprEmailConsent
    };
};

// HOC to provide pages access to location data.
import React from "react";
import { Location } from "@reach/router";
import queryString from "query-string";

const withLocation = (ComponentToWrap) => (props) =>
    (
        <Location>
            {({ location, navigate }) => {
                return (
                    <ComponentToWrap
                        {...props}
                        routeLocation={location}
                        routeNavigate={navigate}
                        routeSearch={location.search ? queryString.parse(location.search) : {}}
                    />
                );
            }}
        </Location>
    );

export default withLocation;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image, { ImagePropTypes } from "../Images/Image";
import Link, { LinkPropTypes } from "../Links/Link";

const NavLogoLink = styled(Link)`
    display: inline-flex;
    line-height: 0;
    margin: 0;
    padding: 0;
`;

const Logo = ({ image, link, classname, dataQa }) => (
    <NavLogoLink {...link}>
        <Image dataQa={dataQa} className={classname} image={image} img_type={"static"} />
    </NavLogoLink>
);

Logo.propTypes = {
    image: PropTypes.shape(ImagePropTypes).isRequired,
    link: PropTypes.shape(LinkPropTypes).isRequired
};

Logo.defaultProps = {};
export default Logo;

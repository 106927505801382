import React from "react";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { leadGenerationStore } from "@resourcehub/resourcehub-data";
import ButtonStyled, { ButtonStyledPropTypes } from "../Buttons/ButtonStyled";

const { leadGenerationActions, leadGenerationSelectors } = leadGenerationStore;

class LeadGenerationButton extends React.Component {
    sendToForm = () => {
        const {
            completion_redirect,
            schedule_appointment,
            setLeadGenerationCompletionRedirect,
            setLeadGenerationScheduleAppointment,
            setLeadGenerationClearForm,
            button
        } = this.props;
        // clear form
        setLeadGenerationClearForm();
        // Push props to store.
        if (completion_redirect) {
            setLeadGenerationCompletionRedirect(completion_redirect);
        }
        if (schedule_appointment) {
            setLeadGenerationScheduleAppointment(schedule_appointment);
        }
        navigate(button.destination);
    };

    render = () => {
        const { button, dataQa = "masthead" } = this.props;
        return (
            <>
                <ButtonStyled
                    {...button}
                    dataQa={`${dataQa}-request-pricing-button`}
                    destination={null}
                    onClick={this.sendToForm}
                />
            </>
        );
    };
}

export const LeadGenerationButtonPropTypes = {
    dataQa: PropTypes.string,
    button: PropTypes.shape(ButtonStyledPropTypes).isRequired,
    schedule_appointment: PropTypes.bool,
    completion_redirect: PropTypes.string
};
LeadGenerationButton.propTypes = LeadGenerationButtonPropTypes;
LeadGenerationButton.defaultProps = {};

// Selectors
const mapStateToProps = (state) => ({
    submission_status: leadGenerationSelectors.getSubmissionStatus(state)
});

export { LeadGenerationButton };
export default connect(mapStateToProps, leadGenerationActions)(LeadGenerationButton);

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { InlineWidget } from "react-calendly";
import { leadGenerationStore } from "@resourcehub/resourcehub-data";

const { leadGenerationActions } = leadGenerationStore;

const Calendly = styled.div`
    label: Calendly;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        .calendly-inline-widget {
            width: 100vw;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        .calendly-inline-widget {
            width: 100vw;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        .calendly-inline-widget {
            width: 100vw;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        .calendly-inline-widget {
            width: 60vw;
            height: 1000px;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        height: 1000px;
        .calendly-inline-widget {
            width: 60vw;
            flex: 0;
            min-height: 1100px;
        }
    }
`;
export class LeadGenerationCalendly extends React.Component {
    isCalendlyEvent(e) {
        return e.data.event && e.data.event === "calendly.event_scheduled";
    }
    componentDidMount = () => {
        window.addEventListener("message", (e) => {
            if (this.isCalendlyEvent(e)) {
                if (Object.prototype.hasOwnProperty.call(this.props, "callback")) {
                    this.props.callback(e);
                }
            }
        });
    };

    render = () => {
        const { email, first_name, last_name, phone } = this.props;
        return (
            <Calendly>
                <InlineWidget
                    url="https://calendly.com/rosetta-stone-sales/quick-call?embed_domain=www.rosettastone.com&embed_type=Inline"
                    prefill={{
                        email: email,
                        firstName: first_name,
                        lastName: last_name,
                        customAnswers: {
                            a1: phone
                        }
                    }}
                />
            </Calendly>
        );
    };
}

export const LeadGenerationCalendlyPropTypes = {
    dataQa: PropTypes.string
};
LeadGenerationCalendly.propTypes = LeadGenerationCalendlyPropTypes;
LeadGenerationCalendly.defaultProps = {};

// Selectors
const mapStateToProps = () => ({
    // response_data: leadGenerationSelectors.getResponseData(state),
    // submission_status: leadGenerationSelectors.getSubmissionStatus(state),
    // submission_data: leadGenerationSelectors.getResponseData(state)
});

export default connect(mapStateToProps, leadGenerationActions)(LeadGenerationCalendly);

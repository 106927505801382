import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { vendor } from "@resourcehub/resourcehub-assets";
import Image from "../Images/Image";
import Link from "../Links/Link";

const IDMeButtonContainerDiv = styled.div`
    margin: 10px 0;
`;

const idMeVerifySvg = {
    src: vendor.idMeVerifySvg,
    type: "static"
};

const IDMeVerifyButton = ({ url }) => (
    <IDMeButtonContainerDiv>
        <Link destination={url} new_window={false}>
            <Image alt={"ID.me Logo"} image={idMeVerifySvg} height={50} width={256} />
        </Link>
    </IDMeButtonContainerDiv>
);

IDMeVerifyButton.propTypes = {
    url: PropTypes.string.isRequired
};

IDMeVerifyButton.defaultProps = {};
export default IDMeVerifyButton;

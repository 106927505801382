import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { images } from "@resourcehub/resourcehub-assets";
import Swiper from "swiper";

const Hero = styled.div`
    height: 610px;
    background-position: 50%;
    background: #0098db url(${images.usConsumerProductFaqBgLargeJpg}) no-repeat;
    background-size: cover;
    display: flex;

    @media (max-width: 900px) {
        background: #0098db url(${images.usConsumerProductFaqBgMobileJpg}) no-repeat;
        height: 200px;
        background-size: cover;
    }
`;

const HeroContentContainer = styled.div`
    max-width: 1320px;
    margin: auto;
    padding: 0 15px 35px;
`;

const HeroFaqWrapper = styled.div`
    max-width: 790px;
    margin: 0 auto;
    padding: 214px 0 0;
    @media (max-width: 900px) {
        padding: 0;
    }

    h1 {
        color: #262626;
        font-size: 148px;
        margin: 0;
        font-family: helvetica, sans-serif;
        padding: 0 0 0 62px;
        letter-spacing: -11px;

        @media (max-width: 900px) {
            font-size: 66px;
            padding: 19px 0 0;
            letter-spacing: 0;
            text-align: center;
        }
    }

    div {
        font-size: 24px;
        padding: 0 0 0 28px;
        font-family: effra, sans-serif;
        letter-spacing: -0.2px;
        line-height: 1.6em;
        margin: -9px 0 0;

        @media (max-width: 900px) {
            display: none;
        }
    }

    a {
        color: #000;
        font-weight: 700;
        letter-spacing: -0.8px;
        text-decoration: underline;

        @media (max-width: 900px) {
            display: none;
        }
    }
`;

const FaqSectionContainer = styled.div`
    display: flex;
    align-items: flex-start;
    max-width: 1320px;
    margin: auto;
    padding: 0 15px 35px;

    @media (max-width: 768px) {
        display: initial;
    }
`;

const FaqSectionSidebar = styled.div`
    width: 27.6%;
    flex-grow: 0;

    display: block;
    @media (max-width: 768px) {
        display: none;
    }

    ul {
        margin: 0;
        padding: 40px 0 0;
    }
`;

const FaqSectionSidebarMobile = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const FaqSectionContent = styled.div`
    padding: 8px 0 45px 3.5%;
    width: 69%;
    flex-grow: 0;

    @media (max-width: 768px) {
        width: initial;
        padding-right: 3.5%;
    }
`;

const BasicsBox = styled.li`
    list-style: none;
    margin: 0 0 33px;
    border-radius: 10px;
    box-shadow: 0 0 4.5px rgb(51 66 81 / 24%);
    font-family: effra, sans-serif;
    font-size: 23px;
    padding: 29px 0 32px 111px;
    letter-spacing: 0.04em;
    cursor: pointer;
    color: ${({ isActive }) => (isActive ? "#fff" : "#000")};
    background-size: 56px !important;
    background-position: 30px !important;
    background: ${({ isActive }) =>
        isActive
            ? `#0098db url(${images.usConsumerProductFaqBasicsIconHoverSvg}) no-repeat`
            : `#fff url(${images.usConsumerProductFaqBasicsIconSvg}) no-repeat`};

    &:hover {
        background: #0098db url(${images.usConsumerProductFaqBasicsIconHoverSvg}) no-repeat;
        color: #fff;
    }

    @media (max-width: 768px) {
        width: 65%;
        padding: 83px 30px 19px;
        border-radius: 5px;
        text-align: center;
        background-size: 50px !important;
        background-position: center 20px !important;
        margin: 5px 0 0;
        height: auto;
        box-shadow: 0 0 4.5px rgb(51 66 81 / 24%);
    }
`;

const FreeTrialBox = styled(BasicsBox)`
    background: ${({ isActive }) =>
        isActive
            ? `#0098db url(${images.usConsumerProductFaqFreeTrialIconHoverSvg}) no-repeat`
            : `#fff url(${images.usConsumerProductFaqFreeTrialIconSvg}) no-repeat`};

    &:hover {
        background: #0098db url(${images.usConsumerProductFaqFreeTrialIconHoverSvg}) no-repeat;
    }
`;
const PricingAndReturnsBox = styled(BasicsBox)`
    background: ${({ isActive }) =>
        isActive
            ? `#0098db url(${images.usConsumerProductFaqPurchaseReturnIconHoverSvg}) no-repeat`
            : `#fff url(${images.usConsumerProductFaqPurchaseReturnIconSvg}) no-repeat`};

    &:hover {
        background: #0098db url(${images.usConsumerProductFaqPurchaseReturnIconHoverSvg}) no-repeat;
    }
`;
const TechnicalBox = styled(BasicsBox)`
    background: ${({ isActive }) =>
        isActive
            ? `#0098db url(${images.usConsumerProductFaqTechnicalIconHoverSvg}) no-repeat`
            : `#fff url(${images.usConsumerProductFaqTechnicalIconSvg}) no-repeat`};
    &:hover {
        background: #0098db url(${images.usConsumerProductFaqTechnicalIconHoverSvg}) no-repeat;
    }
`;

const FaqBoxContent = styled.div`
    max-height: ${({ isActive }) => (isActive ? "800px" : "0")};
    display: block;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
    font-size: 18px;

    p,
    li {
        font-size: 24px;
        font-family: effra, sans-serif;
        margin: 0;
        letter-spacing: -0.01em;
        padding: 0 99px 20px 0;
        line-height: 2em;

        @media (max-width: 768px) {
            padding: 0 0 20px 0;
        }
    }

    a {
        color: #262626;
        text-decoration: underline;
    }
`;

const Accordion = styled.div`
    border-bottom: 3px dotted #c5c5c5;

    @media (max-width: 768px) {
        border-bottom: 1.5px solid #c5c5c5;
    }
`;

const AccordionTitle = styled.div`
    padding: 26px 0 28px;
    background: ${({ isActive }) =>
        isActive
            ? `url(${images.usConsumerProductFaqMinusIconSvg}) no-repeat`
            : `url(${images.usConsumerProductFaqPlusIconSvg}) no-repeat`};
    background-position: 100%;
    cursor: pointer;

    h4 {
        margin: 0;
        font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
        font-family: helvetica, helvetica_bold, sans-serif;

        @media (max-width: 1024px) {
            font-size: 18px;
        }

        @media (max-width: 768px) {
            padding: 0;
        }

        @media (max-width: 400px) {
            padding: 0 90px 0 0;
        }
    }
`;

const SwiperContainer = styled.div`
    label: SwiperContainer;

    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    .swiper-slide {
        text-align: center;
        font-size: 24px;
        width: 100% !important;

        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const SwiperWrapper = styled.div`
    label: SwiperWrapper;
`;

const SwiperPagination = styled.div`
    label: SwiperPagination;
    .swiper-pagination-bullet {
        opacity: 1;
        margin: 0 12px;
        width: 16px;
        height: 16px;
        background-color: #999;
        transition: background-color 0.2s, width 0.2s, border-radius 0.2s;
    }

    .swiper-pagination-bullet-active {
        width: 96px;
        border-radius: 16px;
        background-color: #0098db;
    }
`;

const FaqItem = ({ faqItem }) => {
    const [isContentVisible, setIsContentVisible] = useState(false);

    return (
        <Accordion onClick={() => setIsContentVisible(!isContentVisible)}>
            <AccordionTitle isActive={isContentVisible}>
                <h4>{faqItem.heading}</h4>
            </AccordionTitle>
            <FaqBoxContent isActive={isContentVisible}>
                <div dangerouslySetInnerHTML={{ __html: faqItem.subtext }} />
            </FaqBoxContent>
        </Accordion>
    );
};

const PageTemplateConsumerProductFAQ = ({ config }) => {
    const [selectedFaqBox, setSelectedFaqBox] = useState("basics");

    useEffect(() => {
        new Swiper(".swiper-container", {
            centeredSlides: true,
            slidesToShow: 1,
            pagination: {
                el: ".swiper-pagination",
                clickable: true
            },
            onAny(event, ...args) {
                // when user changes slide, manually update the current FAQ bullets
                // to reflect the currently active box
                if (event === "transitionEnd") {
                    const currentSlideIndex = args[0].activeIndex;

                    const basicSlideIndex = 0;
                    const freeTrialSlideIndex = 1;
                    const pricingAndReturnsSlideIndex = 2;
                    const technicalSlideIndex = 3;

                    switch (currentSlideIndex) {
                        case basicSlideIndex:
                            setSelectedFaqBox("basics");
                            break;
                        case freeTrialSlideIndex:
                            setSelectedFaqBox("freeTrial");
                            break;
                        case pricingAndReturnsSlideIndex:
                            setSelectedFaqBox("pricingAndReturns");
                            break;
                        case technicalSlideIndex:
                            setSelectedFaqBox("technical");
                            break;
                        default:
                            return null;
                    }
                }
            }
        });
    }, []);

    const faqButtons = [
        <BasicsBox onClick={() => setSelectedFaqBox("basics")} isActive={selectedFaqBox === "basics"} key={1}>
            {config.page.content.sections[0].faqButtonCopy.theBasics}
        </BasicsBox>,
        <FreeTrialBox onClick={() => setSelectedFaqBox("freeTrial")} isActive={selectedFaqBox === "freeTrial"} key={2}>
            {config.page.content.sections[0].faqButtonCopy.freeTrial}
        </FreeTrialBox>,
        <PricingAndReturnsBox
            onClick={() => setSelectedFaqBox("pricingAndReturns")}
            isActive={selectedFaqBox === "pricingAndReturns"}
            key={3}
        >
            {config.page.content.sections[0].faqButtonCopy.pricingAndReturns}
        </PricingAndReturnsBox>,
        <TechnicalBox onClick={() => setSelectedFaqBox("technical")} isActive={selectedFaqBox === "technical"} key={4}>
            {config.page.content.sections[0].faqButtonCopy.technical}
        </TechnicalBox>
    ];

    return (
        <>
            <Hero>
                <HeroContentContainer>
                    <HeroFaqWrapper>
                        <h1 dangerouslySetInnerHTML={{ __html: config.page.content.sections[0].heroFaq.h1 }} />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: config.page.content.sections[0].heroFaq.p
                            }}
                        />
                    </HeroFaqWrapper>
                </HeroContentContainer>
            </Hero>
            <div>
                <FaqSectionContainer>
                    <FaqSectionSidebar>
                        <ul>{faqButtons.map((faqButton) => faqButton)}</ul>
                    </FaqSectionSidebar>
                    <FaqSectionSidebarMobile>
                        <SwiperContainer className="swiper-container">
                            <SwiperWrapper className="swiper-wrapper">
                                {faqButtons.map((faqButton, i) => (
                                    <div key={`faq-button-${i}`} className="swiper-slide">
                                        {faqButton}
                                    </div>
                                ))}
                            </SwiperWrapper>
                            <SwiperPagination className="swiper-pagination" />
                        </SwiperContainer>
                    </FaqSectionSidebarMobile>
                    <FaqSectionContent>
                        <div className="accordions">
                            {config.page.content.sections[0].faqItems[selectedFaqBox].map((faqItem) => (
                                <FaqItem faqItem={faqItem} key={faqItem.subtext} />
                            ))}
                        </div>
                    </FaqSectionContent>
                </FaqSectionContainer>
            </div>
        </>
    );
};

PageTemplateConsumerProductFAQ.propTypes = {};
PageTemplateConsumerProductFAQ.defaultProps = {};

export default PageTemplateConsumerProductFAQ;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Swiper from "swiper";

// Styles
import TitleWrapper, { TitleWrapperPropTypes } from "../component-styles/TitleWrapper";
import PageSectionWrapper from "../component-styles/PageSectionWrapper";

// Components
import ProductSampleText, { ProductSampleTextPropTypes } from "../components/ProductSamples/ProductSampleText";

// Carousel
const ProductSampleTextCarouselSlideDiv = styled.div`
    label: ProductSampleTextCarouselSlideDiv;

    width: 100%;
    > div {
        margin-left: 2em;
        margin-right: 2em;
    }
`;
const CarouselNavigationBullets = styled.div`
    label: CarouselNavigationBullets;

    .swiper-pagination-bullet {
        border: 2px solid #666666;
        height: 10px;
        width: 10px;
        margin-left: 13px !important;
        margin-right: 13px !important;
        background-color: inherit !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #666666 !important;
    }
`;
const CarouselNavigationNext = styled.div`
    label: CarouselNavigationNext;

    --swiper-navigation-color: #666666;
`;
const CarouselNavigationPrevious = styled.div`
    label: CarouselNavigationPrevious;

    --swiper-navigation-color: #666666;
`;
const ProductSampleTextCarouselLayout = styled.div`
    //display: flex;
    //flex-direction: column;
    width: 100%;
    > div:nth-of-type(1) {
        margin-left: 2.5em;
        margin-right: 4em;
        margin-bottom: 50px;
    }
`;
class ProductSampleTextCarousel extends React.Component {
    componentDidMount() {
        new Swiper(".swiper-container", {
            cssMode: true,
            loop: true,
            centeredSlides: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            pagination: {
                el: ".swiper-pagination"
            },
            mousewheel: true,
            keyboard: true
        });
    }
    render() {
        const { dataQa, panels, title } = this.props;
        if (!panels) return null;
        return (
            <PageSectionWrapper>
                <ProductSampleTextCarouselLayout>
                    {title && <TitleWrapper {...title}>{title.content}</TitleWrapper>}
                    {panels && (
                        <div className="swiper-container">
                            <div className="swiper-wrapper">
                                {panels.map((panel, i) => (
                                    <ProductSampleTextCarouselSlideDiv
                                        data-qa={`content-section-header-${dataQa}`}
                                        className="swiper-slide"
                                        key={`image-text-carousel-slide-${i}`}
                                    >
                                        <ProductSampleText dataQa={dataQa} {...panel} />
                                    </ProductSampleTextCarouselSlideDiv>
                                ))}
                            </div>

                            {/* Add Arrows */}

                            <CarouselNavigationBullets className="swiper-pagination" />
                            <CarouselNavigationNext className="swiper-button-next" />
                            <CarouselNavigationPrevious className="swiper-button-prev" />
                        </div>
                    )}
                </ProductSampleTextCarouselLayout>
            </PageSectionWrapper>
        );
    }
}

// List
const ProductSampleTextListLayout = styled.div`
    label: ProductSampleTextListLayout;

    display: flex;
    flex-direction: column;
    width: 100%;
`;
const ProductSampleTextListContainer = styled.div`
    label: ProductSampleTextListContainer;

    display: flex;
    flex-direction: column;
    > div {
        margin-bottom: 50px;
    }
`;
const ProductSampleTextTitleWrapper = styled(TitleWrapper)`
    margin-bottom: 40px;
`;
const ProductSampleTextList = ({ dataQa, panels, title }) => {
    if (!panels) return null;
    return (
        <PageSectionWrapper>
            <ProductSampleTextListLayout>
                {title && <ProductSampleTextTitleWrapper {...title}>{title.content}</ProductSampleTextTitleWrapper>}
                {panels && (
                    <ProductSampleTextListContainer>
                        {panels.map((panel, i) => (
                            <ProductSampleText dataQa={dataQa} key={`image-text-${i}`} {...panel} />
                        ))}
                    </ProductSampleTextListContainer>
                )}
            </ProductSampleTextListLayout>
        </PageSectionWrapper>
    );
};

// Root Component
const PageSectionProductSampleText = (props) => {
    switch (props.layout) {
        case "list":
            return <ProductSampleTextList {...props} />;
        case "carousel":
            return <ProductSampleTextCarousel {...props} />;
    }
};
const PageSectionProductSampleTextPropTypes = {
    dataQa: PropTypes.string,
    layout: PropTypes.oneOf(["list", "carousel"]),
    panels: PropTypes.arrayOf(PropTypes.shape(ProductSampleTextPropTypes)).isRequired,
    title: PropTypes.shape(TitleWrapperPropTypes)
};
PageSectionProductSampleText.defaultProps = {
    dataQa: "page-section-image-text",
    layout: "list"
};
PageSectionProductSampleText.propTypes = PageSectionProductSampleTextPropTypes;
export default PageSectionProductSampleText;

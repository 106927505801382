import { profileCard } from "@resourcehub/resourcehub-assets";

export const slides = {
    en: [
        {
            id: 1,
            profileImgSrc: profileCard.profileCardGladysJpg,
            profileSummary:
                "I've been using Rosetta Stone for years to gain basic competency in multiple languages including German, French, Italian, and recently Chinese and Russian. Starts with the very basics teaching basic vocabulary and grammar without any memorization. I've even impressed some locals in my travels with pronunciation and fluency. This is an excellent place to start if interested in starting to learn a new language or brushing up on one learned years ago.",
            profileName: "Gladys"
        },
        {
            id: 2,
            profileImgSrc: profileCard.profileCardSyJpg,
            profileSummary:
                "I am trying out Rosetta Stone, to see if it will help out with the correct grammar and conversation (as well as learning how to read and write the language). Within a week, I can already master the sentence structure and start learning the grammar with particles. The local community is so excited to see that I am starting to learn their language. Out of all the language learning tools out there, I 100% recommend Rosetta Stone!",
            profileName: "Sy"
        },
        {
            id: 3,
            profileImgSrc: profileCard.profileCardJimJpg,
            profileSummary:
                "I've tried other language learning software but Rosetta Stone is much more challenging and professional. I don't have to worry about earning points and following the leader board. I'm trying out the ninety day trial to learn some Russian and I will pay for the privilege once I reach the end of the trial.",
            profileName: "Jim"
        }
    ],
    es: [
        {
            id: 1,
            profileImgSrc: profileCard.profileCardJessicaJpeg,
            profileSummary:
                "Hace el viajar mucho más fácil. Poder hablar con la gente aunque sea preguntas sencillas, en su idioma, es una experiencia fenomenal. Caminé hacia un hombre griego que estaba vendiendo algo en la calle, y le hice una pregunta en griego...y me respondió de una forma tan fluida en griego que asumo mi pregunta fue bien formulada y no fue una tontería lo que dije. Eso fue para mi un buen momento y me inspiró a continuar y mejorar.",
            profileName: "Jessica"
        },
        {
            id: 2,
            profileImgSrc: profileCard.profileCardKevinPng,
            profileSummary:
                "Caminé hacia un hombre griego que estaba vendiendo algo en la calle, y le hice una pregunta en griego...y me respondió de una forma tan fluida en griego que asumo mi pregunta fue bien formulada y no fue una tontería lo que dije. Eso fue para mi un buen momento y me inspiró a continuar y mejorar.",
            profileName: "Kevin"
        },
        {
            id: 3,
            profileImgSrc: profileCard.profileCardPeterJpeg,
            profileSummary:
                "No te preocupes de lo más grande. No te preocupes en que tienes que tener una conversación fluida con alguien...solo enfócate en lo básico y diviértete en el camino- en realidad lo que importa es el camino cuando se trata de aprender un nuevo idioma. Estudié español e italiano con Rosetta Stone, y..el hecho que pude hablar y conversar con la gente durante mis viajes me permitió conocer y encontrar sitios donde ir en cada país.",
            profileName: "Peter"
        }
    ]
};

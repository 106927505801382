const { createGlobalStyle } = require("styled-components");

import "../fonts/fonts.css";

const GlobalStyle = createGlobalStyle`
      body {
        background-color: #fff;
        border: 0;
        box-sizing: border-box;
        color: #262626;
        font-family: effra, sans-serif;
        font-size: 20px;
        margin: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 0;
      }
      a {
            cursor: pointer;
            text-decoration: none;
            color: inherit;
            &:hover {
                text-decoration: underline;
            }
        }
`;

export default GlobalStyle;

import React, { useState } from "react";
import VideoThumbnails from "./VideoThumbnails";
import styled from "styled-components";
import PropTypes from "prop-types";
import VideoPlayer from "./VideoPlayer";

const VideoPlayerContainer = styled.div`
    display: block;

    @media (max-width: 760px) {
        display: none;
    }
`;

const VideoSelector = ({ panels, boxShadow = "#eee" }) => {
    const initialVideoUrl = panels[0].video_url;
    const [currentVideoUrl, setCurrentVideoUrl] = useState(initialVideoUrl);

    return (
        <>
            <VideoPlayerContainer>
                <VideoPlayer currentVideoUrl={currentVideoUrl} boxShadow={boxShadow} />
            </VideoPlayerContainer>
            <VideoThumbnails
                panels={panels}
                setCurrentVideoUrl={(videoUrl) => setCurrentVideoUrl(videoUrl)}
                currentVideoUrl={currentVideoUrl}
                boxShadow={boxShadow}
            />
        </>
    );
};

const VideoSelectorPropTypes = {
    panels: PropTypes.arrayOf(
        PropTypes.shape({
            asset_url: PropTypes.string.isRequired,
            video_url: PropTypes.string.isRequired
        })
    ).isRequired,
    boxShadow: PropTypes.string
};

VideoSelector.propTypes = VideoSelectorPropTypes;

export default VideoSelector;

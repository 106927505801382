import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import MobileSideNavLink from "./MobileNavLink";

const LanguagesPricingWrapperDiv = styled.div`
    label: LanguagesPricingWrapperDiv;

    display: block;
`;

const DropdownIcon = styled.div`
    label: DropdownIcon;

    content: "";
    display: inline-block;
    border: 4px solid transparent;
    border-top: 5px solid #ecc200;
    border-bottom: none;
    margin-left: 15px;
    transition: transform 0.3s;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

const LanguagesPricingMobileDiv = styled.div`
    label: LanguagesPricingMobileDiv;

    // show
    transition: transform 0.3s;
    display: ${(props) => (props.isOpen ? "block" : "none")};

    a {
        display: block;
        letter-spacing: 0.03em;
        color: #ffffff;
        font-family: effra_bold, sans-serif;
        font-size: 14px;
        padding: 8px 26px;
        transition: all 0.2s;
        text-decoration: none;
        text-align: left;
        white-space: nowrap;
        :last-child {
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        :hover,
        :active,
        :focus {
            text-decoration: none;
            background-color: #e5e5e5;
        }
    }
`;

const Separator = styled.hr`
    label: Separator;

    border: 1px solid #555;
`;

class LanguagesPricingMobile extends React.PureComponent {
    state = {
        isOpen: false
    };

    togglePane = () => {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen
        });
    };

    render() {
        const { children, config, dataQa } = this.props;
        const { isOpen } = this.state;
        const { language_options_featured, language_options_other, language_options_students } = config;
        return (
            <LanguagesPricingWrapperDiv onClick={this.togglePane}>
                <MobileSideNavLink data-qa={dataQa}>
                    {children}
                    <DropdownIcon isOpen={isOpen} />
                </MobileSideNavLink>
                <LanguagesPricingMobileDiv isOpen={isOpen}>
                    {language_options_featured.map((language, idx) => {
                        const key = `language-${idx + 1}`;
                        return (
                            <a href={language.url} key={key}>
                                {language.title} {language.small && <small>({language.small})</small>}
                            </a>
                        );
                    })}
                    <Separator />
                    {language_options_other.map((language, idx) => {
                        const key = `language-${idx + 1}`;
                        return (
                            <a href={language.url} key={key}>
                                {language.title} {language.small && <small>({language.small})</small>}
                            </a>
                        );
                    })}
                    {language_options_students && (
                        <>
                            <Separator />
                            {language_options_students.map((language, idx) => {
                                const key = `language-${idx + 1}`;
                                return (
                                    <a href={language.url} key={key}>
                                        {language.title} {language.small && <small>({language.small})</small>}
                                    </a>
                                );
                            })}
                        </>
                    )}

                    <Separator />
                </LanguagesPricingMobileDiv>
            </LanguagesPricingWrapperDiv>
        );
    }
}

LanguagesPricingMobile.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

    config: PropTypes.shape({
        // Labels
        language_options_featured: PropTypes.PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                title: PropTypes.string,
                small: PropTypes.string
            })
        ).isRequired,
        language_options_other: PropTypes.PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                title: PropTypes.string,
                small: PropTypes.string
            })
        ).isRequired
    })
};

export default LanguagesPricingMobile;

import React from "react";
import { Helmet } from "react-helmet";
import { rsBranding } from "@resourcehub/resourcehub-assets";

const HelmetWrapper = ({
    no_index,
    no_follow,
    theme_color = "#efc343",
    lang = "en",
    canonical_url,
    meta_title,
    meta_description
}) => {
    let robotsTag = null;
    if (no_index && no_follow) {
        robotsTag = <meta name="robots" content="noindex, nofollow" />;
    } else if (no_index) {
        robotsTag = <meta name="robots" content="noindex, follow" />;
    } else if (no_follow) {
        robotsTag = <meta name="robots" content="index, nofollow" />;
    }
    return (
        <Helmet defaultTitle={meta_title}>
            <title>{meta_title}</title>
            <link href={rsBranding.faviconIco} rel="shortcut icon" type="image/ico" />
            <link rel="canonical" href={canonical_url} />

            <meta name="description" content={`${meta_description}`} />
            <meta name="theme-color" content={theme_color} />

            <meta property="og:title" content={meta_title} />
            <meta property="og:description" content={meta_description} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={rsBranding.rsLogoBlackSvg} />
            <meta property="og:site_name" content="Rosetta Stone" />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:image" content={rsBranding.rsLogoBlackSvg} />
            <meta name="twitter:site" content="@rosettastone" />
            <meta name="twitter:creator" content="@rosettastone" />
            <meta name="twitter:title" content={meta_title} />
            <meta name="twitter:description" content={meta_description} />
            {robotsTag}
            <html lang={lang} />
        </Helmet>
    );
};

export default HelmetWrapper;

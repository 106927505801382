import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { leadGenerationStore } from "@resourcehub/resourcehub-data";

import ChildContentWrapper from "../../component-styles/ChildContentWrapper";
import TitleWrapper, { TitleWrapperPropTypes } from "../../component-styles/TitleWrapper";
import Link, { LinkPropTypes } from "../Links/Link";

import "react-toastify/dist/ReactToastify.css";
import LeadGenerationButton, { LeadGenerationButtonPropTypes } from "./LeadGenerationButton";

const { leadGenerationActions, leadGenerationConstants, leadGenerationSelectors } = leadGenerationStore;

const statusOptions = Object.keys(leadGenerationConstants.submissionStatus);

const LeadGenerationBarWrapperDiv = styled.div`
    label: LeadGenerationBarWrapperDiv;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-height: 50px;
    z-index: 101;

    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        flex-direction: column;
        > div {
            margin: 5px 0;
            width: 100%;
        }
        div {
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        flex-direction: column;
        > div {
            margin: 5px 0;
            width: 100%;
        }
        div {
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        flex-direction: column;
        > div {
            margin: 5px 0;
            width: 100%;
        }
        div {
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        flex-direction: row;
        > div {
            margin: 0 5px;
            width: initial;
        }
        div {
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        flex-direction: row;
        > div {
            margin: 0 5px;
            width: initial;
        }

        div {
            text-align: inherit;
            align-items: inherit;
        }
    }

    @-webkit-keyframes fadeOut {
        0% {
            height: 50px;
        }
        100% {
            height: 0;
        }
    }

    @keyframes fadeOut {
        0% {
            height: 50px;
        }
        100% {
            height: 0;
        }
    }

    ${({ status }) => {
        switch (status) {
            case leadGenerationConstants.submissionStatus.error:
                return `
                    background-color: #b40000;
                `;
            case leadGenerationConstants.submissionStatus.success:
                return `
                    background-color: #29b614;
                    -webkit-animation-duration: 10s;animation-duration: 2s;
                    animation-name: fadeOut;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                `;
            case leadGenerationConstants.submissionStatus.complete:
                return `
                    height: 0;
                    transition: height 0.5s ease;
                `;
            default:
                return `
                    background-color: #0098db;
                `;
        }
    }};

    ${({ open }) => {
        if (open) {
            return `
            opacity: 0;
            transition: 0.1s ease-out;
                `;
        }
        return `
                opacity: 1;
            `;
    }}
`;

class LeadGenerationBar extends React.Component {
    render = () => {
        const { lead_generation_button, children, dataQa, title, subtitle, legal, legal_link, submission_status } =
            this.props;
        const status = submission_status;
        return (
            <>
                <LeadGenerationBarWrapperDiv data-qa={"footer-call-to-action-bar"} status={status}>
                    {status !== leadGenerationConstants.submissionStatus.success &&
                        status !== leadGenerationConstants.submissionStatus.complete && (
                            <>
                                <div>
                                    {title && <TitleWrapper {...title} dataQa={"footer-call-to-action-bar-title"} />}
                                    {subtitle && <TitleWrapper {...subtitle} />}
                                </div>
                                <div>
                                    <LeadGenerationButton {...lead_generation_button} dataQa={"call-to-action-bar"} />
                                </div>
                                <div>
                                    {legal && <TitleWrapper {...legal} />}
                                    {legal_link && (
                                        <div>
                                            <Link {...legal_link} />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    {children && <ChildContentWrapper>{children}</ChildContentWrapper>}
                </LeadGenerationBarWrapperDiv>
            </>
        );
    };
}

export const LeadGenerationBarPropTypes = {
    dataQa: PropTypes.string,

    lead_generation_button: PropTypes.shape(LeadGenerationButtonPropTypes).isRequired,
    subtitle: PropTypes.shape(TitleWrapperPropTypes),
    title: PropTypes.shape(TitleWrapperPropTypes),
    legal: PropTypes.shape(TitleWrapperPropTypes),
    legal_link: PropTypes.shape(LinkPropTypes),
    success_message: PropTypes.shape(TitleWrapperPropTypes),

    submission_status: PropTypes.oneOf(statusOptions)
};
LeadGenerationBar.propTypes = LeadGenerationBarPropTypes;
LeadGenerationBar.defaultProps = {};

// Selectors
const mapStateToProps = (state) => ({
    submission_status: leadGenerationSelectors.getSubmissionStatus(state)
});

export { LeadGenerationBar };
export default connect(mapStateToProps, leadGenerationActions)(LeadGenerationBar);

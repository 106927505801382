import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image, { ImagePropTypes } from "../Images/Image";
import Link, { LinkPropTypes } from "../Links/Link";

const QuoteWrapper = styled.div`
    label: QuoteWrapper;

    display: flex;
    flex-direction: column;
    ${({ align }) => {
        switch (align) {
            case "left":
                return `
                justify-content: flex-start;
                align-items: flex-start;
                > div {
                    text-align: left;
                }
                a {
                    justify-content: flex-start;
                    align-items: flex-start;
                    > div {
                        text-align: left;
                    }
                }
            `;
            case "center":
                return `
                align-items: center;
                > div {
                    text-align: center;
                }
                a {
                    justify-content: center;
                    align-items: center;
                    > div {
                        text-align: center;
                    }
                }
            `;
            case "right":
                return `
                align-items: flex-end;
                > div {
                    text-align: right;
                }
                a {
                    justify-content: flex-end;
                    align-items: flex-end;
                    > div {
                        text-align: right;
                    }
                }
            `;
        }
    }}
`;

const IconWrapper = styled.div`
    display: flex;

    > img {
        object-fit: none;
    }

    ${({ align }) => {
        switch (align) {
            case "bottom":
                return `
                flex-direction: column-reverse;
            `;
            case "left":
                return `
                flex-direction: row;
            `;
            case "right":
                return `
                flex-direction: row-reverse;
            `;
            case "top":
                return `
                flex-direction: column;
            `;
        }
    }}
`;

const QuoteStyle = styled.div`
    font-family: effra, sans-serif;
    font-size: 16pt;
    font-style: italic;
    padding: 10px;

    ${({ size }) => {
        switch (size) {
            case "small":
                return `
                font-size: 12pt;
                font-style: italic;
                font-weight: normal;
            `;
            case "regular":
            default:
                return `
                font-size: 16pt;
                font-style: italic;
                font-weight: normal;
            `;
        }
    }}
`;
const AttributionStyle = styled.div`
    font-family: effra, sans-serif;

    ${({ size }) => {
        switch (size) {
            case "small":
                return `
                font-size: 12pt;
                font-style: italic;
            `;
            case "regular":
            default:
                return `
                font-size: 12pt;
                font-style: italic;
            `;
        }
    }}
`;
const Icon = styled(Image)`
    > img {
        object-fit: none;
    }
`;

const QuoteAttribution = ({ align, dataQa, quote, attribution, icon, icon_placement, link, size }) => {
    if (icon) {
        return (
            <QuoteWrapper align={align}>
                <IconWrapper align={icon_placement}>
                    <Icon image={icon} />
                    <div>
                        {link ? (
                            <Link {...link}>
                                <QuoteStyle size={size} data-qa={`content-section-quote-body-${dataQa}`}>
                                    {quote}
                                </QuoteStyle>
                                <AttributionStyle size={size} data-qa={`content-section-quote-attribution-${dataQa}`}>
                                    —{attribution}
                                </AttributionStyle>
                            </Link>
                        ) : (
                            <>
                                <QuoteStyle size={size} data-qa={`content-section-quote-body-${dataQa}`}>
                                    {quote}
                                </QuoteStyle>
                                <AttributionStyle size={size} data-qa={`content-section-quote-attribution-${dataQa}`}>
                                    —{attribution}
                                </AttributionStyle>
                            </>
                        )}
                    </div>
                </IconWrapper>
            </QuoteWrapper>
        );
    }
    return (
        <QuoteWrapper align={align}>
            {link ? (
                <Link {...link}>
                    <QuoteStyle data-qa={`content-section-quote-body-${dataQa}`}>{quote}</QuoteStyle>
                    <AttributionStyle data-qa={`content-section-quote-attribution-${dataQa}`}>
                        —{attribution}
                    </AttributionStyle>
                </Link>
            ) : (
                <>
                    <QuoteStyle data-qa={`content-section-quote-body-${dataQa}`}>{quote}</QuoteStyle>
                    <AttributionStyle data-qa={`content-section-quote-attribution-${dataQa}`}>
                        —{attribution}
                    </AttributionStyle>
                </>
            )}
        </QuoteWrapper>
    );
};

export const QuoteAttributionPropTypes = {
    attribution: PropTypes.string.isRequired,
    align: PropTypes.oneOf(["left", "center", "right"]),
    dataQa: PropTypes.string,
    icon: PropTypes.shape(ImagePropTypes),
    icon_placement: PropTypes.oneOf(["bottom", "left", "right", "top"]),
    link: PropTypes.shape(LinkPropTypes),
    quote: PropTypes.string.isRequired,
    size: PropTypes.oneOf(["regular", "small"])
};
QuoteAttribution.propTypes = QuoteAttributionPropTypes;
QuoteAttribution.defaultProps = {
    align: "center",
    icon_placement: "left",
    size: "regular"
};
export default QuoteAttribution;

import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { connect } from "react-redux";
import { cartStore } from "@resourcehub/resourcehub-data";
const { cartSelectors, cartActions } = cartStore;

const slideInAnimation = keyframes`
  0% {
    right: -580px;
  }

  100% {
    right: 0;
  }
`;

const slideIn = css`
    animation: ${slideInAnimation} 1s forwards;
`;

const slideOutAnimation = keyframes`
  0% {
    right: 0;
  }

  100% {
    right: -580px;
  }
`;

const slideOut = css`
    animation: ${slideOutAnimation} 1s;
`;

const slideUpAnimation = keyframes`
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
`;

const slideUp = css`
    animation: ${slideUpAnimation} 1s;
`;

const slideDownAnimation = keyframes`
  0% {
    height: 100%;
  }

  100% {
    height: 0;
  }
`;

const slideDown = css`
    animation: ${slideDownAnimation} 1s;
`;

const CartPanelWrap = styled.div`
    display: ${({ forceInvisible }) => (forceInvisible ? "none" : "block")};
    position: fixed;
    top: 0;
    bottom: 0;
    right: ${({ isCartVisible }) => (isCartVisible ? 0 : "-580px")};
    ${({ isCartVisible }) => (isCartVisible ? slideIn : slideOut)};
    min-height: 100%;
    width: 580px;
    z-index: 10;
    font-family: Helvetica, sans-serif;
    background: #4788be;

    @media (max-width: 960px) {
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        min-height: auto;
        top: auto;
        height: ${({ isCartVisible }) => (isCartVisible ? "100%" : 0)};
        ${({ isCartVisible }) => (isCartVisible ? slideUp : slideDown)};
    }
`;

const CartPanelClose = styled.a`
    background-size: 50%;
    background-repeat: no-repeat;
    background-color: #ecc200;
    background-position: center;
    background-image: url("https://www.rosettastone.com/nuxt-assets/img/icons/x-thick.svg"); // www.rosettastone.com/nuxt-assets/img/icons/x-thick.svg)
    width: 50px;
    height: 51px;
    display: inline-block;
    position: absolute;

    @media (max-width: 960px) {
        background-color: #ecc200;
        background-image: none;
        width: 21px;
        height: 21px;
        line-height: 21px;
        border-radius: 40px;
        display: inline-block;
        right: 5px;
        top: 3px;
        position: absolute;
        text-decoration: none;
        text-align: center;
        color: #333;
        font-size: 16px;

        &:after {
            content: "×";
        }
    }
`;

const LoadingMessage = styled.div`
    background: #4788be;
    height: 100%;
    padding: 0 2em;
    box-sizing: border-box;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

const Message = styled.div`
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 2em;
`;

const Loading = styled.div`
    font-size: 1em;
`;

const loadingBarAnimation = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
`;

const loadingBar = css`
    animation: ${loadingBarAnimation} 7s infinite;
`;

const LoadingBar = styled.div`
    position: relative;
    margin-top: 20px;
    width: 0;
    height: 6px;
    background-color: #fff;
    ${loadingBar};
`;

const CartIFrame = styled.iframe`
    width: 100%;
    height: 100%;
    z-index: 2;
`;

const strings = {
    en: {
        preparing: "Preparing your order",
        loadingMessages: [
            "Studies show learning a new language makes you more open-minded.<br>(We think it makes you more attractive, too.)",
            "Bilingualism can sharpen problem solving skills.",
            "Bilinguals tend to make more money at work."
        ]
    },
    es: {
        preparing: "Preparando tu orden",
        loadingMessages: [
            "Hay estudios que demuestran que el aprender un nuevo idioma te hace una persona de mentalidad abierta. (Creemos que también te hace más atractivo.)",
            "El bilingüismo puede mejorar tu habilidad para resolver problemas.",
            "Las personas bilingües tienden a ganar más dinero en el trabajo."
        ]
    }
};

const CartPanel = ({
    cartIframeUrl,
    setCartIframeUrl,
    setIsCartVisible,
    setShowLoadingMessages,
    isCartVisible,
    setIsOverlayVisible,
    showLoadingMessages
}) => {
    const [content, setContent] = useState("");
    const [forceInvisible, setForceInvisible] = useState(true);

    useEffect(() => {
        setContent(strings.en);

        if (/rosettastone/.test(window.origin)) {
            document.domain = "rosettastone.com";
        }
        document.addEventListener("cartLoaded", (payload) => {
            setShowLoadingMessages(false);
            // Uncomment this when cart team removes this call from success
            // page. This is here to stop the bug where if you delete a product
            // from cart and read it, it doesn't add. it shows cart empty page
            // because the iframe doesn't change.
            if (payload === 0) {
                setCartIframeUrl(process.env.BASE_CART);
            }
        });
    }, []);

    useEffect(() => {
        if (isCartVisible) {
            setForceInvisible(false);
            setCartIframeUrl(cartIframeUrl || process.env.BASE_CART);
        }
    }, [isCartVisible]);

    useEffect(() => {
        if (showLoadingMessages) {
            setTimeout(() => {
                setShowLoadingMessages(false);
                console.log("Timeout reached. Turning off overlay");
            }, 7000);
        }
    }, [showLoadingMessages]);

    const cartPanelCloseHandler = () => {
        setIsCartVisible(false);
        setIsOverlayVisible(false);
    };

    const randomLoadingMessage = () => {
        return content.loadingMessages[Math.floor(Math.random() * content.loadingMessages.length)];
    };

    return (
        <CartPanelWrap forceInvisible={forceInvisible} isCartVisible={isCartVisible}>
            <CartPanelClose href="#" onClick={() => cartPanelCloseHandler()} />
            {showLoadingMessages && content && (
                <LoadingMessage>
                    <Message dangerouslySetInnerHTML={{ __html: randomLoadingMessage() }} />
                    <Loading>
                        <div>{content.preparing}</div>
                        <LoadingBar />
                    </Loading>
                </LoadingMessage>
            )}
            <CartIFrame src={cartIframeUrl} frameborder="0" />
        </CartPanelWrap>
    );
};

const mapStateToProps = (state) => ({
    cartIframeUrl: cartSelectors.getCartState(state).cartIframeUrl,
    showLoadingMessages: cartSelectors.getCartState(state).showLoadingMessages,
    isCartVisible: cartSelectors.getCartState(state).isCartVisible,
    isOverlayVisible: cartSelectors.getCartState(state).isOverlayVisible
});

export { CartPanel };
export default connect(mapStateToProps, { ...cartActions })(CartPanel);

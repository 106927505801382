import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const AppFeature = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0 20px;
    text-align: left;
    position: relative;
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    opacity: ${(props) => (props.isSelected ? 1 : 0.5)};
    align-items: center;

    img {
        height: 32px;
        width: 32px;
        margin-right: 10px;
    }

    @media (max-width: 490px) {
        width: initial;
    }

    @media (max-width: 425px) {
        padding: 5px;
    }
`;

const AppFeatureCopy = styled.div`
    display: inline-block;
`;

const AppFeatureTitle = styled.div`
    font-size: 20px;
    font-family: effra_medium, sans-serif;
    letter-spacing: -0.1px;

    @media (max-width: 760px) {
        font-size: 1em;
        margin: 6px 0 0 0;
    }

    @media (max-width: 425px) {
        font-size: 0.9em;
    }

    @media (max-width: 320px) {
        font-size: 0.8em;
        line-height: 1.4em;
    }
`;

const AppFeatureDetails = styled.div`
    font-size: 14px;
    padding-top: 4px;

    @media (max-width: 760px) {
        display: none;
    }
`;

const FeatureButton = ({ index, title, icon, subtitle, selected, changeSelected, handleMouseOut }) => (
    <AppFeature
        onMouseEnter={() => changeSelected(index)}
        onTouchStart={() => changeSelected(index)}
        onBlur={handleMouseOut}
        isSelected={selected === index.toString()}
    >
        <img src={icon} alt={`${title} icon`} />
        <AppFeatureCopy>
            <AppFeatureTitle>{title}</AppFeatureTitle>
            <AppFeatureDetails>{subtitle}</AppFeatureDetails>
        </AppFeatureCopy>
    </AppFeature>
);

export const FeatureButtonPropTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
    index: PropTypes.string.isRequired,
    changeSelected: PropTypes.func.isRequired,
    handleMouseOut: PropTypes.func
};

FeatureButton.propTypes = FeatureButtonPropTypes;

export default FeatureButton;

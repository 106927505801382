import React from "react";
import styled from "styled-components";

const SectionLabel = styled.div`
    label: SectionLabel;

    font-size: 40px;
    font-family: helvetica_bold, sans-serif;
    margin-bottom: 40px;
    position: relative;
    z-index: 4;
`;
const QuotesSection = styled.section`
    label: QuotesSection;

    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: 0 20px 60px;
    text-align: center;
`;

const QuotesWrapper = styled.div`
    label: QuotesWrapper;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;
const QuoteBlock = styled.div((props) => {
    return {
        label: "QuoteBlock",
        backgroundImage: `url(${props.backdrop})`,
        boxShadow: "0 3px 10px 0 rgba(33, 33, 33, 0.5)",
        boxSizing: "border-box",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        fontSize: "18px",
        height: "200px",
        justifyContent: "space-between",
        lineHeight: "1.33",
        margin: "0 15px 20px",
        padding: "20px 24px",
        width: "350px",
        "& em": {
            fontFamily: "effra_medium,sans-serif",
            fontWeight: 700
        },
        "& img": {
            bottom: 0,
            height: "35px"
        }
    };
});

const Quotes = ({ config }) => (
    <QuotesSection>
        {config.title && <SectionLabel>{config.title}</SectionLabel>}
        <QuotesWrapper>
            {config.quotes.map((q, i) => (
                <div key={`media-quote-${i}`}>
                    <QuoteBlock {...q}>
                        <div dangerouslySetInnerHTML={{ __html: q.quote }} />
                        <div>
                            <img src={q.logo} alt={q.name} />
                        </div>
                    </QuoteBlock>
                </div>
            ))}
        </QuotesWrapper>
    </QuotesSection>
);

export default Quotes;

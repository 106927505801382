import React, { useEffect, useRef, useState } from "react";
import { filterServices } from "@resourcehub/resourcehub-services";
import { Link } from "gatsby";
import Section from "./Section";
import Search from "../Search/Search";
import styled from "styled-components";
import PropTypes from "prop-types";

const SectionTab = styled.div`
    position: relative;

    a {
        text-decoration: none;
        font-family: effra_medium, sans-serif;
    }
`;

const SectionLabel = styled(Link)`
    display: block;
    position: relative;
    background: #fafafa;
    padding: 1em;
    font-size: 14px;
    width: 180px;
    text-align: left;
    color: #4a4a4a;
    button {
        width: 100%;
    }
`;

const FilterBarMobile = styled.div`
    display: none;

    @media (max-width: 1024px) {
        box-sizing: content-box;
        position: relative;
        height: 41px;
        z-index: 12;
        padding: 20px 2.5vw;
        display: flex;
        width: 100%;
    }

    @media (max-width: 425px) {
        padding-left: 3px;
    }
`;

const FilterHeadButton = styled.button`
    border-radius: 8px;
    ${({ navTitle }) =>
        navTitle &&
        `
        border: none;
        box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
        background-color: #fff;
        text-align: left;
        padding: 1em;
        width: 180px;
        color: #4a4a4a;
        font-size: 14px;
    `};

    ${({ isOpen }) =>
        isOpen &&
        `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `};

    @media (max-width: 375px) {
        width: 150px;
    }
`;

const Wrapper = styled.div`
    ${({ navTitle }) =>
        navTitle &&
        `
        box-shadow: rgba(0, 0, 0, 0.1) 0 1px 5px 0;
        width: 180px;
        border-radius: 8px;
        //overflow-y: scroll;
        overflow-x: hidden;
        left: 2.5vw;
        top: 0;
        z-index: 10;
        &:hover {
            box-shadow: @shadow1--hover;
        }

        &:focus {
            padding: calc(1em - 1px);
            outline: none;
            border: 1px solid #72c6ec;
            -webkit-box-shadow: 0 0 8px #72c6ec;
            box-shadow: 0 0 8px #72c6ec;
        }
        
    `};

    ${({ isOpen }) =>
        isOpen &&
        `
        @media (max-width: 768px) {
            display: block;
        }
    `};
`;

const MobileSections = styled.div`
    height: ${({ isOpen }) => (isOpen ? "auto" : 0)};
    transition: ${({ isOpen }) => (isOpen ? "box-shadow 0.1s ease-in-out" : "height 0.3s")};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    background: ${({ isOpen }) => (isOpen ? "#fafafa" : "#ececec")};
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    .mobileSubLinks {
        padding: 0 1.4em;
        font-size: 0;
    }

    ${({ isOpen }) =>
        isOpen &&
        `
          border-top: 1px solid #dbdbdb;
          width: 180px;
          max-height: 100vh;
          overflow-x: hidden;
          box-shadow1: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
          flex-direction: column;
          .mobileSubLinks {
            border-top: 1px solid #dbdbdb;
            text-align: left;
            padding: 1em 1.4em;
            opacity: 0.7;
            font-size: 0.8em;
            background: #ececec;
            color: #4a4a4a;
          }
          .mobileSubLinks:first-child {
            border-top: 0;
          }
          
          @media (max-width: 375px) {
              width: 150px;
          }
    `};
`;

const MobileFilterWrapperContainer = styled.div`
    box-sizing: border-box;
    position: relative;
    height: auto;
    width: 100%;
    margin: 0;
    z-index: 13;
`;

const MobileFilter = ({ vertical, resourcesLink, market, placeholder }) => {
    const filterBar = useRef(null);
    const data = filterServices.getFilterBarData(market, vertical);

    const [isOpen, setIsOpen] = useState(false);
    const [openSubSection, setOpenSubSection] = useState("");

    useEffect(() => {
        document.addEventListener("mousedown", handleFilterStatus, false);
        return () => document.removeEventListener("mousedown", handleFilterStatus, false);
    }, []);

    const handleFilterStatus = (event) => {
        if (filterBar.current.contains(event.target)) return;
        handleFilterClose();
    };

    const handleFilterClose = () => {
        setIsOpen(false);
        setOpenSubSection("");
    };

    const handleClick = () => {
        if (!isOpen) {
            setOpenSubSection("");
        }
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleSubClick = (e, title) => {
        if (openSubSection === title) {
            setOpenSubSection("");
        } else {
            setOpenSubSection(title);
        }
    };

    return (
        <MobileStateless
            isOpen={isOpen}
            openSubSection={openSubSection}
            ref={filterBar}
            handleClick={handleClick}
            handleSubClick={handleSubClick}
            vertical={vertical}
            market={market}
            data={data}
            resourcesLink={resourcesLink}
            placeholder={placeholder}
        />
    );
};

/* eslint-disable react/display-name */
const MobileStateless = React.forwardRef(
    (
        { isOpen, openSubSection, handleClick, handleSubClick, vertical, data, resourcesLink, market, placeholder },
        ref
    ) => {
        const { navTitle, noDropDowns } = data;
        const sections = data.sections || [];

        const mobileNested = sections.map((section) => (
            <Section
                key={section.title}
                section={section}
                openSubSection={openSubSection}
                handleSubClick={handleSubClick}
                resourcesLink={resourcesLink}
            />
        ));

        const mobileNoNested =
            noDropDowns &&
            noDropDowns.map((link) => (
                <SectionTab key={link.title}>
                    <SectionLabel to={`${resourcesLink}${link.url_slug}/`}>{link.title}</SectionLabel>
                </SectionTab>
            ));

        return (
            <FilterBarMobile ref={ref}>
                <Search vertical={vertical} market={market} placeholder={placeholder} resourcesLink={resourcesLink} />
                {((mobileNested && mobileNested.length > 0) || (mobileNoNested && mobileNoNested.length > 0)) && (
                    <MobileFilterWrapperContainer>
                        <Wrapper>
                            <FilterHeadButton onClick={handleClick} type="button" navTitle={navTitle} isOpen={isOpen}>
                                {navTitle}
                            </FilterHeadButton>

                            <MobileSections isOpen={isOpen}>
                                {mobileNested}
                                {mobileNoNested}
                            </MobileSections>
                        </Wrapper>
                    </MobileFilterWrapperContainer>
                )}
            </FilterBarMobile>
        );
    }
);

export const MobileFilterPropTypes = {
    market: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    resourcesLink: PropTypes.string.isRequired,
    vertical: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
};

MobileFilter.propTypes = MobileFilterPropTypes;

export default MobileFilter;

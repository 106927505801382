import React from "react";
import styled from "styled-components";

const SeparatorDiv = styled.section`
    label: Separat0rDiv;
    background-color: #262626;
    :after {
        content: ""; /* This is necessary for the pseudo element to work. */
        display: block; /* This will put the pseudo element on its own line. */
        flex-direction: row !important;
        margin: 0 auto; /* This will center the border. */
        width: 95%; /* Change this to whatever width you want. */
        border-bottom: 1px solid #eeeeee; /* This creates the border. Replace black with whatever color you want. */
    }
`;
const FooterSection = styled.section`
    label: FooterSection;

    color: #eeeeee;
    font-family: effra, sans-serif;
    font-size: 0.8em;
    font-weight: 100;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
`;
const ContentDiv = styled.div`
    label: ContentDiv;

    display: flex;
    max-width: 1084px;
    min-height: 125px;
    width: 100%;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        flex-direction: column !important;
        justify-content: center;
        align-content: center;
        padding: 1em 0;
        text-align: center;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        flex-direction: column !important;
        justify-content: center;
        align-content: center;
        padding: 1em 0;
        text-align: center;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        flex-direction: column !important;
        justify-content: center;
        align-content: center;
        padding: 1em 0;
        text-align: center;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        flex-direction: row !important;
        justify-content: space-between;
        align-content: center;
        padding: 1em 5%;
        text-align: left;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        flex-direction: row !important;
        justify-content: space-between;
        align-content: center;
        margin: auto;
        padding: 1em 0;
        text-align: left;
    }
`;
const MenuSectionDiv = styled.div`
    label: MenuSectionDiv;
`;

const MenuSectionLabelDiv = styled.div`
    label: MenuSectionLabelDiv;

    margin: 12px auto;
    font-weight: bold;
    font-size: 16px;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        background-color: #333333;
        padding: 10px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        background-color: #333333;
        padding: 10px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        background-color: #333333;
        padding: 10px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        background-color: #262626;
        padding: 0;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        background-color: #262626;
        padding: 0;
    }
`;
const MenuSectionList = styled.ul`
    label: MenuSectionList;

    list-style: none;
    margin-bottom: 12px;

    padding: 0;
    //Mobile-landscape (and larger)
    @media (min-width: 320px) {
        margin-right: 0;
    }
    //Mobile-landscape (and larger)
    @media (min-width: 480px) {
        margin-right: 0;
    }
    // Tablet-portrait (and larger)
    @media (min-width: 768px) {
        margin-right: 0;
    }
    // Tablet-landscape (and larger)
    @media (min-width: 992px) {
        margin-right: 20px;
    }
    // Laptops (and longer)
    @media (min-width: 1200px) {
        margin-right: 20px;
    }
`;
const MenuSectionLinkItem = styled.li`
    label: MenuSectionLinkItem;

    font-size: 14px;
    font-weight: bold;
    list-style: none;
    line-height: 1.15;
    margin: 0;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        padding: 10px 0;
        a {
            text-align: center;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        padding: 10px 0;
        a {
            text-align: center;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        padding: 7px 0;
        a {
            text-align: center;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        padding: 7px 0;
        a {
            text-align: left;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        padding: 7px 0;
        a {
            text-align: left;
        }
    }
`;

const Menu = ({ config }) => {
    return (
        <SeparatorDiv>
            <FooterSection data-qa={"footer-navigation-links"}>
                <ContentDiv>
                    {config.sections.map((section, s) => (
                        <MenuSectionDiv key={`section-${s}`}>
                            <MenuSectionLabelDiv data-qa={`footer-navigation-section-${s}-title`}>
                                {section.label}
                            </MenuSectionLabelDiv>
                            <MenuSectionList>
                                {section.links.map((link, l) => (
                                    <MenuSectionLinkItem key={`section-${s}-link-${l}`}>
                                        {link.visible && (
                                            <MenuLink dataQa={`footer-navigation-section-${s}-link-${l}`} link={link} />
                                        )}
                                    </MenuSectionLinkItem>
                                ))}
                            </MenuSectionList>
                        </MenuSectionDiv>
                    ))}
                </ContentDiv>
            </FooterSection>
        </SeparatorDiv>
    );
};

const MenuLinkAnchor = styled.a`
    color: inherit;
    display: block;
    font-weight: normal;
    padding: 0;
    text-decoration: none;
`;
const MenuLink = ({ link, dataQa }) => {
    return (
        <MenuLinkAnchor
            data-qa={dataQa}
            title={link.label}
            target="_blank"
            rel="noopener noreferrer"
            href={link.destination}
        >
            {link.label}
        </MenuLinkAnchor>
    );
};

export default Menu;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const NavLinkStyled = styled.div`
    label: NavLinkStyled;

    cursor: pointer;
    display: block;
    font-family: effra, sans-serif;
    font-size: 16px;
    letter-spacing: 0.4px;
    padding: 23px 11px;
    transition: all 0.25s;

    a {
        color: #262626 !important;
        cursor: pointer;
        text-transform: capitalize;
        text-decoration: none !important;
        :hover,
        :focus,
        :active {
            text-decoration: none !important;
        }
        :hover {
            color: #4788be !important;
        }
    }
`;

const PrimaryNavLink = ({ dataQa, destination, children, openNew, title }) => {
    let specialProps = {};
    if (openNew) {
        specialProps = {
            target: "_blank",
            rel: "noopener noreferrer"
        };
    }
    return (
        <NavLinkStyled>
            {destination ? (
                // Links outside of the immediate gatsby hub/site
                <a data-qa={dataQa} title={title} href={destination} {...specialProps}>
                    {children}
                </a>
            ) : (
                // this is for the case where we want the look and feel of the nav link with no action.
                children
            )}
        </NavLinkStyled>
    );
};

PrimaryNavLink.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    dataQa: PropTypes.string,
    destination: PropTypes.string,
    external: PropTypes.bool,
    openNew: PropTypes.bool
};

PrimaryNavLink.defaultProps = {
    dataQa: null,
    destination: null,
    external: false,
    openNew: false
};

export { NavLinkStyled };
export default PrimaryNavLink;
